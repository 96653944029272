import React               from 'react'
import User                from '../utils/User.js'
import { getUrlIddPlus, getRootPath }   from '../utils/Utils.js'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard }    from '@fortawesome/free-solid-svg-icons'


export default function AdicionarCartao() {

    // Não permitir deslogado ====================
    User.blockIfNonLogged()
    // ===========================================

    React.useEffect(() => {
        document.body.classList.add("body-adicionar-cartao")
        return () => {
            document.body.classList.remove("body-adicionar-cartao")
        }
    })

    window.addEventListener("message", (event) => {
        if (getUrlIddPlus().indexOf(event.origin) !== 0) {
            return;
        }
        if(typeof(event.data.height) != 'undefined') { 
            document.getElementById('card-iframe').style.height = event.data.height+'px';
        } else if(event.data.success) { 
            document.location = getRootPath()+'meus-cartoes'
        }
    }, false);

    return (


    <>

        <div className="areaDados" style={{marginTop:'120px', height: '100%'}}>
            <div className="dadosConfirmoArea">

                <div className="d-flex justify-content-between align-items-start">
                    <h4>
                        <FontAwesomeIcon icon={faCreditCard} className="mr-2" /> 
                        Adicionar cartão
                    </h4>
                </div>
                <br />

                <div className="cadastroContorno" style={{height: '100%'}}>
                    <div className="cadastroBox2 p-4" style={{height: '100%'}}>
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-9 col-lg-6 col-xl-4">
                                <iframe id="card-iframe" src={`${getUrlIddPlus()}adicionar-cartao/?key=${User.getAttr('token')}`} frameBorder="0" scrolling="no" style={{width: '100%', height: '100%', backgroundColor:'transparent'}}></iframe>
                            </div>
                        </div>
                    </div>                               
                </div>
            </div>
        </div>


    </>
  )
}


