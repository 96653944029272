import React    from 'react';
import api      from "../services/api";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch }   from '@fortawesome/free-solid-svg-icons';



export default function ListagemBibliografia(props) {

    const [bibliografia, setBibliografia] = React.useState(null);

    React.useEffect(() => {

        let url = 'bibliografia'
        if(props.slug) { 
            url += '/' + props.slug
        }
        const getParams = []
        if(props.palavraChave) { 
            getParams.push(`palavra_chave=${props.palavraChave}`)
        }
        if(props.comCategoria) { 
            getParams.push(`com_categoria=1`)
        }
        if(getParams.length) { 
            url += '?'+getParams.join('&')
        }
        api.get(url)
        .then(res => {
            if(!res.data) {
                return null
            }
            if(res.data.status === 0){
                return null
            }
            if(!res.data.bibliografia) {
                return null
            }
            setBibliografia(res.data.bibliografia)

            if(typeof(props.onLoadCallback) == 'function') {
                props.onLoadCallback(props.onLoadKey, res.data.bibliografia);
            }

        })
    }, [props.slug, props.palavraChave]);



    /**
    * Renderizar ==========================================================================================
    **/ 
    return (
    <>
        { bibliografia == null && 
            <div className="text-center">
                <br />
                <FontAwesomeIcon icon={faCircleNotch} className="m-3 fa-spin" size="5x" /> 
                <br />
                <br />
            </div>
        }

      { bibliografia && 
        <>
            { bibliografia.map((item, key) => {

                return (
                    <div key={key} className="boxBibliografia">

                        { item.url_imagem != null && 
                            <img className="boxBibliografiaImagem" src={api.getImageUrl(item.url_imagem, 130, 170)} />
                        }
                        <div>
                            <div className="boxBibliografiaTextos">
                                <p className="boxBibliografiaTitulo">
                                    {item.titulo}
                                </p>
                                <p className="boxBibliografiaEditora">
                                    EDITORA: {item.editora}
                                </p>
                                <div className="boxBibliografiaEspacador">
                                </div>
                                <p className="boxBibliografiaDescricao">
                                    {item.descricao.nl2br()}
                                </p>
                                <a className="boxBibliografiaCompra" href={item.link} target="_blank" rel="noreferrer">
                                    Ir para a loja
                                </a>
                            </div>
                        </div>
                    </div>

                )})
            }
        </>
      }
    </>
    )

}


