import React         from 'react';
import api           from "../services/api";
import { useParams } from "react-router-dom"
import User          from '../utils/User.js';
import { getLinkedInCertificateUrl } from '../utils/Utils.js';
import PagePreloader from '../components/PagePreloader.jsx'


export default function Certificado() {

    const params          = useParams();

    const [ moduloX_user,  setModuloX_user  ] = React.useState(null);
    const [ downloadUrl,   setDownloadUrl   ] = React.useState(null);
    const [ linkedInUrl,   setLinkedInUrl   ] = React.useState(null);
    const [ showPreloader, setShowPreloader ] = React.useState(true);


    React.useEffect(() => {

        /**
         * Buscar moduloX_user
         **/ 
        let url = `moduloX_user/certificado/${params.certificadoId}/`
        if(User.getAttr('token')) { 
            url += `?key=${User.getAttr('token')}`
        }
        api
        .get(url)
        .then((response) => {

            // Erro no ajax
            if(response.data.status === 0) { 
                return
            }
            setShowPreloader(false)
            setModuloX_user(response.data.moduloX_user)
            let downloadUrl = `${response.data.moduloX_user.url_certificado_download}/`
            if(User.getAttr('token')) { 
                downloadUrl += `?key=${User.getAttr('token')}`
            }
            setDownloadUrl(downloadUrl)
            if(User.getAttr('id') == response.data.moduloX_user._user.id) {
                setLinkedInUrl(getLinkedInCertificateUrl(response.data.moduloX_user.modulo.programa.nome, response.data.moduloX_user.concluido_em, document.location.href, response.data.moduloX_user.id_certificado))
            }
        })
        .catch((err) => {
            console.error("Erro ao buscar pela API: " + err);
        });

    }, [])


    return (
    <>


        <div className="areaDados" style={{marginTop: '120px'}}>
            <div className="dadosConfirmoArea">


                <PagePreloader show={showPreloader} />

                { !showPreloader && !moduloX_user && 
                    <>
                        Certificado não encontrado
                    </>
                }

                { !showPreloader && moduloX_user && 
                    <>

                        <div className="cadastroContorno">
                            <div className="cadastroBox2" style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'baseline', paddingTop:'5px', paddingBottom:'5px', fontSize:'13px'}}>
                                certificado emitido em 
                                <h6 className="pl-1 pr-1">
                                    {moduloX_user.data_conclusao_por_extenso}
                                </h6>
                                para: 
                                <h6 className="pl-2">
                                    {moduloX_user._user.userType.nome}
                                </h6>
                            </div>
                        </div>


                        <div className="areaGeralCertificados">

                            <div style={{maxWidth: '300px', textAlign: 'right'}}>
                                <a href={downloadUrl} target="_blank" rel="noreferrer">
                                    <img className="img-fluid" src={api.getImageUrl(moduloX_user.modulo.paginaCertificado[0].file_url, 300, 226)} />
                                </a>
                                <a className="btn btn-success mt-3 mb-2 btn-block" href={downloadUrl} target="_blank" rel="noreferrer">
                                    download
                                </a>
                                {linkedInUrl && 
                                    <a className="btn btn-info mb-5 btn-block" href={linkedInUrl} target="_blank" rel="noreferrer">
                                        publicar no LinkedIn
                                    </a>
                                }
                            </div>

                            <div className="areaListagemCertificados">
                                
                                <h2 className="abasEpisodioTitulo">
                                    {moduloX_user.modulo.programa.nome}
                                </h2>
                                
                                <h5>
                                    {moduloX_user.modulo.nome}
                                </h5>
                                
                                {moduloX_user.modulo.programa.site_sobre && 
                                    <div style={{marginTop: '50px', borderBottom: '1px solid #FFF', borderTop: '1px solid #FFF', paddingBottom:'20px', paddingTop:'20px'}}>
                                        { moduloX_user.modulo.programa.site_sobre.nl2br()}
                                    </div>
                                }

                                <div style={{marginTop: '40px'}}>
                                    Tópicos abordados:
                                </div>

                                {moduloX_user.modulo.submodulo.map((submodulo, key) => {
                                    if(submodulo.video) { 
                                        return (
                                            <div key={key} style={{marginLeft: '30px'}}>
                                                <h5 style={{marginTop: '20px'}}>
                                                    {submodulo.video.nome}
                                                </h5>
                                                {submodulo.descricao}
                                            </div>
                                        )
                                    }
                                })}




                            </div>

                        </div>

                    </>
                }


                

            </div>
        </div>




        



    </>
    )


}