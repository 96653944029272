import React, { useState, useRef }   from 'react';
import Vimeo                         from '@u-wave/react-vimeo';

import api                           from "../../services/api";
import User                          from '../../utils/User.js';
import { getLinkedInCertificateUrl } from '../../utils/Utils.js';

import Modal                         from 'react-bootstrap/Modal';
import './ModalPlayer.css';
import trechosAssistidos             from './ModalPlayer.trechosAssistidos.js';
import Avaliacao                     from './ModalPlayer.Avaliacao.js';

import { FontAwesomeIcon }           from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlay, faFile, faTimes }       from '@fortawesome/free-solid-svg-icons';
import { faLinkedin }       from '@fortawesome/free-brands-svg-icons';


export default function ModalPlayer(props) {



    // Configurações *************************************************************************************

    // Tempo antes de ser redirecionado automaticamente
    const tempo_espera_proximo_episodio = 5

    // ***************************************************************************************************



    if(!props.keyAtual) { 
        return null
    }

    const vimeoVideoRef = useRef(null);

    const [keyNext,               setKeyNext               ] = useState(null)
    const [labelProximoEpisodio,  setLabelProximoEpisodio  ] = useState(null)
    const [fullscreen,            setFullscreen            ] = useState(false)

    const [VimeoStartAt,        setVimeoStartAt        ] = useState(0)
    const [vimeoVideoId,        setVimeoVideoId        ] = useState(null)
    const [vimeoOnPlay,         setVimeoOnPlay         ] = useState(null)
    const [vimeoOnPause,        setVimeoOnPause        ] = useState(null)

    const [showProximoEpisodio, setShowProximoEpisodio ] = useState(false)
    const [countdownWidth,      setCountdownWidth      ] = useState(0)
    const [countdownTimeLimit,  setCountdownTimeLimit  ] = useState(0)
    const [videoConcluido,      setVideoConcluido      ] = useState(false)

    const [hasCertificate,      setHasCertificate      ] = useState(false)
    const [showCertificate,     setShowCertificate     ] = useState(false)
    const [urlCertificado,      setUrlCertificado      ] = useState(null)
    const [urlLinkedin,         setUrlLinkedin         ] = useState(null)

    const [hasAvaliacao,        setHasAvaliacao        ] = useState(null)
    const [showAvaliacao,       setShowAvaliacao       ] = useState(false)



    React.useEffect(() => {

        /**
         * Remover player anterior
         **/ 
        ModalPlayer.avaliacaoDone   = false
        ModalPlayer.certificateDone = false
        setVimeoVideoId(null)
        setCountdownAction(null)

        /**
         * Time do início vídeo
         **/ 
        const moduloAtual    = props.programa.modulo[props.keyAtual.modulo]
        const submoduloAtual = moduloAtual.submodulo[props.keyAtual.submodulo]
        if(props.fromStart) { 
            setVimeoStartAt(0)
        } else if(submoduloAtual.submoduloX_user) { 
            const duracao = trechosAssistidos.getDurationFromTrechos(submoduloAtual.submoduloX_user.trechos_assistidos)
            if(Number(submoduloAtual.submoduloX_user.video_actual_time) + trechosAssistidos.tempo_restante_considerado_100_por_cento >= duracao) { 
                setVimeoStartAt(0)
            } else { 
                setVimeoStartAt(parseInt(submoduloAtual.submoduloX_user.video_actual_time))
            }
        }

        /**
         * Setar player
         **/ 
        setPlayerHtml(submoduloAtual.video)

        /**
         * Salvar trechos assistidos ao sair
         **/ 
        trechosAssistidos.setSubmodulo(submoduloAtual.id)
        window.onbeforeunload = () => {
            trechosAssistidos.atualizar()
        }

        /**
         * Resize da janela
         **/ 
        window.onresize = () => {
            resizeVideo()
        }


        /**
         * Ver se é o último epsiódio não concluído deste modulo
         **/ 
        api
        .get(`submoduloX_user/has_certificate_on_end/?key=${User.getAttr('token')}&submodulo=${submoduloAtual.id}`)
        .then((res) => {
            if(!res.data) { 
                console.log('Erro ao verificar se submodulo disponibilizará certificado');
                return null
            }
            if(res.data.status === 0) { 
                console.log('Erro na API ao verificar se submodulo disponibilizará certificado');
                return null
            }

            setHasCertificate(res.data.has_certificate_on_end)

        })
        .catch((err) => {
            console.error("Erro ao buscar pela API: " + err);
        });


        /**
         * Próximo episódio
         **/
        let keyNext
        if(moduloAtual.submodulo.length-1 == props.keyAtual.submodulo) {     // último submodulo deste modulo
            if(props.programa.modulo.length-1 == props.keyAtual.modulo) {    // está no último módulo
                keyNext = null
            } else { 
                keyNext = {modulo: props.keyAtual.modulo+1, submodulo: 0 }
            }
        } else { 
            keyNext = {modulo: props.keyAtual.modulo, submodulo: props.keyAtual.submodulo+1 }
        }
        setKeyNext(keyNext);

        /**
         * Label do botão de próximo episódio
         **/ 
        if(keyNext) { 
            const submoduloNext = props.programa.modulo[keyNext.modulo].submodulo[keyNext.submodulo]
            if(typeof(submoduloNext.questionario) != 'undefined') { 
                setLabelProximoEpisodio('Continuar para o quiz')
            } else { 
                setLabelProximoEpisodio('Próximo episódio')
            }
        }

        /**
         * Ver se tem avaliacao neste submodulo
         **/ 
        let keyBeforeLastQuiz = null
        let keyLastSubmodulo = props.programa.modulo[props.keyAtual.modulo].submodulo.length - 1
        for(let s=keyLastSubmodulo; s>=0; s--) {
            if(props.programa.modulo[props.keyAtual.modulo].submodulo[s].questionario && s > 0) { 
                keyBeforeLastQuiz = s-1
                break
            }
        }

        const keySubmoduloAvaliacao = (!keyBeforeLastQuiz ? keyLastSubmodulo : keyBeforeLastQuiz)
        setHasAvaliacao(keySubmoduloAvaliacao == props.keyAtual.submodulo)

        /**
         * Atualizar / salvar trechos assistidos
         **/ 
        setVimeoOnPlay(() => () => {
            trechosAssistidos.atualizar()
        })
        setVimeoOnPause(() => () => {
            trechosAssistidos.atualizar()
        })

    }, [props.keyAtual]);






    trechosAssistidos.setDadosCertificado = (dadosCertificado) => {
        setUrlCertificado(dadosCertificado.url)
        setUrlLinkedin(getLinkedInCertificateUrl(props.programa.nome, dadosCertificado.data, dadosCertificado.url, dadosCertificado.id))
    }






    const setPlayerHtml = (video=null) => {
        if(video === null) { 
            return;
        }
        const prefixoIdUrl = 'vimeo.com/'
        const inicio = video.url.indexOf(prefixoIdUrl)+prefixoIdUrl.length
        let id = video.url.substr(inicio)
        if(id.indexOf('?') !== -1) {
            id = id.substr(0, id.indexOf('?'))
        }
        setVimeoVideoId(id)

    }







    const vimeoOnLoaded = () => {
        trechosAssistidos.player = vimeoVideoRef.current.player
        setVideoConcluido(false)
        if(fullscreen) { 
            document.querySelector('#area-player iframe').requestFullscreen()
        }
    }






    /**
     * Setar verificador que abre avaliação no final do vídeo
     **/ 
    const vimeoOnTimeUpdate = (time) => {
        if(countdownTimeLimit || videoConcluido) { 
            return
        }

        if(time.seconds >= time.duration - trechosAssistidos.tempo_restante_considerado_100_por_cento) { 
            trechosAssistidos.atualizar(nextStep)
        }
    }


    const nextStep = () => {

        exitFullScreen()
        setVideoConcluido(true)

        /**
         * Modal de avaliacao
         **/ 
        if(hasAvaliacao && !ModalPlayer.avaliacaoDone) { 
            setShowAvaliacao(true)
            return
        }

        /**
         * Modal de certificado
         **/ 
        if(hasCertificate && !ModalPlayer.certificateDone) { 
            setShowCertificate(true)
            return
        }

        /**
         * Acabaram os módulos e episódios
         **/ 
        if(!keyNext) { 
            setTimeout(fecharModal, trechosAssistidos.tempo_restante_considerado_100_por_cento)
            return
        }

        /**
         * Exibir contagem de próximo episódio
         **/ 
        setShowProximoEpisodio(true)
        const contando_desde = new Date().getTime() / 1000;
        processCountdown(contando_desde)

    }


    const closeCertificateModal = () => {
        ModalPlayer.certificateDone = true
        setShowCertificate(false)
        nextStep()
    }



    const vimeoOnCuePoint = (params1, params2) => {
        console.log('vimeoOnCuePoint', params1, params2);
    }

    const vimeoOnCueChange = (params1, params2) => {
        console.log('vimeoOnCueChange', params1, params2);
    }



    /**
     * Sair da tela cheia
     **/ 
    const exitFullScreen = () => {
        const fullscreen = document.fullscreenElement !== null
        setFullscreen(fullscreen)
        if(fullscreen) { 
            document.exitFullscreen()
        }
    }


    






    const fecharModal = () => {
        trechosAssistidos.atualizar(() => {
            trechosAssistidos.reset()
            props.setModalPlayerShow(false)
        })
        props.detalhesUpdate()
        setCountdownAction('kill')
    }

    const goToNextEpisode = () => {

        setCountdownAction('kill')

        const submoduloNext = props.programa.modulo[keyNext.modulo].submodulo[keyNext.submodulo]
        if(typeof(submoduloNext.questionario) != 'undefined') { 
            document.location = `/quiz/${props.programa.site_slug}/${submoduloNext.id}`
        } else { 
            props.openPlayer(keyNext.modulo, keyNext.submodulo, true)
        }
        trechosAssistidos.reset()
    }

    


    const setCountdownAction = (action) => {
        ModalPlayer.countdownAction = action
    }


    const processCountdown = (contando_desde) => {

        /**
         * Ver se vai pro próximo e/ou para contagem
         **/ 
        let toNext          = false
        let killCountdown   = false
        let tempo_decorrido = 0
        if(ModalPlayer.countdownAction == 'forward') {
            toNext = true
        } else if(ModalPlayer.countdownAction == 'kill') { 
            killCountdown = true
        } else { 
            const timestamp_atual = new Date().getTime() / 1000;
            tempo_decorrido = timestamp_atual - contando_desde
            toNext = tempo_decorrido >= tempo_espera_proximo_episodio
        }
        if(toNext) { 
            killCountdown = true
        }

        /**
         * Eliminar contagem
         **/ 
        if(killCountdown) { 

            setCountdownWidth(0)
            setShowProximoEpisodio(false)
            clearTimeout(countdownTimeLimit)
            setCountdownTimeLimit(0)
        }

        /**
         * Chamar próximo
         **/ 
        if(toNext) { 
            setCountdownWidth(100)
            goToNextEpisode()
        }


        /**
         * Continuar avançando contagem regressiva
         **/ 
        if(!toNext && !killCountdown) { 
            setCountdownWidth(tempo_decorrido * 100 / tempo_espera_proximo_episodio)
            setCountdownTimeLimit (setTimeout(() => { processCountdown(contando_desde) }, 10))
        }
    }

    const callbackAvaliacao = () => {
        ModalPlayer.avaliacaoDone = true
        setShowAvaliacao(false)
        nextStep()        
    }




    const resizeVideo = () => {

        const videoWidth   = 16
        const videoHeight  = 9
        const windowWidth  = window.innerWidth
        const windowHeight = window.innerHeight

        // Ajustar pela altura
        let newVideoHeight = windowHeight;
        let newVideoWidth = videoWidth * newVideoHeight / videoHeight

        // Não entrou; ajustar pela largura
        if(newVideoWidth > windowWidth) {
            newVideoWidth = windowWidth;
            newVideoHeight = videoHeight * newVideoWidth / videoWidth
        }
        document.querySelector("[data-vimeo-initialized='true']").style.width  = newVideoWidth+'px'
        document.querySelector("[data-vimeo-initialized='true']").style.height = newVideoHeight+'px'
    }




    const textoDescricaoCertificado = `Você acabou de receber o certificado de conclusão desta ${props.programa.modulo.length > 1 ? 'temporada' : 'série'}.`
    const temMaisModulos = (props.programa.modulo.length > 1 ? true : false)




    return (
    <>

        {showAvaliacao && 
            <Avaliacao submodulo={props.programa.modulo[props.keyAtual.modulo].submodulo[props.keyAtual.submodulo]} setShow={setShowAvaliacao} callback={callbackAvaliacao} temMaisModulos={temMaisModulos} />
        }


        {showCertificate && 

            <>
                <div className="modal-sobre-player-backdrop">
                </div>
                <div className="modal-sobre-player" >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header d-flex align-items-center">
                                <h5 className="modal-title">
                                    Parabéns!
                                </h5>
                                <button className="btn btn-light border btn-sm" onClick={ () => setShowCertificate(false) }>
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            </div>
                            <div className="modal-body">

                                <div className="py-2">

                                    {textoDescricaoCertificado}

                                    <a className="btn btn-primary text-button btn-block mt-2" href={urlCertificado}>
                                        <FontAwesomeIcon icon={faFile} className="mr-2" />
                                        visualizar / baixar certificado
                                    </a>
                                    <a className="btn btn-primary text-button btn-block mt-2" href={urlLinkedin} target="_blank" rel="noreferrer">
                                        <FontAwesomeIcon icon={faLinkedin} className="mr-2" />
                                        adicionar no LinkedIn
                                    </a>
                                    <br />

                                    <small>
                                        Você pode ver todos seus certificados no menu da sua conta, em <strong>Meus certificados</strong>
                                    </small>




                                </div>

                                <div className="modal-footer mt-2 px-0">
                                    <button type="button" className="btn btn-success" onClick={closeCertificateModal}>
                                        {keyNext && 
                                            <>
                                                <FontAwesomeIcon icon={faPlay} className="mr-2" />
                                                continuar assistindo
                                            </>
                                        }
                                        {!keyNext && 
                                            <>
                                                <FontAwesomeIcon icon={faTimes} className="mr-2" />
                                                fechar
                                            </>
                                        }
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>

        }

        <Modal show={props.show} backdrop={true} onHide={fecharModal} dialogClassName="modalPlayer" >

            <a id="modal-close" onClick={fecharModal} >
                <FontAwesomeIcon icon={faArrowLeft} size="3x" />
            </a>


            {showProximoEpisodio && 
                <a href="#" id="proximo-episodio" onClick={() => {setCountdownAction('forward')}}>
                    <div id="countdown" style={{width: `${countdownWidth}%`}}>
                    </div>
                    <FontAwesomeIcon icon={faPlay} className="mr-2" />
                    {labelProximoEpisodio}
                </a>
            }




            <div className="areaPlayerGeral">

                <div className="areaPlayer" id="area-player" >

                    {vimeoVideoId !== null && 
                        <Vimeo
                            ref={vimeoVideoRef}
                            video={vimeoVideoId}
                            start={VimeoStartAt}
                            onPlay={vimeoOnPlay}
                            onPause={vimeoOnPause}
                            onTimeUpdate={vimeoOnTimeUpdate}
                            onLoaded={vimeoOnLoaded}
                            onReady={resizeVideo}
                            speed={true}
                            onCuePoint={vimeoOnCuePoint}
                            onCueChange={vimeoOnCueChange}
                            autoplay
                        />
                    }


                </div>

            </div>

        </Modal>
    </>
  );
}

ModalPlayer.countdownAction = null;
ModalPlayer.avaliacaoDone   = false;
ModalPlayer.certificateDone = false;
