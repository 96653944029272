import React    from 'react';
import { Link }    from "react-router-dom";
import NavDropdown from 'react-bootstrap/NavDropdown';
import api         from "../../services/api";
import User        from '../../utils/User.js';

export default function MenuUsuario(props) {

    const [ name, setName ] = React.useState("");
    const [ styleUsuarioFoto, setStyleUsuarioFoto ] = React.useState({});

    React.useEffect(() => {

        if(User.getAttr('name')) { 
            let name;
            if(User.getAttr('name').indexOf(' ') !== -1) { 
                name = User.getAttr('name').substr(0, User.getAttr('name').indexOf(' '));
            } else { 
                name = User.getAttr('name');
            }
            setName(name)

            setStyleUsuarioFoto(User.getAttr('url_imagem') ? {backgroundImage: `url(${api.getImageUrl(User.getAttr('url_imagem'))})`} : {})
        }

    }, [props.updateTimestamp])


    if(User.getAttr('token')) {

        const toggleUsuarioMenu = () => {
            setTimeout(() => {
                document.querySelector(".usuarioMenu1Dropdown .dropdown-menu.show").style.right = '0px'
                document.querySelector(".usuarioMenu1Dropdown .dropdown-menu.show").style.left = 'auto'
            }, 5)
        }

        let meusPagamentos = null
        if(props.hasTransacao) { 
            meusPagamentos = 
                <NavDropdown.Item as={Link} to="meus-pagamentos/" onClick={() => props.setNavbarExpanded(false)}>
                    Meus pagamentos
                </NavDropdown.Item>
        }


        return (
            <NavDropdown className="usuarioMenu1Dropdown" onToggle={toggleUsuarioMenu} title={
                <div className="usuarioMenu">
                    <span className="usuarioMenu1">
                        {name}
                    </span>
                    <span className="usuarioFoto1" style={styleUsuarioFoto}>
                    </span>
                </div>
            }>
                <NavDropdown.Item as={Link} to="meus-dados/" onClick={() => props.setNavbarExpanded(false)}>
                    Meus dados
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="meus-certificados/" onClick={() => props.setNavbarExpanded(false)}>
                    Meus certificados
                </NavDropdown.Item>
                {/*
                <NavDropdown.Item as={Link} to="minhas-conquistas/" onClick={() => props.setNavbarExpanded(false)}>
                    Minhas conquistas
                </NavDropdown.Item>
                */}
                { meusPagamentos }
                <a className="dropdown-item" href="#" onClick={() => { User.logout() }}>
                    Desconectar
                </a>
            </NavDropdown>


        )

    } else { 

        return (
            <>
                <li className="buscaMenu1 px-4 d-flex align-items-center">
                    <button className="btn btn-success topoAssineJa2" onClick={() => props.toggleModalCadastro(true)} >
                        Assine já
                    </button>
                </li>
                <li className="nav-item dropdown">
                    <button className="topoEntrar" data-toggle="modal" data-target="#exampleModal" onClick={() => props.toggleModalAssinante(true)} style={{background: 'none', border:'none'}}>
                        Entrar
                    </button>
                </li>
            </>
        )
    }
}