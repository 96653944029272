import { useParams, useOutletContext } from "react-router-dom";
import React           from 'react';
import api             from "../services/api";
import ListagemVideos  from '../components/ListagemVideos';
import User            from '../utils/User.js';
import Checkout        from '../utils/Checkout.js';
import barra_idd_plus  from '../imagens/barra_idd_plus.jpg';


import './Canal.css'

export default function Detalhes() {

    const toggleModalAssinante = useOutletContext().toggleModalAssinante

    /**
     * Dados dinamicos da associação
     **/ 
    const [apiReturn, setApiReturn] = React.useState(null);
    const params = useParams();

    React.useEffect(() => {
        let url = `associacao/${params.slug}`
        if(User.getAttr('token')) { 
            url += `/?key=${User.getAttr('token')}`
        }
        api
        .get(url)
        .then(res => {
            if(res.data.status === 0) { 
                return null;
            }
            setApiReturn(res.data);
        })
    }, [params.slug]);

    if (!apiReturn) return null;
    if (apiReturn.status === 0) return null;


    const associacao      = apiReturn.associacao;
    const nl2br           = require('react-nl2br');
    const backgroundImage = (associacao.imagemCanal ? api.getImageUrl(associacao.imagemCanal.url_imagem, 1920, 720) : '');

    const clickAssineAgora = () => {
        Checkout.go({'associacao': associacao.id}, toggleModalAssinante)
    }

    return (


        <>

        <div className="CanalBox" style={{backgroundImage: `url(${backgroundImage})`}}>
            <div className="DetalhamentoBoxTextos">
                <span className="DetalhamentoBoxSubtitulo">
                    Canal 
                    <img src={barra_idd_plus} className="ml-2" />
                    <img src={api.getImageUrl(associacao.url_imagem)} className="ml-2" />
                </span>
                <p className="DetalhamentoBoxDescricao">
                    {nl2br(associacao.descricao_canal)}
                </p>

                {!associacao.assinante && 
                    <button className="DetalhamentoBoxCta sombra" onClick={clickAssineAgora} >
                        <span>Assine Agora </span> 
                        <span className="DetalhamentoBoxEpisodio">(R$ {associacao.preco_assinatura.toPriceFormat()} ao mês)</span>
                    </button>
                }
            </div>
            <div className="DetalhamentoBoxImagemFundo">
            </div>
        </div>





        {/* Listagem Catálogo */}
        <div className="listagemContainer">
            <div className="listagemTextos">
                <div className="listagemTituloCatalogo mb-4">
                Nosso <span className="destaqueBranco">Catálogo</span>
                </div>
            </div>
        </div>
        <ListagemVideos associacao={associacao.id} carousel={false} />





    </>
    )
}


