import React    from 'react';
import api      from "../services/api";
import Docente from '../utils/Docente.js'
import Modal from 'react-bootstrap/Modal';
import ListaHorizontal from '../components/ListaHorizontal';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch }   from '@fortawesome/free-solid-svg-icons';


// import ListaHorizontal from '../components/ListaHorizontal';


export default function ListagemDocentes(props) {

    const [docente, setDocente] = React.useState(null);
    const [showModal, setShowModal] = React.useState(false)
    const [docenteSelecionado, setDocenteSelecionado] = React.useState(null)

    React.useEffect(() => {

        if(props.docente) { 
            setDocente(props.docente)
        } else { 
            api.get('docente/')
            .then(res => {
                if(!res.data)             return null
                if(res.data.status === 0) return null
                if(!res.data.docente)     return null
                setDocente(res.data.docente)

            })
        }
    }, []);


    const preencherCurriculo = () => {
        document.getElementById('curriculo-nome').innerHTML = docenteSelecionado.nome
        document.getElementById('curriculo-minicurriculo').innerHTML = docenteSelecionado.minicurriculo
        document.getElementById('curriculo-imagem').style.backgroundImage = 'url('+api.getImageUrl(docenteSelecionado.url_imagem, 160, 160)+')'
        document.getElementById('curriculo-close').onclick = () => {
            setShowModal(false)
        }
        document.getElementsByClassName('modal-content')[0].style.height = '10px'    // chuncho pra poder clicar em "fechar"
    }







    const breakpoints={
        400: {
          slidesPerView: 2,
          slidesPerGroup: 1
        },
        640: {
          slidesPerView: 3,
          slidesPerGroup: 1
        },
        790: {
          slidesPerView: 4,
          slidesPerGroup: 1
        },
        870: {
          slidesPerView: 5,
          slidesPerGroup: 1
        },
        900: {
          slidesPerView: 3,
          slidesPerGroup: 1
        },
        1090: {
          slidesPerView: 4,
          slidesPerGroup: 2
        },
        1415: {
          slidesPerView: 5,
          slidesPerGroup: 2
        },
        1600: {
          slidesPerView: 6,
          slidesPerGroup: 1
        },
        1860: {
          slidesPerView: 7,
          slidesPerGroup: 3
        },
    }


    const getContent = (doc, key) => {

        if(!doc) { 
            return null
        }
        return (
            <div key={key} >
                <a className="docentesLandingBox" style={{backgroundImage: `url(${api.getImageUrl(doc.url_imagem, 250, 310)})`}} onClick={() => {setDocenteSelecionado(doc); setShowModal(true);}  } >
                    <div className="docentesLandingBox2">
                        <div className="docentesLandingsubtitulo">
                            <p className="docentesLandingtitulo">
                                {doc.nome}
                            </p>
                            {Docente.getOcupacaoLocalidade(doc)}
                        </div>
                    </div>
                </a>
            </div>
        )
    } 






   

    /**
    * Renderizar ==========================================================================================
    **/ 
    return (
    <>







        { docente == null && 
            <div style={{textAlign:'center'}}>
            <br />
            <FontAwesomeIcon icon={faCircleNotch} className="m-3 fa-spin" size="5x" /> 
            <br />
            <br />
            </div>
        }

        { docente && 
            <>



                <ListaHorizontal getContent={getContent} items={docente} carousel={props.carousel} breakpoints={breakpoints} />


                <Modal onShow={() => { preencherCurriculo() }} show={showModal} backdrop={true} onHide={() => setShowModal(false) } >
                    <div className="modal-dialog ModalLarguraLogin" role="document">
                        <div>
                            <div>
                                <div className="modalInscricaoGeral">
                                    <div className="modalArea">
                                        <div className="modalArea2">


                                            <div className="modalContorno">
                                                <div className="modalBox">
                                                    <div className="modalDocentesBordaImagem">
                                                        <div id="curriculo-imagem" className="modalDocentesImagem">
                                                        </div>
                                                    </div>
                                                    <p id="curriculo-nome" className="modalDocentesFuncao">
                                                        
                                                    </p>
                                                    <p className="modalDocentesNome">
                                                        professor
                                                    </p>
                                                    <p className="modalDocentesEmpresa">Grupo IDD</p>
                                                    <div className="modalDocentesLinha"></div>
                                                    <p id="curriculo-minicurriculo" className="modalDocentesTexto">
                                                    </p>
                                                    <button id="curriculo-close" type="button" className="btn-close btnModalDocentes" style={{position: 'inherit'}} >
                                                        <strong>X</strong>
                                                    </button>
                                                </div>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

            </>
        }
    </>
)

}


