
import React               from 'react';
import { useParams, Link } from "react-router-dom";

import api                 from "../services/api";
import User                from '../utils/User.js';
import { getRootPath }     from '../utils/Utils.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch, faCreditCard, faCalculator, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';


export default function MeusPagamentos() {

    // Não permitir deslogado ====================
    User.blockIfNonLogged()
    // ===========================================

    const [transacao,    setTransacao    ] = React.useState(null);
    const [anuidade,     setAnuidade     ] = React.useState(null);
    const [expiredCount, setExpiredCount ] = React.useState(null);

    const params = useParams();

    React.useEffect(() => {

        /**
         * Buscar anuidades
         **/ 
        api
        .get(`anuidade/?key=${User.getAttr('token')}`)
        .then(res => {
            if(res.data.status === 0) { 
                console.log('Erro na API ao buscar anuidades');
                return;
            }
            setAnuidade(res.data.anuidade)
        })

    }, [params.slug]);



    React.useEffect(() => {

        if(anuidade) { 
            searchTransacao(anuidade[0].id)
        }

    }, [anuidade]);


    const searchTransacao = (anuidade_id) => {
        api
        .get(`transacao/?key=${User.getAttr('token')}&anuidade=${anuidade_id}`)
        .then(res => {
            if(res.data.status === 0) { 
                console.log('Erro na API ao buscar transacao');
                return;
            }

            const objs = []
            let expiredCount = 0
            for(const item of res.data.transacao) {

                // Desconsiderar não-parcelas
                if(!Number(item.parcelamento)) {
                    continue
                }
                if(item.status_consolidado == 'paid') { 
                    item.vencido = false
                } else { 
                    item.vencido = new Date() > new Date(item.data)
                }
                if(item.vencido) { 
                    expiredCount++
                }
                objs.push(item)
            }
            setExpiredCount(expiredCount)
            setTransacao(objs)
        })
    }



    if (!anuidade) {
        return null
    }



    const onChangeAnuidade = (event) => {
        searchTransacao(event.target.value);
    }


    const getDanfeButton = (invoice, key) => {
        return (
            <a key={key} href={invoice.pdf_url} className="btn btn-light btn-xs" style={{opacity:0.7}} target="_blank" rel="noreferrer">
                DANFE
            </a>
        )
    }




    return (


    <>

        <div className="areaDados" style={{marginTop:'120px'}}>
            <div className="dadosConfirmoArea">

                <div className="d-flex justify-content-between align-items-start">
                    <h4>
                        <FontAwesomeIcon icon={faCalculator} className="mr-2" /> 
                        Meus pagamentos
                    </h4>
                    <Link to={`${getRootPath()}meus-cartoes`} className="btn btn-info ml-3">
                        <FontAwesomeIcon icon={faCreditCard} className="mr-2" /> 
                        Cartões cadastrados
                    </Link>
                </div>
                <br />

                <div className="cadastroContorno">
                    <div className="cadastroBox2 p-4">

                        {anuidade && anuidade.length > 1 && 

                            <div className="form-inline mb-3">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">
                                            Período: 
                                        </div>
                                    </div>
                                    <select className="form-control" onChange={onChangeAnuidade}>
                                        {anuidade.map((item, key) => {
                                            return (
                                                <option value={item.id} key={key}>
                                                    {item.inicio_formatado} até {item.fim_formatado}
                                                </option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            
                        }



                        {!transacao && 
                            <span style={{fontSize:'20px'}}>
                                <FontAwesomeIcon icon={faCircleNotch} className="fa-spin mr-2 mt-3" /> 
                                Carregando...
                            </span>
                        }




                        {transacao && 

                            <>

                                { expiredCount > 0 && 

                                    <div className="bg-white rounded p-4 text-danger mb-4">
                                        <strong>
                                            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2 fa-flip" /> 
                                            Há parcelas em atraso que não puderam ser processadas com o cartão cadastrado, conforme segue abaixo.
                                        </strong>
                                        <br />
                                        <Link to={getRootPath()+'meus-cartoes/'} className="btn btn-primary mt-2">
                                            Clique aqui para verificar seu cartão
                                        </Link>
                                    </div>
                                }


                                {transacao.length == 0 && 
                                    <h3 className='mt-3'>
                                        Você não tem nenhum pagamento ainda.
                                    </h3>
                                }


                                <div style={{overflowX: 'auto'}}>
                                    <table className="table text-white">
                                        <tbody>
                                            {transacao.map((item, key) => {

                                                let descricao = ''
                                                if(item.programa) { 
                                                    descricao = item.programa.nome
                                                } else if(item.anuidade.assinatura.associacao) {{
                                                    descricao = 'Canal '+item.anuidade.assinatura.associacao.nome}
                                                } else { 
                                                    descricao = 'Assinatura IDDplus'
                                                }

                                                let forma_parcelamento
                                                if(item.quantidade_parcelas == 1) { 
                                                    if(item.status_consolidado != 'cancelled') { 
                                                        forma_parcelamento = 'à vista'
                                                    }
                                                } else { 
                                                    forma_parcelamento = item.parcela + 'ª parcela' 
                                                }

                                                let invoice = []
                                                if(item.nfeIoProductInvoice) { 
                                                    for(const thisInvoice of item.nfeIoProductInvoice) {
                                                        if(thisInvoice.status != 'Issued') { 
                                                            continue
                                                        }
                                                        invoice.push(thisInvoice)
                                                    }
                                                }

                                                let tr_class         = ""
                                                let descricao_status = ""
                                                let status_formatado = item.status_formatado

                                                if(item.status_consolidado == 'paid') { 

                                                    /**
                                                     * Pago
                                                     **/ 
                                                    tr_class    = "text-success"
                                                    const dates = [];
                                                    
                                                    for(let cXt of item.transacaoXyoupayCharge) {
                                                        for(let payment of cXt.youpayCharge.youpayPayment) {
                                                            if(payment.paid_at) { 
                                                                dates.push(payment.paid_at_formatado[0]);
                                                            }
                                                        }
                                                    }
                                                    status_formatado += ' em ' + dates.join(' e ');

                                                } else if(item.status_consolidado == 'not-paid') { 

                                                    /**
                                                     * Não pago
                                                     **/ 
                                                    if(item.vencido) { 
                                                        tr_class         = "text-danger"
                                                        status_formatado = 'atrasado'
                                                    }
                                                    if(item.paymentError) { 
                                                        descricao_status = 
                                                        <div className="d-flex align-items-start">
                                                            <small className="d-block" style={{lineHeight: '130%'}}>
                                                                Falha na cobrança<br />
                                                                {item.paymentError.details}<br />
                                                                Cartão {item.paymentError.youpayCard.brand} **** **** **** {item.paymentError.youpayCard.number} 
                                                            </small>
                                                            <Link to={`${getRootPath()}meus-cartoes`} className="btn btn-warning ml-3">
                                                                <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2 fa-flip" /> 
                                                                Verificar cartões
                                                            </Link>
                                                        </div>
                                                    }

                                                } else if(item.status_consolidado == 'cancelled') {

                                                    /**
                                                     * Cancelado
                                                     **/ 
                                                    tr_class = "text-secondary"
                                                }

                                                return (
                                                    <tr key={key} className={tr_class}>
                                                        <td>
                                                            {item.data_formatado}
                                                        </td>
                                                        <td>
                                                            {descricao}
                                                        </td>
                                                        <td>
                                                            {forma_parcelamento}
                                                        </td>
                                                        <td>
                                                            R$ {item.preco.toPriceFormat()}
                                                        </td>
                                                        <td>
                                                            {status_formatado}
                                                            {descricao_status}
                                                        </td>
                                                        <td>
                                                            { invoice.map((thisInvoice, key) => {
                                                                return getDanfeButton(thisInvoice, key)
                                                            })}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </>

                        }
                                                       

                            
                </div>
            </div>
        </div>
      </div>


    </>
  )
}


