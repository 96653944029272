import axios from "axios";
import { getEnviroment, getUrlIdd } from '../utils/Utils.js';


const apiHost = {
    'development': "http://localhost/idd_online/api.idd.edu.br/public_html/", 
    'test':        "https://testes.idd.edu.br/api/",
    'production':  "https://api.idd.edu.br/",
}




const api = axios.create({
  "baseURL": apiHost[getEnviroment()],
});

api.getHost = () => {
    return apiHost[getEnviroment()]
}

api.getImageUrl = function(url) {
    return getUrlIdd()+'file-repository/'+url
}
// api.getImageUrl = function(url, width=320, height=180) {
    // url = 'file-repository/' + url;
    // url = url.replace(/\//g, '%2F');
    // let newBaseURL = api.getHost().replace(/api.idd.edu.br/, 'idd.edu.br')
    //     newBaseURL = newBaseURL.replace('testes.idd.edu.br/api', 'testes.idd.edu.br/site')
    // return `${newBaseURL}_modulos/imageCache/image.php?file=${url.replace(/\//g, '%2F')}&container=${width}x${height}`;
// }

api.getRepositoryFileUrl = (url) => {
    url = 'file-repository/' + url;
    return api.getHost().replace(/api.idd.edu.br/, 'idd.edu.br')+ url
}

export default api;