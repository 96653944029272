import React from 'react';
import { useParams, useLocation, Link } from "react-router-dom";

import api from "../services/api";
import User from '../utils/User.js';

import { FontAwesomeIcon }        from '@fortawesome/react-fontawesome';
import { faUndo }             from '@fortawesome/free-solid-svg-icons';
import { faArrowAltCircleRight }             from '@fortawesome/free-regular-svg-icons';

import quiz from '../imagens/quiz.jpg'
import './ResultadoQuiz.css';

export default function ResultadoQuiz() {

  const params   = useParams();
  const location = useLocation()


  const [apiReturn, setApiReturn] = React.useState(null);
  const [exibirConcluir, setExibirConcluir] = React.useState(false);

  React.useEffect(() => {
    if(location.state) { 
      if(location.state.recemConcluido) { 
        setExibirConcluir(true)
      }
    }
    let url = `submodulo/${params.submodulo}/?key=${User.getAttr('token')}`
    api
      .get(url)
      .then(res => {
        if(res.data.status === 0) { 
          return null;
        }
        setApiReturn(res.data);
      })
  }, []);

  if (!apiReturn) return null;
  if (apiReturn.status === 0) return null;
  const submodulo = apiReturn.submodulo;

  const acertos  = submodulo.submoduloX_user[0].questionarios_acertos
  const questoes = submodulo.submoduloX_user[0].questionarios_questoes
  const erros    = questoes - acertos

  const sAcertos = (acertos != 1 ? 's' : '')
  const sErros   = (erros   != 1 ? 's' : '')

  const labelRespostasCertas  = `${acertos} resposta${sAcertos} certa${sAcertos}`
  const labelRespostasErradas = `${erros}   resposta${sErros}   errada${sErros}`

  const txtCongratulacoes = (acertos > 0 ? 'Parabéns!' : ':( Não foi dessa vez.')


  return (


    <>

      <img className="img-fluid mb-5" src={quiz} />
      <div className="container">
        <div className="quizTextos">
          <h4>
            {submodulo.modulo.programa.nome}
          </h4>
          <h5>
            Quiz: {submodulo.questionario.nome}
          </h5>
        </div>
        <div className="quizResultado sombra mt-5 mb-3 text-center">
          {txtCongratulacoes}
        </div>
        <p>
          Seu resultado foi:
        </p>
        <div
          className="quizTextos pb-3 pt-2 sombra"
          style={{ backgroundColor: "rgba(50, 46, 127, .7)", borderRadius: 10 }}
        >
          <div className="quizRespostaCerta mt-2" style={{ width: "80%" }}>
            {labelRespostasCertas}
          </div>
          <div className="quizRespostaErrada mt-2" style={{ width: "20%" }}>
            {labelRespostasErradas}
          </div>
        </div>
        <div className="d-flex justify-content-between pt-2">
          { erros && 
            <Link to={`/detalhes/${submodulo.modulo.programa.site_slug}`} className="quizBotao" state={{ autoPlay: submodulo.id }}>
              <FontAwesomeIcon icon={faUndo} className="mt-1 mr-1" />
              Tentar de novo 
            </Link>
          }
          <Link to={`/detalhes/${submodulo.modulo.programa.site_slug}`} className="quizBotao">
            { exibirConcluir ? `Concluir o quiz` : 'Voltar para o curso' }
            <FontAwesomeIcon icon={faArrowAltCircleRight} className="mt-1 ml-2" />
          </Link>
        </div>
      </div>

    </>
  )
}


