import React, { useRef } from 'react';
import ListagemVideos           from '../components/ListagemVideos';

export default function ListagemVideosDaSerie(props) {



    const refPrograma = useRef({})

    const enablePrograma = (key, programa) => {
        if(programa.length > 0) { 
            refPrograma.current[key].style.display = 'block'
        }
    }

    return (
    <>

        {props.programa.map((item, key) => {
            return (
                <div key={key} ref={(element) => refPrograma.current[key] = element} style={{display:'none'}}>
                    <div className="listagemContainer pt-5">
                        <div className="titulosCategorias pb-3">
                            <span className="divisorTitulos">
                            </span>
                            {item.nome}
                        </div>
                    </div>
                    <ListagemVideos carousel={false} onLoadKey={key} onLoadCallback={enablePrograma} programa={item} />
                </div>
            )
        })}

        
        
    </>

  );
}