import React, { useRef } from 'react';
import api from "../services/api";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Autoplay } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/autoplay'

import './BannerSlider.css'


export default function BannerSlider(props) {

    const [carousel, setCarousel] = React.useState([])
    const [imageLoaded, setImageLoaded] = React.useState(false)

    /**
     * Buscar banners
     **/ 
    React.useEffect(() => {
        let url = `carousel/`
        if(props.lancamentos) { 
            url += 'lancamentos/'
        }
        api
        .get(url)
        .then((response) => {
            if(response.data.status === 0) { 
                return null;
            }
            setCarousel(response.data.carousel)
        })
        .catch((err) => {
            console.error("Erro ao buscar pela API: " + err);
        });

    }, [])


    const swiperRef = useRef
    const onLoadImage = (key) => {
        if(key == 0) { 
            setImageLoaded(true)
            swiperRef.current.style.display = 'block'
        }
    }






    /**
     * Renderizar ==========================================================================================
     **/ 
    return (

        <>


            { !imageLoaded && 
                <div className="preloaderBannerSlider">
                  <FontAwesomeIcon icon={faCircleNotch} className="fa-spin" /> 
                </div>
            }

            <Swiper
                ref={swiperRef}
                slidesPerView={1}
                slidesPerGroup={1}
                loop={carousel.length > 1}
                modules={[Navigation, Autoplay]}
                navigation
                autoplay={{
                    delay: 4000,
                    disableOnInteraction: false,
                }}
                style={{display:'none'}}
            >
                {carousel.map((obj, key) => {
                    const image = <img  className="d-block w-100"
                                        src={api.getImageUrl(obj.url_imagem, 1920, 450)}
                                        alt="IDD Plus"
                                        onLoad={() => { onLoadImage(key) }}
                                   />
                    if(obj.link) { 
                        return (
                            <SwiperSlide key={key} >
                                <a href={obj.link} target={obj.target}>
                                    {image}
                                </a>
                            </SwiperSlide>
                        )
                    } else { 
                        return (
                            <SwiperSlide key={key} >
                                {image}
                            </SwiperSlide>
                        )
                    }
                })}
            </Swiper>

      
        </>
    )

}

