import { getUrlIddPlus } from '../utils/Utils.js';
import User              from '../utils/User.js';
import api               from "../services/api";

export default class Checkout {


    static go = (params, toggleModalCadastro) => {


        /**
         * Não tem user, então deve exibir modal de cadastro
         **/ 
        if(!User.getAttr('token') && !params._user) { 
            const options = {
                site_slug:  params.site_slug  ? params.site_slug  : null,
                associacao: params.associacao ? params.associacao : null,
                free:       params.free       ? params.free       : null,
            }
            toggleModalCadastro(true, options)
            return
        }

        /**
         * Gerar transacao pela API
         **/ 
        const postParams = new URLSearchParams()
        if(typeof(params.site_slug) != 'undefined' && params.site_slug) { 
            postParams.append('slug', params.site_slug)
        } else if(typeof(params.associacao) != 'undefined') { 
            postParams.append('associacao', params.associacao)
        }
        let transacaoUrl = 'transacao/';
        if(User.getAttr('token')) { 
            transacaoUrl += `?key=${User.getAttr('token')}`
        } else { 
            postParams.append('_user', params._user)
        }
        api
        .post(transacaoUrl, postParams)
        .then(res => {

            /**
             * Ver impedimentos
             **/ 
            let dismissMessage = null
            if(res.data.status === 0) { 
                dismissMessage = 'Houve algum erro ao salvar seus dados.'
            }
            if(res.data.ja_assinante) { 
                dismissMessage = 'Você já tem uma assinatura atualmente.'
            }

            /**
             * Cancelar checkout
             **/ 
            if(dismissMessage) { 
                alert(dismissMessage);
                if(typeof(params.onDismiss) == 'function') { 
                    params.onDismiss()
                }
                return
            }

            /**
             * Prosseguir pro pagamento
             **/ 
            let url = getUrlIddPlus()
            if(typeof(params.site_slug) == 'undefined') { 
                url += `assinatura/pagamento/`
            } else { 
                url += `on-demand/${params.site_slug}/`
            }
            url += res.data.paymentKey
            document.location = url
        })
    }
}
