import React, { useState } from 'react';
import Cookies  from 'universal-cookie'
import { useParams, Link }       from "react-router-dom";

import api  from "../services/api";
import User from '../utils/User.js';
import { getRootPath } from '../utils/Utils.js';

import download_ebook from '../imagens/download-ebook.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch }   from '@fortawesome/free-solid-svg-icons'


export default function Quiz() {

    const params   = useParams();
    const indicadorResposta = React.useRef([])

    const [submodulo,           setSubmodulo           ] = useState(0);
    const [quantQuestoes,       setQuantQuestoes       ] = useState(0);
    const [quantAcertos,        setQuantAcertos        ] = useState(0);
    const [respostaSelecionada, setRespostaSelecionada ] = useState([]);
    const [showResult,          setShowResult          ] = useState(false);
    const [finalizado,          setFinalizado          ] = useState(false);
    const [linkDetalhes,        setLinkDetalhes        ] = useState(false);

    const word = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']

    React.useEffect(() => {

        if(!params.submodulo) { 
            return null
        }
        api
        .get(`submodulo/${params.submodulo}`)
        .then((response) => {

            // Erro no Ajax
            let error = false;
            if(!response.data) error = true;
            if(response.data.status === 0) error = true;
            if(error) { 
                console.log('ERRO AO BUSCAR DADOS DO QUIZ');
                return
            }

            // URL incorreta (manipulada)
            const submod = response.data.submodulo
            if(submod.modulo.programa.site_slug != params.site_slug) { 
                console.log('O slug na URL não corresponde ao submodulo');
                return
            }

            // Setar submodulo
            setSubmodulo(submod)
            setQuantQuestoes(submod.questionario.questao.length);
            setLinkDetalhes(`${getRootPath()}/detalhes/${submod.modulo.programa.site_slug}`);

        })
        .catch((err) => {
            console.error("Erro ao buscar pela API: " + err);
        });

    }, [params.submodulo])



    const clickResposta = (q, r) => {
        for(let i=0; i<submodulo.questionario.questao[q].resposta.length; i++) {
            const indicadorRespostaKey = q+'_'+i
            if(i == r) { 
                indicadorResposta.current[indicadorRespostaKey].classList.add('selecionado')
                respostaSelecionada[q] = i
                setRespostaSelecionada(respostaSelecionada)
            } else { 
                indicadorResposta.current[indicadorRespostaKey].classList.remove('selecionado')
            }
        }
    }


    const finalizarQuiz = () => {


        /**
        * Contar acertos
        **/ 
        let acertos = 0;
        for(let q=0;  q<submodulo.questionario.questao.length; q++) {
            if(typeof(respostaSelecionada[q]) == 'undefined') { 
                alert(`Por favor, assinale todas as respostas.`)
                return
            }
            const r = respostaSelecionada[q]
            if(submodulo.questionario.questao[q].resposta[r].correta == 1) { 
                acertos++;
            }
        }

        /**
        * Salvar no banco
        **/ 
        var params = new URLSearchParams();
        params.append('acao', 'salvarResultadoQuiz')
        params.append('submodulo', submodulo.id)
        params.append('questionario_questoes', quantQuestoes)
        params.append('questionario_acertos', acertos)
        api
        .post(`submoduloX_user/?key=${User.getAttr('token')}`, params)
        .then(res => {
            if(res.data.status === 0) { 
                console.log('Erro ao salvar quantidade de acertos do usuário');
                return null;
            }
        })

        /**
        * Exibir resultado
        **/ 
        document.querySelector('body').classList.add('blur')
        setShowResult(true)
        setQuantAcertos(acertos)

        setFinalizado(true)

    }



    const exibirResolucao = () => {
        setShowResult(false)

        for(let q=0;  q<submodulo.questionario.questao.length; q++) {
            const questao = submodulo.questionario.questao[q]
            for(let r=0; r<questao.resposta.length; r++) {
                if(questao.resposta[r].correta == 1) { 
                    indicadorResposta.current[q+'_'+r].classList.add('correto')
                    if(respostaSelecionada[q] != r) {
                        indicadorResposta.current[q+'_'+respostaSelecionada[q]].classList.add('incorreto')
                    }
                }
            }
        }
    }



    const clickSair = (e) => {
        if(finalizado) { 
            return
        }
        if(!confirm('Deseja sair sem concluir o quiz?')) { 
            e.preventDefault()
        }
    }



    const verVideoResposta = () => {
        const cookies = new Cookies();
        cookies.set('autoPlay', 'continue', { path: '/' })
        document.location = `/detalhes/${params.site_slug}`
    }


    let fileUrl = null
    if(submodulo) { 
        if(submodulo.questionario.file != null) { 
            fileUrl = api.getHost()+'quiz/'+submodulo.questionario.id+'/?key='+User.getAttr('token')
        }
    }





    return (


    <>
        <div style={{marginTop:'100px'}}>
        </div>

        { !submodulo && 
            <div className="text-center">
            <FontAwesomeIcon icon={faCircleNotch} />
            </div>
        }


        { showResult && 
            <>
                <div className="quizResultBlur">
                </div>
                <div className="quizResult">
                    <div className="quizResultTitle">
                        VOCÊ ACERTOU
                    </div>
                    <div className="quizResultNum">
                        {quantAcertos}/{quantQuestoes}
                    </div>
                    <button className="btn btn-depoimentos mr-3" onClick={() => { window.location.reload() }} >
                        REFAZER O QUIZ
                    </button>
                    <a href="#inicioQuestoes" className="btn btn-depoimentos mr-3" onClick={exibirResolucao} >
                        CONFERIR ERROS E ACERTOS
                    </a>
                    <button className="btn btn-depoimentos" onClick={verVideoResposta} >
                        VER VÍDEO DA RESPOSTA
                    </button>
                </div>
            </>
        }


        {submodulo && 

            <>
                    <div className="areaQuiz">

                        <div className="areaQuizTitulo">

                            <div className="tituloQuiz">
                                <span className="tituloQuizCor">
                                Quiz
                                </span>
                                - {submodulo.questionario.nome}
                            </div>
                            { fileUrl != null && 
                                <a href={fileUrl} className="btn btn-quiz" target="_blank" rel="noreferrer" >
                                    Baixar versão para Impressão
                                    <img src={download_ebook} width="20" height="20" className="ml-2" />
                                </a>
                            }
                        </div>


                        <a id="inicioQuestoes" className="anchorTag">
                        </a>


                        { submodulo && submodulo.questionario.questao.map((questao, q) => {

                            return (
                                <div className="areaQuizPergunta" key={q}>
                                    <div className="QuizPergunta">
                                        Questão {q+1}
                                        <div className="quizPerguntaSublinha">
                                        </div>
                                    </div>

                                    <div dangerouslySetInnerHTML={{__html: questao.enunciado}} >
                                    </div>
                                    <div className="clearfix">
                                    </div>

                                    <div className="form-check areaQuizRespostas mt-3">
                                        {questao.resposta.map((resposta, r) => {

                                            const indicadorRespostaKey = q+'_'+r

                                            return (
                                                <div key={r}>
                                                    <a className="d-flex" href="javascript:; " onClick={(event) => clickResposta(q, r, event)}>
                                                        <div>
                                                            <div ref={(element) => indicadorResposta.current[indicadorRespostaKey] = element} className="indicadorResposta">
                                                                <div className="indicadorRespostaStatus">
                                                                </div>
                                                            </div>
                                                            <span className="tituloQuizCor mr-2">
                                                                {word[r]})
                                                            </span> 
                                                        </div>
                                                        <span dangerouslySetInnerHTML={{__html: resposta.descricao}} >
                                                        </span>
                                                    </a>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })
                    }





                </div>

                { !finalizado && submodulo && 
                    <div className="areaQuizBotao">
                        <div className="linhaQuizBotao">
                        </div>
                        <button className="btn btn-quiz2" onClick={finalizarQuiz} >
                            FINALIZAR E OBTER RESULTADOS
                        </button>
                    </div>
                }


                { finalizado && 
                    <div className="areaQuizBotao">
                        <div className="linhaQuizBotao">
                        </div>
                        <button className="btn btn-quiz2 mr-3" onClick={() => { window.location.reload() }} >
                            Refazer o quiz
                        </button>
                        <button className="btn btn-quiz2 mr-3" onClick={verVideoResposta} >
                            Ver o vídeo da resposta
                        </button>
                        <Link to={linkDetalhes} className="btn btn-quiz2" onClick={clickSair}>
                            Sair
                        </Link>
                    </div>
                }

            </>

        }

        
    </>
)}

