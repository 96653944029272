import React, { useState, useRef }   from 'react'
import api                           from "../services/api";
import Cookies                       from 'universal-cookie'
import Estrelas                      from './Estrelas.jsx';
import ProgramaHelper                from '../utils/ProgramaHelper.js';
import User                          from '../utils/User.js';
import { getRootPath }               from '../utils/Utils.js'

import { FontAwesomeIcon }           from '@fortawesome/react-fontawesome';
import { faCircleNotch }             from '@fortawesome/free-solid-svg-icons';

import BarraCarregamento             from '../components/BarraCarregamento';

import '../utils/stringFunctions.js';
import './ListagemVideos.css';

var showingThumb = null
var thumbToShow = null
var timeoutShow = null
var isHidingDetail = false;



export default function ListagemVideos(props) {


    const [itemListagem, setItemListagem] = useState(null);
    const [uniqueKey, setUniqueKey] = useState(null);
    const thumb = useRef([])
    const [isHoveringRow, setIsHoveringRow] = React.useState(false)


    /**
     * Salvar uniqueKey desta listagem
     **/ 
    React.useEffect(() => {
        setUniqueKey(ListagemVideos.uniqueKey)
        ListagemVideos.uniqueKey++;
    }, [])


    /**
     * Buscar programas
     **/ 
    React.useEffect(() => {

     
        let url;
        if(typeof(props.categoria) != 'undefined') { 
            url = `modulo/categoria/${props.categoria}/`
        } else if(typeof(props.palavraChave) != 'undefined') { 
            url = `programa/palavra-chave/${props.palavraChave}/`
        } else if(typeof(props.associacao) != 'undefined') { 
            url = `programa/associacao/${props.associacao}/`
        } else if(typeof(props.lancamentos) != 'undefined') { 
            url = `programa/lancamentos/`
        } else if(typeof(props.destaque) != 'undefined') { 
            url = `programa/destaque/`
        } else if(typeof(props.on_demand) != 'undefined') { 
            url = `programa/on_demand/`
        } else if(typeof(props.continue) != 'undefined') { 
            url = `programa/continue/`
        } else if(typeof(props.programa) != 'undefined') { 
            url = `programa/${props.programa.site_slug}`
        } else { 
            url = `programa/`
        }
        const getParams = []
        if(typeof(props.semAssociacao) != 'undefined') { 
            getParams.push('sem_associacao=1')
        }
        if(typeof(props.lancamentos) != 'undefined' && url.indexOf('/lancamentos/') === -1) { 
            getParams.push('lancamentos=1')
        }
        if(User.getAttr('token')) { 
            getParams.push(`key=${User.getAttr('token')}`)
        }
        if(getParams.length) { 
            url += '?'+getParams.join('&')
        }

        api.get(url)
        .then(res => {
            if(!res.data)             return null;
            if(res.data.status === 0) return null;
            if(!res.data.programa && !res.data.modulo)    return null


            // Array de itens a exibir 
            const item = [];

            if(typeof(res.data.modulo) != 'undefined') { 

                /**
                 * Exibir temporadas =======================================================================================================
                 **/ 
                const todosModulos = res.data.modulo
                let key = -1
                for(let modulo of todosModulos){

                    key++

                    item[key] = {
                        url:                `${getRootPath()}detalhes/${modulo.programa.site_slug}/`, 
                        url_imagem:         api.getImageUrl(modulo.url_imagem), 
                        programaIniciado:   (modulo.submoduloAtual              ? true                                                   : false                               ), 
                        ano:                (modulo.programa.data == null       ? ''                                                     : modulo.programa.data.substring(0, 4)),
                        descricao:          (modulo.programa.site_sobre == null ? ''                                                     : modulo.programa.site_sobre          ),
                        labelAssistir:      (modulo.submoduloAtual              ? <>Assistir<br />agora</>                               : <>Continuar<br />assistindo</>      ),
                        labelEpisodioAtual: (modulo.submoduloAtual              ? ProgramaHelper.getEpisodioLabel(modulo.submoduloAtual) : ''                                  ),
                    }
                }

            } else if(typeof(props.programa) != 'undefined') { 

                /**
                 * Exibir episódios de um só programa ======================================================================================
                 **/ 
                const programa = res.data.programa
                let key = -1
                for(let modulo of programa.modulo){

                    for(let submodulo of modulo.submodulo){

                        key++

                        item[key] = {
                            url:               `${getRootPath()}detalhes/${programa.site_slug}/`, 
                            submoduloAutoPlay: submodulo.id,
                            autoPlayOnClick:   true, 
                        }

                        if(submodulo.video) { 
                            item[key].url_imagem = submodulo.video.thumbnail_url
                        } else { 
                            item[key].url_imagem = api.getImageUrl(submodulo.url_imagem)
                        }

                        if(!submodulo.submoduloX_user) { 
                            item[key].programaIniciado = false
                        } else { 
                            item[key].programaIniciado = true
                            item[key].video_actual_time = submodulo.submoduloX_user.video_actual_time
                        }

                        if(programa.data == null) { 
                            item[key].ano = '';
                        } else { 
                            item[key].ano = programa.data.substring(0, 4);
                        }

                        if(submodulo.descricao == null) { 
                            item[key].descricao = '';
                        } else { 
                            item[key].descricao = submodulo.descricao;
                        }
                        if(!submodulo.video) { 
                            if(!item[key].programaIniciado) { 
                                item[key].labelAssistir = <>Responder<br />agora</>
                                item[key].porcentagem = 0
                            } else { 
                                item[key].labelAssistir = <>Tentar<br />novamente</>
                                item[key].porcentagem = 100
                            }
                        } else { 
                            if(!item[key].programaIniciado) { 
                                item[key].labelAssistir    = <>Assistir<br />agora</>
                            } else { 
                                item[key].labelAssistir = <>Continuar<br />assistindo</>
                                if(submodulo.video.duration) { 
                                    item[key].porcentagem = submodulo.submoduloX_user.video_actual_time * 100 / submodulo.video.duration
                                } else { 
                                    item[key].porcentagem = 0
                                }
                            }
                        }

                        programa.submoduloAtual = submodulo
                        programa.submoduloAtual.modulo = modulo
                        item[key].labelEpisodioAtual = ProgramaHelper.getEpisodioAtualUser(programa, true)
                    }
                }


            } else { 

                /**
                 * Exibir vários programas =============================================================================================
                 **/ 
                for(let key in res.data.programa){

                    const programa = res.data.programa[key]

                    item[key] = {
                        url:                              `${getRootPath()}detalhes/${programa.site_slug}/`, 
                        url_imagem:                       api.getImageUrl(programa.url_imagem, 400, 350),
                        avaliacao:                        programa.avaliacao,
                        quantidade_submodulos_assistidos: programa.quantidade_submodulos_assistidos,
                        quantidade_submodulos:            programa.quantidade_submodulos,
                    }

                    if(programa.data == null) { 
                        item[key].ano = '';
                    } else { 
                        item[key].ano = programa.data.substring(0, 4);
                    }

                    if(programa.site_sobre == null) { 
                        item[key].descricao = '';
                    } else { 
                        item[key].descricao = programa.site_sobre;
                    }
                    item[key].labelAssistir    = <>Assistir<br />agora</>
                    item[key].programaIniciado = false
                    if(programa.programaX_user) { 
                        if(programa.programaX_user.id) { 
                            item[key].labelAssistir = <>Continuar<br />assistindo</>
                            item[key].programaIniciado = true
                        }
                    }

                    item[key].labelEpisodioAtual = "";
                    if(item[key].programaIniciado) { 
                        item[key].labelEpisodioAtual = ProgramaHelper.getEpisodioAtualUser(programa, true)
                    }
                }
            }

            setItemListagem(item);

            /**
             * Callback dado pelo componente pai
             **/ 
            if(typeof(props.onLoadCallback) == 'function') {
                props.onLoadCallback(props.onLoadKey, item);
            }
        })


    }, [props.programa, 
        props.categoria, 
        props.palavraChave,
        props.associacao,
        props.lancamentos,
        props.destaque,
        props.on_demand,
        props.continue,
        props.semAssociacao,
    ]);



    const [navTransitionDelay, setNavTransitionDelay] = useState(null);
    const [initialMarginLeft, setInitialMarginLeft]   = useState(null);
    const [thumbnailLoaded, setThumbnailLoaded]       = useState(false);

    const row        = useRef(null);
    const slidingRow = useRef(null);
    const prevButton = useRef(null)
    const nextButton = useRef(null)




    /**
     * Só vai rodar depois de carregar algum thumbnail
     * Ou seja, já definiu altura e carregou rows, prev e next
     **/ 
    React.useEffect(() => {
        if(!thumbnailLoaded) { 
            return
        }
        if(!props.carousel) { 
            return
        }
        /**
         * Setar altura dos botões previous / next 
         **/ 
        prevButton.current.style.height = Math.round(slidingRow.current.getBoundingClientRect().height) + 'px'
        nextButton.current.style.height = Math.round(slidingRow.current.getBoundingClientRect().height) + 'px'

        /**
         * Salvar
         **/ 
        setNavTransitionDelay(parseFloat(window.getComputedStyle(slidingRow.current).getPropertyValue('transition-duration')) * 1000)
        setInitialMarginLeft(getSlidingRowMargin())

    }, [thumbnailLoaded]);




    /**
     * Exibir / ocultar next e prev
     **/ 
    const togglePrevButton = () => {
        const firstThumbLeft      = thumb.current[0].offsetLeft
        const isFirstThumbVisible = firstThumbLeft >= 0

        if(!isFirstThumbVisible && isHoveringRow && !isMobile()) { 
            prevButton.current.style.visibility = 'visible'
        } else { 
            prevButton.current.style.visibility = 'hidden'
        }
    }
    const toggleNextButton = () => {
        const lastThumb      = thumb.current.at(-1)
        const lastThumbLeft  = lastThumb.offsetLeft
        const lastThumbWidth = lastThumb.getBoundingClientRect().width
        const rowWidth       = getRowWidth()
        const isLastThumbVisible = lastThumbLeft + lastThumbWidth <= rowWidth

        if(!isLastThumbVisible && isHoveringRow && !isMobile()) { 
            nextButton.current.style.visibility = 'visible'
            nextButton.current.style.marginLeft = rowWidth - nextButton.current.getBoundingClientRect().width + 'px'    // Posicionar no lugar certo conforme tamanho da row
        } else { 
            nextButton.current.style.visibility = 'hidden'
        }
    }



    /**
     * Over e out da row inteira
     **/ 
    const rowMouseEnter = () => {
        setIsHoveringRow(true)
    }
    const rowMouseLeave = () => {
        setIsHoveringRow(false)
    }
    React.useEffect(() => {
        if(!thumbnailLoaded) { 
            return
        }
        if(!props.carousel) { 
            return
        }
        if(!isHoveringRow) { 
            prevButton.current.style.visibility = 'hidden'
            nextButton.current.style.visibility = 'hidden'
        } else { 
            togglePrevButton()
            toggleNextButton()
        }

    }, [isHoveringRow]);



    /**
     * Retorna distância a mover o container no previous / next
     **/ 
    const getOneSlideLength = () => {
        const thumbsDistance = thumb.current[0].offsetLeft - thumb.current[1].offsetLeft
        const thumbQtd       = Math.floor(getRowWidth() / thumbsDistance)
        return thumbQtd * thumbsDistance
    }



    /**
     * Next / previous
     **/ 
    const prevButtonClick = () => {

        const distance   = getOneSlideLength()
        let   margin     = getSlidingRowMargin() + distance

        // Tirar over de thumb
        hideDetails(null, true);

        // Alterar margin
        if(margin > initialMarginLeft) { 
            margin = initialMarginLeft
        }
        setSlidingRowMargin(margin)

        // Ocultar prev / next se necessário
        setTimeout(() => {
            togglePrevButton()
            toggleNextButton()
        }, navTransitionDelay+1);
    }
    const nextButtonClick = () => {

        const distance     = getOneSlideLength()
        const actualMargin = getSlidingRowMargin()
        let   margin       = actualMargin - distance

        // Tirar over de thumb
        hideDetails(null, true);

        // Alterar margin
        const rowContentWidth = slidingRow.current.offsetWidth
        const rowWidth        = getRowWidth()
        if(rowContentWidth - rowWidth < Math.abs(margin)) { 
            margin = (rowContentWidth - rowWidth) * -1
        }
        setSlidingRowMargin(margin)

        // Ocultar prev / next se necessario
        setTimeout(() => {
            togglePrevButton()
            toggleNextButton()
        }, navTransitionDelay+1);
    }


    const getRowWidth = () => {
        return row.current.getBoundingClientRect().width
    }

    const getSlidingRowMargin = () => {
        return parseInt(window.getComputedStyle(slidingRow.current).getPropertyValue('margin-left'))
    }
    const setSlidingRowMargin = (margin) => {
        slidingRow.current.style.marginLeft = `${margin}px`
    }
    const getWindowWidth = () => {
        return document.body.clientWidth
    }



    /**
     * Resize da janela: dá scroll caso fique espaço vazio
     **/ 
    window.addEventListener('resize', () => {

        // Evitar vários resize ao mesmo tempo
        if(ListagemVideos.resizedTimeout[uniqueKey]) { 
            clearTimeout(ListagemVideos.resizedTimeout[uniqueKey])
        }

        // On resize
        ListagemVideos.resizedTimeout[uniqueKey] = setTimeout(() => {
            const rowContentWidth = slidingRow.current.offsetWidth
            const rowWidth        = getRowWidth()

            if(rowContentWidth > rowWidth && rowContentWidth - rowWidth < Math.abs(getSlidingRowMargin())) { 
                setSlidingRowMargin((rowContentWidth - rowWidth) * -1);
            }
        }, 500)
    })

    

    const showDetailsDelay = 500

    const thumbMouseEnter = (key) => {
        thumbToShow = uniqueKey+'-'+key
        if(timeoutShow) { 
            clearTimeout(timeoutShow)
        }
        if(showingThumb === null) { 
            timeoutShow = setTimeout(() => { showDetails() }, showDetailsDelay)
        }
    }

    const thumbMouseLeave = () => {
        thumbToShow = null
        if(timeoutShow) { 
            clearTimeout(timeoutShow)
        }
    }



    

    const showDetails = () => {

        timeoutShow = null
        showingThumb = thumbToShow

        const thisThumb = document.getElementById('thumb'+thumbToShow)
        const key       = Number(thumbToShow.split('-')[1])
        thumbToShow     = null


        // Tamanho e posição original
        const originalWidth = thisThumb.offsetWidth
        const originalTop   = thisThumb.offsetTop 
        let   originalLeft  = thisThumb.offsetLeft 

        // Inserir HTML de details no over
        const thumbOver = document.getElementById('thumb-over')
        document.getElementById('slider-thumb-over-detail').innerHTML = thisThumb.querySelector('.slider-thumb-detail').innerHTML

        // Colocar imagem no over (não pode duplicar no innerHTML porque senão ele recarrega a imagem)
        const thumbImg = thisThumb.querySelector('.slider-thumb-img img')
        const sliderRowContent = thisThumb.parentElement
        const sliderRowContentHeight = sliderRowContent.offsetHeight+'px'       // pegar altura da linha com a imagem
        sliderRowContent.style.minHeight = sliderRowContentHeight               // manter a altura mesmo sem a imagem retirada
        document.getElementById('slider-thumb-over-img').appendChild(thumbImg);
        document.getElementById('slider-thumb-over-img').href = thisThumb.querySelector('.slider-thumb-img').href;

        if(thisThumb.querySelector('.slider-thumb-img').classList.contains('linkAssistir')) { 
            document.getElementById('slider-thumb-over-img').classList.add('linkAssistir')
        } else { 
            document.getElementById('slider-thumb-over-img').classList.remove('linkAssistir')
        }



        thumbOver.onmouseleave = () => {
            if(showingThumb === null) { 
                return;
            }
            if(!isHidingDetail) { 
                hideDetails()
            }
        }

        // Click de "assistir" ou "continuar assistindo" 
        let onClickAssistir;
        if(thisThumb.getAttribute("data-submodulo_auto_play") != '0') { 
            onClickAssistir = () => {
                const cookies = new Cookies();
                cookies.set('autoPlay', thisThumb.getAttribute("data-submodulo_auto_play"), { path: '/' })
            }
        } else if(thisThumb.getAttribute('data-programa_iniciado')) { 
            onClickAssistir = () => {
                const cookies = new Cookies();
                cookies.set('autoPlay', 'continue', { path: '/' })
            }
        } else { 
            onClickAssistir = null
        }
        for(let htmlObj of thumbOver.getElementsByClassName('linkAssistir')) {
            htmlObj.onclick = onClickAssistir
        }

        // Tamanhos dos botões prev e next
        let prevButtonWidth = 0;
        if(key != 0) { 
            prevButtonWidth = prevButton.current.offsetWidth
        }
        const isLastThumb = (key == (thumb.current.length -1))
        let nextButtonWidth = 0
        if(!isLastThumb) { 
            nextButtonWidth = nextButton.current.offsetWidth
        }


        // Se o thumbnail não está inteiro visível na janela, dá scroll até entrar
        if(!props.carousel) { 
            if(thisThumb.offsetLeft < 0 + prevButtonWidth) { 
                const margin = getSlidingRowMargin() - thisThumb.offsetLeft + prevButtonWidth
                originalLeft += margin - getSlidingRowMargin()
                setSlidingRowMargin(margin)
            } else { 
                const outToRight = thisThumb.offsetLeft + originalWidth - nextButtonWidth > getWindowWidth()
                if(outToRight) { 
                    const margin = getSlidingRowMargin() - ((thisThumb.offsetLeft + originalWidth + nextButtonWidth) - getWindowWidth())
                    originalLeft -= getSlidingRowMargin() - margin
                    setSlidingRowMargin(margin)
                }
            }
        }
        

        // Estado inicial
        thumbOver.style.width = originalWidth + 'px'
        thumbOver.style.top   = originalTop   + 'px'
        thumbOver.style.left  = originalLeft  + 'px'
        thumbOver.style.display = 'block'

        // Depois de aumentado
        const scale           = 1.5
        const finalWidth      = originalWidth * scale
        const diff            = (finalWidth - originalWidth) / 2
        const scaledLeft      = originalLeft - diff

        let   transformOrigin = '50% 50%';
        let   left            = originalLeft
        let   top             = originalTop

        if(scaledLeft - prevButtonWidth < 0) { 

            // Caso esteja à esquerda da área visível
            transformOrigin = '0% 50%';
            if(left < prevButtonWidth) { 
                left = prevButtonWidth
            }

        } else {

            // Caso exceda o tamanho à direita
            if(scaledLeft + finalWidth + nextButtonWidth > getWindowWidth()) { 
                transformOrigin = '100% 50%';
                if(left + originalWidth + nextButtonWidth > getWindowWidth()) { 
                    left = getWindowWidth() - originalWidth - nextButtonWidth
                }
            }
        }

        thumbOver.style.transform       = `scale(${scale})`
        thumbOver.style.transformOrigin = transformOrigin
        thumbOver.style.top             = top
        thumbOver.style.left            = left
        
        // Detalhes saindo de trás da img
        const thubmOverDetail = document.getElementById('slider-thumb-over-detail')
        thubmOverDetail.style.marginTop = `-${thubmOverDetail.offsetHeight}px`
        setTimeout(() => {
            thubmOverDetail.style.marginTop = '0px'
        }, 0);

        // Barra de andamento 
        if(thumbOver.getElementsByClassName('slider-thumb-over-andamento').length) { 
            thumbOver.getElementsByClassName('slider-thumb-over-andamento')[0].style.display = 'block'
            setTimeout(() => {
                thumbOver.getElementsByClassName('slider-thumb-over-andamento')[0].style.opacity = 1
            }, 0);
        }

        if(isMobile()) {
            const overTransitionDelay = parseFloat(window.getComputedStyle(thumbOver).getPropertyValue('transition-duration')) * 1000;
            setTimeout(() => {
                thumbOver.querySelector('img').onclick = () => {
                    hideDetails()
                }
            }, overTransitionDelay)
        }
    }


    const hideDetails = () => {

        isHidingDetail = true

        const thumbOver = document.getElementById('thumb-over')

        // Animação diminuindo
        thumbOver.style.transform = 'scale(1)'
        thumbOver.style.zIndex    = 99
        
        // Esconder andamento
        const thubmOverDetail = document.getElementById('slider-thumb-over-detail')
        thubmOverDetail.style.marginTop = `-${thubmOverDetail.offsetHeight}px`

        const thumbOverAndamento = thubmOverDetail.querySelector('.slider-thumb-over-andamento')
        if(thumbOverAndamento) { 
            thumbOverAndamento.style.display = 'none'
            thumbOverAndamento.style.opacity = 0
        }

        // Oculta next / prev, se for o caso
        if(!props.carousel) { 
            togglePrevButton()
            toggleNextButton()
        }
        
        // depois da animação de ocultar
        const overTransitionDelay = parseFloat(window.getComputedStyle(thumbOver).getPropertyValue('transition-duration')) * 1000;

        setTimeout(function() {

            // Devolver imagem do over pro slider
            document.getElementById('thumb'+showingThumb).querySelector('.slider-thumb-img').appendChild(document.querySelector('#slider-thumb-over-img img'))

            // Esconder over
            showingThumb   = null
            thumbOver.style.display = 'none'

            // Terminou de esconder detalhes
            isHidingDetail = false

            // Próximo timeOutShow
            if(thumbToShow) { 
                timeoutShow = setTimeout(showDetails, showDetailsDelay)
            }
        }, overTransitionDelay)
    }








    const isMobile = () => {
        return window.getComputedStyle(row.current).getPropertyValue('overflow-x') != 'hidden'
    }


    const sliderRowStyle        = (props.carousel ? {} : {display: 'flex'}                  )
    const sliderRowContentStyle = (props.carousel ? {} : {whiteSpace: 'normal', flexGrow: 1})
    const sliderThumbStyle      = (props.carousel ? {} : {marginBottom: '15px'}             )




    /**
    * Renderizar ==========================================================================================
    **/ 
    return (

        <>

            { uniqueKey === 0 && 
                <div id="thumb-over" className="slider-thumb-over">
                    <a id="slider-thumb-over-img">
                    </a>
                    <div id="slider-thumb-over-detail" className="slider-thumb-detail">
                    </div>
                </div>
            }

            { !itemListagem && 
                <div className="preloaderListagemVideos slider-row-content d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faCircleNotch} className="mt-1 mr-3 fa-spin fa-5x" /> 
                </div>
            }

            <div ref={row} onMouseEnter={rowMouseEnter} onMouseLeave={rowMouseLeave} className="slider-row pb-2" style={sliderRowStyle}>


                { itemListagem && 
              
                    <>

                        <a ref={prevButton} onClick={prevButtonClick} className="slider-prev">
                        </a>
                        <a ref={nextButton} onClick={nextButtonClick} className="slider-next">
                        </a>

                        <div ref={slidingRow} className="slider-row-content" style={sliderRowContentStyle} >

                            {itemListagem.map(function(item, i) { 



                                let sliderThumbCss = "slider-thumb"
                                if(!props.carousel) { 
                                    sliderThumbCss += " slider-thumb-mosaico"
                                }

                                let sliderThumbImgCss = "slider-thumb-img"
                                if(item.autoPlayOnClick) { 
                                    sliderThumbImgCss += " linkAssistir"
                                }

                                let thumbDetailClass = "slider-thumb-detail";
                                if(!item.programaIniciado) { 
                                    thumbDetailClass += " pt-3";
                                }

                                const programa_iniciado   = (item.programaIniciado  ? '1'                    : '0')
                                const submodulo_auto_play = (item.submoduloAutoPlay ? item.submoduloAutoPlay : '0')

                                const id = `thumb${uniqueKey}-${i}`


                                return (
                                    <div    key={i} 
                                            id={id}
                                            onMouseEnter={() => { thumbMouseEnter(i) } } 
                                            onMouseLeave={thumbMouseLeave} 
                                            ref={(element) => thumb.current.push(element)} 
                                            className={sliderThumbCss} 
                                            data-programa_iniciado={programa_iniciado} 
                                            data-submodulo_auto_play={submodulo_auto_play} 
                                            style={sliderThumbStyle} 
                                        >

                                        <a className={sliderThumbImgCss} href={item.url}>
                                            <img src={item.url_imagem} alt="" onLoad={() => { setThumbnailLoaded(true) }} />
                                        </a>

                                        <div className={thumbDetailClass} >

                                            { item.programaIniciado && 
                                                <div className="slider-thumb-over-andamento">
                                                    <>
                                                        <div className="listagemBoxTemporada">
                                                            {item.labelEpisodioAtual}
                                                        </div>
                                                        {props.programa && 
                                                            <BarraCarregamento porcentagem={item.porcentagem} deThumbnail={true} />
                                                        }
                                                        {!props.programa && 
                                                            <BarraCarregamento programa={item} deThumbnail={true} />
                                                        }
                                                    </>
                                                </div>
                                            }
                                            <div className="listagemBoxDescricao">
                                                {item.descricao.abbreviate(190).nl2br()}
                                            </div>
                                            
                                            <div className="baseListagemHome">
                                                <a className="assistirListagemHome listagemContinuarAssisitindo linkAssistir" href={item.url}>
                                                    {item.labelAssistir}
                                                </a>
                                                <a className="assistirListagemHome listagemMaisDetalhes" href={item.url}>
                                                    Mais <br />
                                                    conteúdo
                                                </a>
                                            </div>

                                            <div className="listagemBoxEstrelas">
                                                {!item.avaliacao && 
                                                    <>&nbsp;</>
                                                }
                                                {item.avaliacao && 
                                                    <Estrelas avaliacao={item.avaliacao} estrelaClassName="DashboardEstrela" />
                                                }
                                                <div className="listagemAnoVideo">
                                                    {item.ano}
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                )
                            })}

                        </div>
                    </>
                }
            </div>
      
        </>
    )

}

ListagemVideos.uniqueKey = 0;
ListagemVideos.resizedTimeout = [];
