import React, { useRef }          from 'react'
import api                        from "../services/api";
import { Link, useOutletContext } from "react-router-dom";
import { FontAwesomeIcon }        from '@fortawesome/react-fontawesome';
import { faCircleNotch }          from '@fortawesome/free-solid-svg-icons';

import ProgramaHelper             from '../utils/ProgramaHelper.js';
import IsValid                    from '../utils/IsValid.js';
import User                       from '../utils/User.js';
import { getRootPath }            from '../utils/Utils.js';
import BarraCarregamento          from '../components/BarraCarregamento';
import FormularioCadastro         from '../components/FormularioCadastro';


export default function MeusDados() {

    const inputFile             = useRef(null)
    const inputSenha            = useRef(null)
    const inputSenhaConfirmacao = useRef(null)

    const [ programa,         setPrograma         ] = React.useState([]);
    const [ programasLoaded,  setProgramasLoaded  ] = React.useState(false);
    const [ imageUrl,         setImageUrl         ] = React.useState(null);
    const [ _user,            setUser             ] = React.useState(null);
    const [ nome,             setNome             ] = React.useState(null);
    const [ showPreloaderFoto, setShowPreloaderFoto ] = React.useState(false);
    const [ showInputSenha, setShowInputSenha ] = React.useState(false);
    const [ showPreloaderSenha, setShowPreloaderSenha ] = React.useState(false);
    const [ showSenhaAlterada, setShowSenhaAlterada ] = React.useState(false);


    const setMenuUsuarioTimestamp = useOutletContext().setMenuUsuarioTimestamp;


    React.useEffect(() => {

        /**
         * Buscar dados do _user
         **/ 
        api
        .get(`_user/?key=${User.getAttr('token')}`)
        .then((response) => {

            // Erro no ajax
            if(response.data.status === 0) { 
                return
            }
            const _user = response.data._user
            if(_user.userType.telefone) { 
                _user.userType.telefone = _user.userType.telefone[0]
            }
            setUser(_user)
            setNome(_user.userType.nome)
            if(_user.userType.url_imagem) { 
                setImageUrl(api.getImageUrl(_user.userType.url_imagem));
            }

        })
        .catch((err) => {
            console.error("Erro ao buscar pela API: " + err);
        });

        /**
         * Buscar programas
         **/ 
        api.get(`programa/continue/?key=${User.getAttr('token')}`)
        .then(res => {
            if(res.data.status === 0) { 
                return null;
            }
            setPrograma(res.data.programa)
            setProgramasLoaded(true)

        })

    }, [])



    if(!_user) { 
        return null
    }



    /**
     * Alteração de foto ======================================================================
     **/ 
    const clickAlterarImagem = () => inputFile.current.click()

    const changeInputFile = (event) => {

        const file = event.target.files[0]

        if(!IsValid.fileExtension(file.name, ['jpeg', 'jpg', 'png', 'gif', 'bmp', 'tiff', 'tif'])) { 
            alert('É preciso inserir um arquivo em formato de imagem.')
            inputFile.current.value = ''
        }

        setShowPreloaderFoto(true);

        const formData = new FormData(); 
        formData.append( 
            "imagem", 
            file, 
            file.name 
        )

        api.post(`_user/?key=${User.getAttr('token')}`, formData)
        .then(res => {
            if(res.data.status === 0) { 
                alert('Erro ao salvar a foto.')
                window.location.reload()
            } else { 

                // Exibir img da página
                setImageUrl(api.getImageUrl(res.data.url))
                
                // Alterar no user e atualizar MenuUsuario
                const user = User.getUser()
                user.url_imagem = res.data.url
                User.setUser(user)
                setMenuUsuarioTimestamp(new Date().getTime())

                // Ocultar preloader
                setShowPreloaderFoto(false)
            }
        })
    }



    /**
     * Alteração de senha ======================================================================
     **/ 
    const changeSenha = (event) => {
        
        event.preventDefault()

        /**
         * Validar
         **/ 
        if(inputSenha.current.value == '') { 
            alert('Preencha uma senha.');
            return
        }
        if(inputSenha.current.value.length < 5 || inputSenha.current.value.length > 30) { 
            alert('A senha deve ter entre 5 e 30 caracteres.');
            return
        } 
        if(inputSenha.current.value != inputSenhaConfirmacao.current.value) {
            alert('A confirmação de senha não corresponde à senha.')
            return
        }

        setShowPreloaderSenha(true)

        /**
         * Enviar
         **/ 
        const formData = new FormData(); 
        formData.append("password", inputSenha.current.value)
        api.post(`password/?key=${User.getAttr('token')}`, formData)
        .then(res => {

            if(res.data.status === 0) { 
                alert('Erro ao salvar a senha.')
                return
            }

            // Ocultar preloader
            setShowPreloaderSenha(false)
            setShowInputSenha(false)
            setShowSenhaAlterada(true)
            setTimeout(() => setShowSenhaAlterada(false), 4000)
        })
    }





    let styleFoto = {}
    if(imageUrl) { 
        styleFoto.backgroundImage = `url(${imageUrl})`
    }
    if(showPreloaderFoto) { 
        styleFoto.opacity = '0.3'
    }






    





    return (
    <>

        { User.getAttr('need_address') && 
            <style>
                {`
                    .obrigatorio {
                        background-color: #F3FF8E;
                        color: #666;
                    }
                `}
            </style>
        }


        {/* Input de foto */}
        <input ref={inputFile} name="inputFile" id="inputFile" type="file" onChange={changeInputFile} />






        <div className="areaUsuario">
            <div className="meusDadosFoto d-flex align-items-end justify-content-end" style={styleFoto}>
                {showPreloaderFoto && 
                    <FontAwesomeIcon icon={faCircleNotch} className="fa-spin" /> 
                }
                {!showPreloaderFoto && 
                    <button className="btn btn-light meusDadosBotao" onClick={clickAlterarImagem} style={{opacity:'0.9'}} >
                        trocar foto
                    </button>
                }
            </div>
            <div className="meusDadosTextos">

                <div className="meusDadosNome">
                    {User.getAttr('need_address') ? 'Complete seu cadastro' : nome}
                </div>
                {User.getAttr('need_address') && 
                    <strong className="text-warning ml-3" style={{fontSize: '1.3rem'}} >
                        Os campos em amarelo são de preenchimento obrigatório
                    </strong>
                }
                <div className="meusDadosInfos">
                    login: {_user.email}
                </div>
                <div className="meusDadosInfos d-flex align-items-start">

                    <span>
                        senha: 
                    </span>

                    { !showInputSenha && !showPreloaderSenha && !showSenhaAlterada && 
                        <button className="btn btn-info meusDadosBotao m-2" onClick={() => setShowInputSenha(true) }>
                            alterar senha
                        </button>
                    }

                    { showInputSenha && !showPreloaderSenha && 
                        <>
                            <form onSubmit={changeSenha}>
                                <input ref={inputSenha} type="password" className="CadastroPreto CadastroCampoSenha ml-2" placeholder="digite a senha" />
                                <br />
                                <input ref={inputSenhaConfirmacao} type="password" className="CadastroPreto CadastroCampoSenha ml-2" placeholder="confirme a senha" />
                                <button className="btn btn-info btn-sm ml-2" onClick={() => setShowInputSenha(true) } type="submit">
                                    salvar
                                </button>
                            </form>
                        </>
                    }

                    { showPreloaderSenha && 
                        <FontAwesomeIcon icon={faCircleNotch} className="fa-spin m-2" /> 
                    }

                    { showSenhaAlterada && 
                        <span className="ml-2" style={{ color: "#3FCBFF" }}>
                            alteração feita!
                        </span>
                    }
                </div>
            </div>
        </div>




        <FormularioCadastro _user={_user} userToken={User.getAttr('token')} page="MeusDados" setNome={setNome} />

        



















        <div className="titulosDetalhamentoTemporada pt-4">
            <div className="titulosCategorias pb-3">
                <span className="divisorTitulos"></span>
                Meus estudos
            </div>
        </div>



        {!programasLoaded && 
            <div className="ml-5 text-info" style={{fontSize: '25px'}}>
                <FontAwesomeIcon icon={faCircleNotch} className="mt-1 mr-3 fa-spin" /> 
                carregando...
            </div>
        }

        {programa.length == 0 && programasLoaded && 
            <div className="ml-5">
                Nenhum curso em andamento
            </div>
        }

        {programa.map((prog, key) => {
            
            const label_episodio = ProgramaHelper.getEpisodioAtualUser(prog)
            
            return(
                <div key={key} className="DadosListagemArea mt-4">
                    <div className="AbasEpisodioBoxGeral" style={{marginBottom: '0px'}}>
                        <Link to={`${getRootPath()}detalhes/${prog.site_slug}/`} className="AbasEpisodioAreaAbrir" >
                            <div className="AbasEpisodioAreaVideo">
                                <img className="img-fluid AbasEpisodioVideo" src={api.getImageUrl(prog.url_imagem)} />
                            </div>
                            <div className="AbasEpisodioAreaTextos p-2">
                                <h3 className="abasEpisodioTitulo">
                                    {prog.nome}
                                </h3>
                                <div>
                                    {label_episodio}
                                </div>
                                <div style={{marginTop:'45px'}}>
                                    <BarraCarregamento programa={prog} />
                                </div>
                            </div>            
                        </Link>  
                    </div>    
                </div>
            )
        })}




    </>
    )


}