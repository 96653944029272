import React, { useState }   from 'react'
import { useParams, 
         useLocation, 
         useNavigate, 
         useOutletContext }  from "react-router-dom"
import Cookies               from 'universal-cookie'

import api                   from "../services/api"

import usuario               from '../imagens/usuario.jpg'
import down                  from '../imagens/down.svg'

import ListagemDocentes      from '../components/ListagemDocentes';
import BarraCarregamento     from '../components/BarraCarregamento';
import ModalPlayer           from '../components/Detalhes/ModalPlayer'
import CabecalhoAbaTemporada from '../components/Detalhes/CabecalhoAbaTemporada'
import ListagemMateriais     from '../components/ListagemMateriais'
import ListagemBibliografia  from '../components/ListagemBibliografia'
import Estrelas              from '../components/Estrelas'

import User                  from '../utils/User.js'
import ProgramaHelper        from '../utils/ProgramaHelper.js';
import Checkout              from '../utils/Checkout.js';

import { FontAwesomeIcon }   from '@fortawesome/react-fontawesome'
import { faCircleNotch }     from '@fortawesome/free-solid-svg-icons'

import './Detalhes.css';


export default function Detalhes() {

    const params   = useParams()
    const location = useLocation()
    const navigate = useNavigate()

    const [showTab,    setShowTab   ] = useState('episodios');
    const [programaLoaded,    setProgramaLoaded   ] = useState(false);


    const [modalPlayerShow,       setModalPlayerShow        ] = useState(false);
    const [modalPlayerKeyAtual,   setModalPlayerKeyAtual    ] = useState(null);
    const [modalPlayerFromStart,  setModalPlayerFromStart   ] = useState(null);
    const [detalhesUpdateVar,     setDetalhesUpdateVar      ] = useState(false);

    const [titleTemporada,         setTitleTemporada        ] = useState(null);
    const [keyAtual,               setKeyAtual              ]       = useState(null);
    const [docente,                setDocente               ] = useState(null);
    const [depoimento,             setDepoimento            ] = useState(null);

    const [labelAssistir,          setLabelAssistir         ] = useState(null);
    const [labelEpisodio,          setLabelEpisodio         ] = useState(null);
    const [subtitle,               setSubtitle              ] = useState(null);

    const [onDemandNotPurchased,   setOnDemandNotPurchased  ] = useState(false);
    const [canalNotPurchased,      setCanalNotPurchased     ] = useState(false);
    const [assinaturaNotPurchased, setAssinaturaNotPurchased] = useState(false);
    const [paymentPending,         setPaymentPending        ] = useState(false);

    const [programa,               setPrograma              ] = useState(null);
    const [isSubscriber,           setIsSubscriber          ] = useState(false);

    const openPlayer = (keyModulo, keySubmodulo, fromStart) => {
        setModalPlayerFromStart(fromStart ? true : false)
        setModalPlayerKeyAtual({modulo:keyModulo, submodulo:keySubmodulo})
        setModalPlayerShow(true)
    }


    const toggleModalAssinante  = useOutletContext().toggleModalAssinante
    const toggleModalCadastro   = useOutletContext().toggleModalCadastro
    const setModalAssinanteFree = useOutletContext().setModalAssinanteFree



    const goPurchase = (confirmMessage, params) => {
        if(!confirm(confirmMessage)) { 
            return
        }
        if(!params) { 
            params = {}
        }
        Checkout.go(params, toggleModalCadastro)
    }


    const clickAssistir = (programa, keyModulo, keySubmodulo, forceOnDemandNotPurchased, forceCanalNotPurchased, forceAssinaturaNotPurchased, forcePaymentPending) => {

        const _onDemandNotPurchased   = forceOnDemandNotPurchased   || onDemandNotPurchased
        const _canalNotPurchased      = forceCanalNotPurchased      || canalNotPurchased
        let   _assinaturaNotPurchased = forceAssinaturaNotPurchased || assinaturaNotPurchased
        const _paymentPending         = forcePaymentPending         || paymentPending

/**
 * Hack para deixar free somente 1a temporada de Fundamentos do Planejamento de Obras
 **/ 
let free = false
if(programa.id == 143) {
    free = keyModulo == 0;
    if(!isSubscriber && !free) { 
        _assinaturaNotPurchased = true
    }
}

        const submodulo = programa.modulo[keyModulo].submodulo[keySubmodulo]

        if(!User.getAttr('token') && free) { 

            /**
             * É free, mas precisa logar
             **/ 
            setModalAssinanteFree(true)
            toggleModalAssinante(true)

        } else if(_onDemandNotPurchased) { 

            /**
             * Comprar on-demand
             **/ 
            goPurchase('Este é um conteúdo on-demand.\nDeseja adquirir agora?', { site_slug: programa.site_slug })

        } else if(_canalNotPurchased) { 

            /**
             * Assinar canal
             **/ 
            goPurchase(`Este é um conteúdo do canal ${programa.associacao.nome}.\nDeseja assinar o canal?`, { associacao: programa.associacao.id })

        } else if(_assinaturaNotPurchased) { 

            /**
             * Assinar IDDplus
             **/ 
            goPurchase('Este conteúdo é exclusivo para assinantes. \nDeseja assinar agora?')

        
        } else if(_paymentPending && !free) {

            /**
             * Está inadimplente
             **/ 
            if(confirm('Houve algum problema ao processar seus pagamentos.\nÉ preciso regularizá-los para ter acesso a este conteúdo.\nDeseja visualizar a pendência?')) { 
                navigate('/meus-pagamentos/')
            }
            return;

        } else if(!User.getAttr('token')) { 

            /**
             * Está deslogado
             **/ 
            toggleModalAssinante(true)

        } else if(!submodulo.video) { 

            /**
             * Abrir quiz
             **/ 
            navigate(`/quiz/${programa.site_slug}/${submodulo.id}`)

        } else { 

            /**
             * Abrir vídeo
             **/ 
            const urlName = submodulo.video.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/ /g, '-')
            const url = `/detalhes/${programa.site_slug}/?T${keyModulo+1}E${keySubmodulo+1}-${urlName}`
            window.history.replaceState(null, "", url)

            // window.dataLayer.push({
            //     event: 'pageview',
            //     url
            // });

            openPlayer(keyModulo, keySubmodulo)
        }
    }


    const setTemporadaAtual = (temporada) => {
        setKeyAtual({modulo: temporada-1, submodulo:0});
    }



    /**
     * Buscar dados dinâmicos ==========================================================================================
     **/ 
    React.useEffect(() => {

        let url = `programa/${params.site_slug}/?count_data=1&verificar_assinatura=1`
        if(User.getAttr('token')) { 
            url += `&key=${User.getAttr('token')}`
        }

        setProgramaLoaded(false)

        api
        .get(url)
        .then(res => {

            if(!res.data) { 
                console.log('Erro ao buscar programa pela API');
                return null
            }
            if(res.data.status === 0) { 
                console.log('Erro ao buscar programa pela API');
                return null
            }

            if(res.data.invalid_token) { 
                User.logout(true);
                window.location.reload();
            }


            const programa = res.data.programa
            setPrograma(programa);
            setIsSubscriber(res.data.is_subscriber);

            if(programa.preco.length) { 
                programa.preco = programa.preco[0]
            } else { 
                programa.preco = null
            }

            /**
            * Textos dos botões e outros textos
            **/ 
            let temSubmodulo = false
            if(programa.programaX_user) { 
                if(programa.programaX_user.submodulo) { 
                    temSubmodulo = true
                }
            }

            /**
             * Definir algumas vars
             **/ 


            // Encontrar keys de modulo e submodulo atual
            let _keyAtual = { modulo: 0, submodulo: 0}
            let iniciouCurso = false
            if(temSubmodulo) { 
                loopMestre:
                for(let m=0; m<programa.modulo.length; m++) {
                    for(let s=0; s<programa.modulo[m].submodulo.length; s++) {
                        if(programa.modulo[m].submodulo[s].id == programa.programaX_user.submodulo) { 
                            _keyAtual = {modulo: m, submodulo: s}
                            iniciouCurso = true
                            break loopMestre;
                        }
                    }
                }
            }
            setKeyAtual(_keyAtual)

            /**
            * Quiz ou vídeo
            **/ 
            const _submoduloAtual = programa.modulo[_keyAtual.modulo].submodulo[_keyAtual.submodulo]
            const tipo = (typeof(_submoduloAtual.questionario) != 'undefined' ? 'quiz' : 'video')

            /**
            * Continuar assistindo / Assistir agora
            **/ 
            let onDemandNotPurchased   = false
            let canalNotPurchased      = false
            let assinaturaNotPurchased = false
            let paymentPending         = false

            if(!programa.allowed) { 
                if(programa.preco) {
                    onDemandNotPurchased = true 
                } else if(programa.payment_pending) {
                    paymentPending = true
                } else if(programa.associacao) {
                    canalNotPurchased = true
                } else { 
                    assinaturaNotPurchased = true
                }
            }
            setOnDemandNotPurchased(onDemandNotPurchased)
            setCanalNotPurchased(canalNotPurchased)
            setAssinaturaNotPurchased(assinaturaNotPurchased)
            setPaymentPending(paymentPending)


            /**
             * subtitle embaixo do nome da série
             **/ 
            if(programa.availability == 'free') {
                setSubtitle(<strong style={{fontStyle:"italic"}}>Temporada 1 - free, enquanto durar a promoção</strong>)
            } else if(onDemandNotPurchased) { 
                setSubtitle('Conteúdo on-demand')
            } else if(assinaturaNotPurchased) { 
                setSubtitle('Conteúdo exclusivo para assinantes')
            } else if(canalNotPurchased) { 
                setSubtitle('Conteúdo exclusivo para assinantes do canal '+programa.associacao.nome)
            }

            /**
             * labelAssistir
             **/ 
            if(onDemandNotPurchased) { 
                setLabelAssistir(`Comprar agora (R$ ${programa.preco.valor_formatado})`)
            } else if(canalNotPurchased) { 
                setLabelAssistir(`Assinar este canal (R$ ${programa.associacao.preco_assinatura_formatado})`)
            } else if(iniciouCurso) { 
                if(tipo == 'quiz') {
                    setLabelAssistir(`Prosseguir para o quiz`)
                } else if(tipo == 'video') {
                    setLabelAssistir(`Continuar assistindo`) 
                }
            } else { 
                if(tipo == 'quiz') {
                    setLabelAssistir(`Resolva o quiz`)
                } else if(tipo == 'video') {
                    setLabelAssistir(`Assistir agora!`) 
                }
            }

            /**
             * episódio [3] da temporada [2]
             **/ 
            if(programa.quantidade_submodulos > 1 && !onDemandNotPurchased) { 
                let _labelEpisodio
                if(tipo == 'video') {
                    _labelEpisodio = `episódio ${_keyAtual.submodulo+1}`
                    if(programa.quantidade_modulos > 1) { 
                        _labelEpisodio += ` da temporada ${_keyAtual.modulo+1}`
                    }
                } else if(programa.quantidade_modulos > 1) { 
                    _labelEpisodio = `Temporada ${_keyAtual.modulo+1} - ${_keyAtual.submodulo+1}`
                }
                if(_labelEpisodio) { 
                    setLabelEpisodio(_labelEpisodio)
                }
            }


            /**
            * Preloader do programa
            **/ 
            setProgramaLoaded(true)

            /**
            * Auto-play ao carregar
            **/ 
            let autoPlay = null;
            const cookies = new Cookies();

            if(location.state) { 
                if(location.state.autoPlay) { 
                    autoPlay = location.state.autoPlay;
                }
            } else if(cookies.get('autoPlay', {'path':'/'})) {
                autoPlay = cookies.get('autoPlay', {'path':'/'})
                cookies.remove('autoPlay', { path: '/' })
                if(autoPlay == '0') { 
                    autoPlay = null
                }
            }
            if(autoPlay) { 
                let keyModulo, keySubmodulo
                if(autoPlay == 'continue') { 
                    keyModulo    = _keyAtual.modulo
                    keySubmodulo = _keyAtual.submodulo
                } else { 
                    loopModulos:
                    for(keyModulo=0; keyModulo<programa.modulo.length; keyModulo++) {
                        for(keySubmodulo=0; keySubmodulo<programa.modulo[keyModulo].submodulo.length; keySubmodulo++) {
                            if(programa.modulo[keyModulo].submodulo[keySubmodulo].id == autoPlay) { 
                                break loopModulos
                            }
                        }
                    }
                }
                if(typeof(keyModulo) != 'undefined') { 
                    clickAssistir(programa, keyModulo, keySubmodulo, onDemandNotPurchased, canalNotPurchased, assinaturaNotPurchased, paymentPending)
                }
                // window.history.replaceState({}, document.title)
            }



        })
    }, [params.site_slug, detalhesUpdateVar])


    /**
    * Carregar conteúdo das abas ============================================================
    **/ 
    React.useEffect(() => {

        /**
        * Corpo docente
        **/ 
        if(showTab == 'corpo_docente') { 
            if(docente === null) { 
                let url = `docente/${params.site_slug}`
                api
                .get(url)
                .then(res => {
                    if(res.data.status === 0) { 
                        return null;
                    }
                    setDocente(res.data.docente);
                })
            }
        }

        /**
        * Depoimentos
        **/ 
        if(showTab == 'depoimentos') { 
            if(depoimento === null) { 
                let url = `depoimento/${params.site_slug}`
                api
                .get(url)
                .then(res => {
                    if(res.data.status === 0) { 
                        return null;
                    }
                    setDepoimento(res.data.depoimento);
                })
            }
        }

    }, [showTab])



    React.useEffect(() => {

        if(!keyAtual) {
            return;
        }

        let titleTemporada = `Temporada ${keyAtual.modulo+1} `
        if(programa.modulo[keyAtual.modulo].nome) { 
            titleTemporada += ` - ${programa.modulo[keyAtual.modulo].nome}`;
        }
        setTitleTemporada(titleTemporada)
    }, [keyAtual])



    /**
     * Continuar só se tiver programa e keyAtual
     **/ 
    if(!programa) {
        return null
    }
    if(!keyAtual) {
        return null
    }

    /**
    * Temporadas / episódios
    **/ 
    const qtdEpisodiosTemporadas = ProgramaHelper.getDuracao(programa)


    /**
    * Função que atualiza dados dinâmicos
    **/ 
    const detalhesUpdate = () => setDetalhesUpdateVar(!detalhesUpdateVar)





    const setModalPlayerShowAndSetSubmodulo = (show) => {
        setModalPlayerShow(false)
        if(!show) { 
            setModalPlayerKeyAtual(null)
        }
    }






    

    let classNameLinkEpisodios = 'detalhamentoAbasTemporadaItem'
    if(programa.qtd_docentes == 0 && programa.qtd_materiais == 0 && programa.qtd_depoimentos == 0) { 
        classNameLinkEpisodios += ' abaSemLink';
    }

    const rowsMateriais = []
    for(let m=1; m<=programa.modulo.length; m++) {
        rowsMateriais.push(
            <>
            { keyAtual.modulo == m && 
            <ListagemMateriais slug={programa.site_slug} temporada={m+1} qtdTemporadas={programa.modulo.length} carousel={false} key={m} />
        }
        </>
        )
    }


    let bannerBgStyle;
    if(programa.carousel) { 
        bannerBgStyle = {
            'backgroundImage': `url(${api.getImageUrl(programa.carousel.url_imagem, 1920, 1080)})`
        }
    }


    let textoDisponibilidade = ''
    if(onDemandNotPurchased) { 
        if(programa.prazo_disponibilidade_apos_compra) { 
            textoDisponibilidade += `Após adquirir a série, você terá ${programa.prazo_disponibilidade_apos_compra} dias para assistir. `
        }
        if(textoDisponibilidade != '') { 
            textoDisponibilidade += '\n'
        }
        if(programa.prazo_disponibilidade_apos_inicio) { 
            textoDisponibilidade += `Após começar a assistir a série, você terá ${programa.prazo_disponibilidade_apos_inicio} dias para concluir. `
        }
    }




    return (


    <>



        <div className="DetalhamentoBox margemMenu" style={bannerBgStyle}>



            <div className="DetalhamentoBoxTextos" style={{background: 'linear-gradient(90deg, rgba(0, 0, 0, 0.85) 6%, rgba(0, 0, 0, 0) 100%)'}}>
                <div className="detalhamentoBoxEstrelas">

                    <Estrelas avaliacao={programa.avaliacao} estrelaClassName="DetalhamentoEstrela" />

                    <div className="listagemAnoVideo">
                        {qtdEpisodiosTemporadas}
                    </div>
                </div>
                <span className="DetalhamentoBoxSubtitulo">
                    {programa.nome}
                </span>
                <p className="detalhamentoBoxInfo">
                    {subtitle}
                </p>
                <div className="bannerSeparador">
                </div>


                <p className="DetalhamentoBoxDescricao">
                    {programa.site_sobre ? programa.site_sobre.nl2br() : ''}
                </p>


                {!keyAtual &&
                  <div className="btn transicaoBotoes w-100">
                    <FontAwesomeIcon icon={faCircleNotch} className="mt-1 mr-3 fa-spin" /> 
                    carregando...
                  </div>
                }
                {keyAtual &&
                    <>
                        <button className="btn transicaoBotoes w-100" data-toggle="modal" data-target="#exampleModal" onClick={() => clickAssistir(programa, keyAtual.modulo, keyAtual.submodulo) }>
                            <span>
                                {labelAssistir}
                            </span>
                            {!onDemandNotPurchased && !canalNotPurchased && !programa.modulo[keyAtual.modulo].submodulo[keyAtual.submodulo].questionario && labelEpisodio && 
                                <span className="DetalhamentoBoxEpisodio ml-2">
                                    [ {labelEpisodio} ]
                                </span>
                            }
                        </button>

                        {textoDisponibilidade && 
                            <p className="small pt-2" style={{whiteSpace: 'pre-wrap'}}>
                                { textoDisponibilidade }
                            </p>
                        }
                    </>
                }


            </div>

        </div>


        <div className="detalhamentoDown">
            <a href="#episodios">  
                <img className="" src={down} width="50px" />
            </a>
        </div>






        
        <div className="detalhamentoAbasTemporada">
            <a className={classNameLinkEpisodios} onClick={() => { setShowTab('episodios') }}>
                Episódios
                <div className="detalhamentoAbasTemporadaBase">
                </div>
            </a>
            { programa.qtd_docentes > 0 &&
                <a id="episodios" className="detalhamentoAbasTemporadaItem" onClick={() => { setShowTab('corpo_docente') }} >
                    Corpo Docente
                    <div className="detalhamentoAbasTemporadaBase"></div>
                </a>
            }
            { programa.qtd_materiais > 0 && 
                <a className="detalhamentoAbasTemporadaItem"onClick={() => { setShowTab('material') }} >
                    E-books
                    <div className="detalhamentoAbasTemporadaBase"></div>
                </a>
            }
            { programa.qtd_bibliografias > 0 &&
                <a className="detalhamentoAbasTemporadaItem" onClick={() => { setShowTab('bibliografia') }} >
                    Bibliografia
                    <div className="detalhamentoAbasTemporadaBase"></div>
                </a>
            }
            { programa.qtd_depoimentos > 0 &&
                <a className="detalhamentoAbasTemporadaItem" onClick={() => { setShowTab('depoimentos') }} >
                    Depoimentos
                    <div className="detalhamentoAbasTemporadaBase"></div>
                </a>
            }
        </div>





        {showTab == 'episodios' && 

            <>

 
            { !programaLoaded && 
              <div className="areaListagemDetalhamento">
                <FontAwesomeIcon icon={faCircleNotch} className="m-5 fa-spin" size="5x" />
                <br /><br /><br /><br /><br /><br /><br /><br /><br />
              </div>
            }



            { programaLoaded && 

                <>

                <CabecalhoAbaTemporada title={titleTemporada} qtdTemporadas={programa.modulo.length} setTemporadaAtual={setTemporadaAtual} temporadaInicialSelect={keyAtual['modulo']+1} />


                { programa.modulo.map((modulo, m) => {
                    return (
                        <div  key={m}>
                            { keyAtual.modulo == m && 
                                <div id="AbaListagemVideos" className="tab-content tab-pane AbaListagemArea">

                                    {modulo.submodulo.map((submodulo, s) => {

                                        let thumbnail_url, 
                                        onClick, 
                                        label, 
                                        duracao, 
                                        descricao

                                        if(submodulo.video) { 
                                            thumbnail_url = submodulo.video.thumbnail_url
                                            onClick       = () => clickAssistir(programa, m, s)
                                            label         = submodulo.video.nome
                                            let seconds = submodulo.video.duration;
                                            let hours   = 0;
                                            let minutes = 0;
                                            if(seconds > 60*60) { 
                                                hours = Math.floor(seconds / 60*60)
                                                seconds -= 60*60*hours
                                            }
                                            if(seconds > 60) {
                                                minutes = Math.floor(seconds / 60)
                                                seconds -= 60*minutes
                                            }
                                            if(hours > 0) { 
                                                duracao = `${hours}:${minutes}`
                                            } else if(minutes > 0) { 
                                                duracao = `${minutes} MIN`
                                            } else { 
                                                duracao = 'Menos de 1 min'
                                            }
                                            descricao     = (submodulo.descricao ? submodulo.descricao.nl2br() : '')
                                        } else { 
                                            thumbnail_url = api.getImageUrl(submodulo.url_imagem)
                                            onClick       = () => clickAssistir(programa, m, s)
                                            label         = 'Quiz'
                                            if(submodulo.submoduloX_user) { 
                                                duracao = `acertos: ${submodulo.submoduloX_user.questionarios_acertos} / ${submodulo.submoduloX_user.questionarios_questoes}`;
                                            } else { 
                                                duracao = Number(submodulo.questionario.quantidade_questoes);
                                                if(duracao > 1) { 
                                                    duracao += ' questões'
                                                } else { 
                                                    duracao += ' questão'
                                                }
                                            }
                                            descricao = 'Teste seus conhecimentos adquiridos até agora.'
                                        }
                                        return (

                                            <div key={s} className="AbasEpisodioBoxGeral">
                                                <button onClick={onClick} className="AbasEpisodioAreaAbrir textButton">
                                                    <div className="AbasEpisodioAreaVideo">
                                                        <img className="img-fluid AbasEpisodioVideo" src={thumbnail_url} />
                                                    </div>
                                                    <div className="AbasEpisodioAreaTextos">
                                                        <h3 className="abasEpisodioTitulo">
                                                            Episódio {s+1}: {label}
                                                        </h3>
                                                        <div className="AbasEpisodioAreaAberta">
                                                            {descricao}
                                                        </div>
                                                        <BarraCarregamento submodulo={submodulo} duracao={duracao} />
                                                    </div>                  
                                                </button>  
                                            </div>
                                        )
                                   })}
                                </div>
                            }
                        </div>
                    )
                })}

                </>
            }

            </>
        }
        



        {showTab == 'corpo_docente' && 
        
            <div id="AbaListagemSobreSerie" className="tab-content tab-pane AbaListagemSobreSerie">
                { !docente && 
                    <>
                        <FontAwesomeIcon icon={faCircleNotch} className="m-5 fa-spin" size="5x" /> 
                        <br /><br /><br /><br /><br /><br /><br /><br /><br />
                    </>
                }
                { docente && 
                    <div className="docenteAreaGeral">
                        <ListagemDocentes docente={docente} carousel={false} />
                    </div>
                }
            </div>

        }




        {showTab == 'material' && 

            <>

                { !programaLoaded && 
                  <div className="areaListagemDetalhamento">
                    <FontAwesomeIcon icon={faCircleNotch} className="m-5 fa-spin" size="5x" /> 
                    <br /><br /><br /><br /><br /><br /><br /><br /><br />
                  </div>
                }

                { programaLoaded && 

                  <>
                    <CabecalhoAbaTemporada title="E-books" qtdTemporadas={programa.modulo.length} setTemporadaAtual={setTemporadaAtual} temporadaInicialSelect={keyAtual['modulo']+1} />

                    {rowsMateriais}
                  </>
                }
            </>

        }









        {showTab == 'bibliografia' && 

            <>

                <div id="AbaListagemAnexos" className="tab-pane AbaListagemSobreSerie">

                    { !programaLoaded && 
                      <div className="areaListagemDetalhamento">
                        <FontAwesomeIcon icon={faCircleNotch} className="m-5 fa-spin" size="5x" /> 
                        <br /><br /><br /><br /><br /><br /><br /><br /><br />
                      </div>
                    }


                    { programaLoaded && 


                        <div className="docenteAreaGeral">
                            <ListagemBibliografia slug={programa.site_slug} />
                        </div>
                    }

                </div>
            </>
        }

        





        {showTab == 'depoimentos' && 
            <div id="AbaListagemDepoimentos" className="tab-pane areaListagemDetalhamento">
                { !depoimento && 
                  <>
                    <FontAwesomeIcon icon={faCircleNotch} className="m-5 fa-spin" size="5x" /> 
                    <br /><br /><br /><br /><br /><br /><br /><br /><br />
                  </>
                }
                { depoimento && 
                    <div className="docenteAreaGeral">
                        { depoimento.map((dep, key) => {
                            const url_imagem = (dep.url_imagem ? api.getImageUrl(dep.url_imagem, 60, 60) : usuario);
                            return (

                                <div className="boxDepoimento" key={key} >
                                    <div className="boxDepoimentosAspa">
                                        <div className="boxDepoimentosAspa3">
                                        </div>
                                        <div className="boxDepoimentosAspa2">
                                        </div>
                                    </div>

                                    <div className="boxDepoimentoDescricao">
                                        {dep.texto ? dep.texto.nl2br() : ''}
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                        <div className="boxDepoimentoImagem" style={{backgroundImage: `url(${url_imagem})`}}>
                                        </div>
                                        <div className="boxDepoimentoTextos">
                                            <p className="boxDepoimentoTitulo">
                                                {dep.autor}
                                            </p>
                                            <p className="boxDepoimentoCargo">
                                                {dep.autor_descricao}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                
                            )
                          })
                        }
                    </div>
                }
            </div>
        }


        





        









        {programa && 

            <ModalPlayer 
                show               = {modalPlayerShow} 
                setModalPlayerShow = {setModalPlayerShowAndSetSubmodulo} 
                keyAtual           = {modalPlayerKeyAtual}
                fromStart          = {modalPlayerFromStart}
                programa           = {programa}
                openPlayer         = {openPlayer} 
                detalhesUpdate     = {detalhesUpdate}
            />

        }



    </>
  )
}


