import React         from 'react';
import { useOutletContext } from "react-router-dom";
import User          from "../utils/User";
import HomeLogado    from "./HomeLogado";
import HomeDeslogado from "./HomeDeslogado";
import HomeLogin    from "./HomeLogin";

export default function Home() {


    if(User.getAttr('token')) { 
        return <HomeLogado />
    } else { 
        
        const permitirAcessoSemLogar = useOutletContext().permitirAcessoSemLogar;

        if(permitirAcessoSemLogar) { 
            return <HomeDeslogado />
        } else { 
            return <HomeLogin />
        }
    }

}