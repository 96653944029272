import React, { useState } from 'react';
import FormLogin from '../components/FormLogin.jsx';

export default function Home() {

    const [showEsqueciSenha,   setShowEsqueciSenha  ] = useState(false)

    React.useEffect(() => {

        /**
         * Esconder menus (deixar só "Ajuda")
         **/ 
        setTimeout(() => {
            const menuItens = document.querySelectorAll("#navbarSupportedContent li:not(:last-child)")
            menuItens.forEach(function(el) {
                el.style.display = 'none'
            })
        }, 500)
        document.getElementsByClassName("areaUsuarioMenu1")[0].style.display = 'none'

        /**
         * "Ajuda" alinhado à direita
         **/ 
        document.getElementById("navbarSupportedContent").align = 'right'
        document.getElementById("navbarSupportedContent").setAttribute('style', 'display:block !important');
        document.getElementsByClassName("boxMenu1")[0].style.display = 'block';

        /**
         * Aumentar "Ajuda"
         **/ 
        document.querySelector("#navbarSupportedContent li:last-child a").style.fontSize = '20px'

        /**
         * Adicionar class="bodyCadastro"
         **/ 
        document.body.classList.add('bodyCadastro');
    }, []);

    const onLogin = () => {
        document.location = '/'
    }

    return (
        <>
            <div className="cadastroConteudoGeral">
                <div className="cadastroArea">
                    <h2>
                        { showEsqueciSenha && 
                            <>Esqueci minha senha</>
                        }
                        { !showEsqueciSenha && 
                            <>Login</>
                        }
                    </h2>
                    <div className="cadastroArea2">
                        <div className="cadastroContorno" style={{maxWidth: '350px'}}>
                            <div className="cadastroBox2">
                                <FormLogin setShowEsqueciSenha={setShowEsqueciSenha} onLogin={onLogin} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}