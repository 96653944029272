import React                           from 'react';
import { useParams, Link }             from "react-router-dom";

import api                             from "../services/api";
import User                            from '../utils/User.js';
import { getRootPath , getUrlIdd }   from '../utils/Utils.js';

import { FontAwesomeIcon }             from '@fortawesome/react-fontawesome'
import { faCircleNotch, faCreditCard, faStar, faTrash } from '@fortawesome/free-solid-svg-icons';


export default function MeusCartoes() {

    // Não permitir deslogado ====================
    User.blockIfNonLogged()
    // ===========================================


    /**
    * Dados dinamicos
    **/ 
    const [youpayCard,     setYoupayCard     ] = React.useState(null);
    const [lastCardUpdate, setLastCardUpdate ] = React.useState(new Date());
    const [today,          setToday          ] = React.useState(null);

    const params = useParams();

    React.useEffect(() => {

        /**
         * Buscar youpayCards
         **/ 
        api
        .get(`youpayCard/?key=${User.getAttr('token')}`)
        .then(res => {
            if(res.data.status === 0) { 
                console.log('Erro na API ao buscar youpayCards');
                return;
            }
            setYoupayCard(res.data.youpayCard)
            setToday(res.data.today)
        })

    }, [params.slug, lastCardUpdate]);









    /**
     * Alterar principal e excluir cartão
     **/ 
    const sendCardRequestToApi = (youpayCard, method, url, confirmMessage) => {
        if(!confirm(confirmMessage)) { 
            return
        }
        const params = new URLSearchParams();
        params.append('youpayCard', youpayCard)

        const apiMethod = api[method]       // equivale ao método api.post() ou api.delete()
        apiMethod(`${url}?key=${User.getAttr('token')}`, params)
        .then(res => {
            if(res.data.status === 0) { 
                alert('Houve algum erro no processamento.');
            }
            setYoupayCard(null)
            setLastCardUpdate(new Date())
        })
    }


    const togglePrincipal = (youpayCard) => {
        sendCardRequestToApi(youpayCard, 'post', 'youpayCard/principal/', 'Deseja marcar este como o cartão preferencial para pagamentos?')
    }
    const deleteCard = (youpayCard) => {
        sendCardRequestToApi(youpayCard, 'post', 'youpayCard/delete/', 'Deseja mesmo excluir este cartão?')
    }



    return (


    <>

        <div className="areaDados" style={{marginTop:'120px'}}>
            <div className="dadosConfirmoArea">

                <h4>
                    <FontAwesomeIcon icon={faCreditCard} className="mr-2" /> 
                    Meus cartões
                </h4>
                <br />

                <div className="cadastroContorno">
                    <div className="cadastroBox2 p-4">


                        {!youpayCard && 
                            <span style={{fontSize:'20px'}}>
                                <FontAwesomeIcon icon={faCircleNotch} className="fa-spin mr-2 mt-3" /> 
                                Carregando...
                            </span>
                        }

                        {youpayCard && youpayCard.length > 0 && 
                            <div className="row">

                                <>
                                    {youpayCard.map((item, key) => {

                                        const imgSource        = `${getUrlIdd()}_modulos/youpay/images/${item.brand.toLowerCase()}.svg`
                                        const [month, yearEnd] = item.expiration.split('/')
                                        const year             = '20'+yearEnd
                                        const dateObj          = new Date(today);
                                        const expired          = parseInt(year) < dateObj.getFullYear() || (parseInt(year) == dateObj.getFullYear() && parseInt(month) < (dateObj.getMonth()+1))
                                        const onClickPrincipal = () => togglePrincipal(item.id)
                                        const onClickDelete    = () => deleteCard(item.id)

                                        let color, type, principalButton, deleteButton, boxCssClass
                                        if(expired) { 
                                            color           = 'danger'
                                            type            = 'Cartão expirado'
                                            principalButton = false
                                            deleteButton    = true
                                            boxCssClass     = 'meus-cartoes-expired'
                                        } else if(item.principal === '1') { 
                                            color           = 'success'
                                            type            = 'Cartão principal'
                                            principalButton = false
                                            deleteButton    = false
                                            boxCssClass     = ''
                                        } else { 
                                            color           = 'secondary'
                                            type            = 'Cartão alternativo'
                                            principalButton = true
                                            deleteButton    = true
                                            boxCssClass     = ''
                                        }
                                        const headerCss = `bg-${color} text-white text-center d-block w-100`
                                        const boxCss = `${boxCssClass} col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 d-flex align-items-stretch`

                                        return (
                                            <div key={key} className={boxCss}>
                                                <div className="bg-white rounded text-secondary ml-3 w-100 d-flex flex-column">
                                                    <strong className={headerCss} >
                                                        {type}
                                                    </strong>
                                                    <div className="d-flex flex-column" style={{flexGrow: 1}}>
                                                        <div className="mt-3 mx-3">
                                                            <img src={imgSource} alt={item.brand} style={{maxWidth: '100%'}} className="mb-2" />
                                                            **** **** **** {item.number}
                                                            <br />
                                                            {item.cardholder_name && 
                                                                <>{item.cardholder_name}<br /></>
                                                            }
                                                            Válido até {item.expiration}
                                                        </div>
                                                        <div className="d-flex justify-content-between mt-2 mb-3 mx-3 align-items-end" style={{flexGrow:1}}>
                                                            { principalButton && 
                                                                <button onClick={onClickPrincipal} className="btn btn-info btn-xs">
                                                                    <FontAwesomeIcon icon={faStar} className="mr-1" /> 
                                                                    tornar principal
                                                                </button>
                                                            }
                                                            { !principalButton && 
                                                                <span>
                                                                </span>
                                                            }
                                                            { deleteButton && 
                                                                <button className="btn btn-light border text-secondary btn-xs" onClick={onClickDelete}>
                                                                    <FontAwesomeIcon icon={faTrash} /> 
                                                                </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </>

                                <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 d-flex">
                                    <Link to={`${getRootPath()}meus-cartoes/adicionar`} id="adicionar-cartao" className="bg-light rounded text-secondary ml-3 overflow-hidden w-100 textButton">
                                        <br />
                                        <div className="p-3">
                                            <div style={{maxWidth: '100%', aspectRatio: '16/10'}} className="p-2 mb-2 rounded bg-secondary text-white d-flex justify-content-center align-items-center text-center">
                                                <h3>
                                                    Adicionar cartão
                                                </h3>
                                            </div>
                                            **** **** **** **** 
                                            <br />
                                            Válido até ___ / ___
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>


    </>
  )
}


