import React                 from 'react'
import api                   from "./services/api"
import { useParams, Outlet } from "react-router-dom"
import Cookies               from 'universal-cookie'

import TagManager            from 'react-gtm-module'

import ModalAssinante        from './components/ModalAssinante'
import ModalCadastro         from './components/ModalCadastro'
import MenuUsuario           from './components/App/MenuUsuario'
import User                  from './utils/User.js'
import {getEnviroment, getRootPath} from './utils/Utils.js'

import iddplus_logomarca     from './imagens/iddplus_logomarca.svg'
import idd_plus_busca        from './imagens/idd_plus-busca.svg'
import icone_whatsapp        from './imagens/icone_whatsapp.svg'
import youtube_idd           from './imagens/youtube_idd.png'
import instagram_idd         from './imagens/instagram_idd.png'
import facebook_idd          from './imagens/facebook_idd.png'
import linked_in_idd         from './imagens/linked_in_idd.png'

import { FontAwesomeIcon }   from '@fortawesome/react-fontawesome'
import { library }           from '@fortawesome/fontawesome-svg-core'
import { faChevronDown }     from '@fortawesome/free-solid-svg-icons'
library.add(faChevronDown)

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

/**
 * Credenciais do Tag Manager
 **/ 
const tagManagerId = (getEnviroment() == 'production' ? 'GTM-54X85JK8' : 'GTM-5HQ2CRJG')

/**
 * Google Tag Manager
 **/
TagManager.initialize({
  gtmId: tagManagerId
})

function App() {


    /**
     * Se desativar, não permitirá acesso a nada sem login
     **/ 
    const permitirAcessoSemLogar = true


    /**
     * Se estiver deslogado, vai pro Home, que vai chamar HomeLogin (tela de login) ==================================================
     **/ 
    const pathname = window.location.pathname
    if(!permitirAcessoSemLogar && pathname != '/' && pathname.indexOf('/login/') !== 0 && !User.getAttr('token')) { 
        document.location = '/';
        return null
    }
    // ==============================================================================================================


    /**
     * Se estiver logado e falta endereço, vai pra tela de cadastro
     **/  
    if(pathname.indexOf('/meus-dados/') !== 0 && User.getAttr('token') && User.getAttr('need_address')) { 
        document.location = '/meus-dados/';
        return null
    }



    const params           = useParams();
    const searchQueryInput = React.useRef(null)

    const [ associacao,            setAssociacao           ] = React.useState([]);
    const [ modalAssinanteShow,    setModalAssinanteShow   ] = React.useState(false);
    const [ modalAssinanteOnLogin, setModalAssinateOnLogin ] = React.useState(null);
    const [ modalAssinanteFree,    setModalAssinanteFree   ] = React.useState(false);
    const [ modalCadastroShow,     setModalCadastroShow    ] = React.useState(false);
    const [ modalCadastroOptions,  setModalCadastroOptions ] = React.useState({});
    const [ searchShow,            setSearchShow           ] = React.useState(typeof(params.palavraChave) != 'undefined');
    const [ hasTransacao,          setHasTransacao         ] = React.useState(false);
    const [ menuUsuarioTimestamp,  setMenuUsuarioTimestamp ] = React.useState(null);




    /**
     * Gerar token com nova validade, se não foi extendido nas últimas 24 horas
     **/ 
    const cookies = new Cookies()

    if(User.getAttr('token') && cookies.get('token_extended') !== '1' && !App.isExtending) { 

        App.isExtending = true;

        api
        .post(`login/extend/?key=${User.getAttr('token')}`)
        .then((response) => {
            let error = false;
            if(!response.data) error = true;
            if(response.data.status === 0) error = true;

            if(error) { 
                console.log('ERRO AO TENTAR EXTENDER LOGIN');
                return;
            }
            if(!response.data.extended) { 

                /**
                 * O token anterior já tinha expirado
                 **/ 
                User.logout(true)
                window.location.reload()

            } else { 

                /**
                 * Extendeu expiração do token
                 **/ 
                User.setUser({
                    'name':         response.data.name,
                    'token':        response.data.token,
                    'id':           response.data.id,
                    'need_address': response.data.need_address,
                    'url_imagem':   response.data.url_imagem,
                });
                setMenuUsuarioTimestamp(new Date().getTime())

                /**
                 * Setar cookie "token_extended" com validade até amanhã
                 **/ 
                const tomorrow = new Date();
                tomorrow.setDate(tomorrow.getDate() + 1)
                const cookies = new Cookies();
                cookies.set('token_extended', '1', { path: '/', expires: tomorrow })
            }

            App.isExtending = false;

        })
        .catch((err) => {
            console.error("Erro ao buscar pela API: " + err);
        });
    }






    React.useEffect(() => {

        /**
         * RD Station só em produção
         **/ 
        if(getEnviroment() == 'development') { 
            setTimeout(() => document.querySelector('div[aria-label="cookieconsent"]').style.display = 'none', 2000);
        }

        /**
         * Exibir campo de busca preenchido
         **/ 
        setSearchShow(typeof(params.palavraChave) != 'undefined')

        /**
         * Setar timestamp da atualização de menu de usuário
         **/ 
        setMenuUsuarioTimestamp(new Date().getTime())

        /**
         * Mandar pageview pro Google Tag Manager
         **/ 
        // window.dataLayer.push({
        //     event: 'pageview'
        // });
        

        /**
         * Buscar canais
         **/ 
        api
        .get("associacao/")
        .then(response => {
            if (!response.data) {
                return null
            }
            if (response.data.status === 0) {
                return null
            }
            if(response.data.associacao === null) { 
                setAssociacao([])
            } else { 
                setAssociacao(response.data.associacao)
            }
        })
        .catch((err) => {
            console.error("Erro ao buscar pela API: " + err);
        });

        /**
         * Ver se tem pagamentos
         **/ 
        if(User.getAttr('token')) { 
            api
            .get(`transacao/has/?key=${User.getAttr('token')}`)
            .then(response => {
                if (!response.data) {
                    return null
                }
                if (response.data.status === 0) {
                    return null
                }
                setHasTransacao(response.data.hasTransacao)


            })
            .catch((err) => {
              console.error("Erro ao buscar pela API: " + err);
            });
        }

        /**
         * Iniciar verificação de acessos simultâneos
         **/ 
        if(User.getAttr('token')) { 
            simultaneousSessionBlock()
        }


    }, []);







    /**
     * Busca 
     **/ 
    const search = (event) => {
        if(event) { 
            event.preventDefault()
        }
        document.location.href = `/busca/${searchQueryInput.current.value}/`
    }
    const searchToggle = () => {
        setSearchShow(!searchShow)
        setTimeout(() => {
            document.getElementById('searchQueryInput').select();
        }, 0)
    }
    const clickLupa = () => {
        if(!searchQueryInput.current || searchQueryInput.current.value.trim() == '') { 
            searchToggle()
        } else { 
            search()
        }
    }





    /**
     * Menus desdobráveis
     **/ 
    const clickMenuMobile = () => {
        document.getElementById("navbarSupportedContent").classList.toggle("show");
    }
    const clickCanais = () => {
        document.getElementById('menu-canais-link').classList.toggle('show');
        document.getElementById('menu-canais').classList.toggle('show');
    }






    /**
     * Bloquear acessos simultâneos em mais de um lugar
     **/ 
    const simultaneous_session_block_delay = 15000;

    function simultaneousSessionBlock() {

        if(App.isExtending) { 
            setTimeout(simultaneousSessionBlock, simultaneous_session_block_delay)
        } else { 
            api
            .get(`_user/validate_access/&key=${User.getAttr('token')}`)
            .then(response => {
                if (!response.data) {
                    User.logout()
                    return
                }
                if(response.data == 1) { 
                    setTimeout(simultaneousSessionBlock, simultaneous_session_block_delay);
                } 
            })
            .catch((err) => {
                console.error("Erro ao buscar pela API: " + err);
            });
        }
    }






    /**
     * Função que abre modal de assinante
     **/ 
    const toggleModalAssinante = (show, onLogin) => {
        if(show && typeof(onLogin) == 'function') { 
            setModalAssinateOnLogin(() => onLogin);
        } else { 
            setModalAssinateOnLogin(null);
        }
        if(!show) { 
            setModalAssinanteFree(false)
        }
        setModalAssinanteShow(Boolean(show))
    }



    /**
     * Função que abre modal de cadastro
     **/ 
    const toggleModalCadastro = (show, options) => {
        if(!show || !options) { 
            options = {}
        }
        setModalCadastroOptions(options)
        setModalCadastroShow(Boolean(show))
    }





    return (



    <>

        {!User.getAttr('token') && 

            <>
                <ModalCadastro  show                  = {modalCadastroShow} 
                                toggleModalCadastro   = {toggleModalCadastro} 
                                toggleModalAssinante  = {toggleModalAssinante} 
                                setModalAssinanteFree = {setModalAssinanteFree} 
                                options               = {modalCadastroOptions} />

                <ModalAssinante show={modalAssinanteShow} 
                                toggleModalAssinante={toggleModalAssinante} 
                                onLogin={modalAssinanteOnLogin} 
                                toggleModalCadastro={toggleModalCadastro} 
                                free={modalAssinanteFree} />
            </>
        }


  
        <nav className="navbar navbar-expand-lg fixed-top">
            <a className="navbar-brand" href={getRootPath()}>
                <img src={iddplus_logomarca} height="40" />
            </a>

            <button className="navbar-toggler linkMobileIdd" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={clickMenuMobile}>
                <span className="navbar-toggler-icon" style={{color:'#FFF'}}>
                    <FontAwesomeIcon icon="chevron-down" className="mt-2" />
                </span>
            </button>

            <div className="collapse navbar-collapse collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto boxMenu1">
                    <li className="nav-item active">
                        <a className="nav-link itenMenu1 pl-3" href={`${getRootPath()}lancamentos/`}>
                            Lançamentos
                        </a>
                    </li>
                    
                    {associacao && associacao.length > 0 && 

                        <li id="menu-canais-link" className="nav-item dropdown pl-3">
                            <button className="nav-link itenMenu1 dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={clickCanais}>
                                Canais
                            </button>
                            <div id="menu-canais" className="dropdown-menu dropdown-menuCanais">
                                {associacao.map((assoc, keyAssoc) => {
                                    const url_canal  = `${getRootPath()}canal/${assoc.slug}`;
                                    return (
                                        <a href={url_canal} key={keyAssoc} className="">
                                            {assoc.nome}
                                        </a>
                                    )
                                })}
                            </div>
                        </li>
                    }
                    <li className="nav-item pl-3">
                        <a className="nav-link itenMenu1" href={`${getRootPath()}descubra/`} >
                            Descubra
                        </a>
                    </li>
                    <li className="nav-item pl-3">
                        <a className="nav-link itenMenu1" href={`${getRootPath()}ajuda/`} >
                            Ajuda
                        </a>
                    </li>
                </ul>
                <ul className="navbar-nav areaUsuarioMenu1">
                    <li className="buscaMenu1 d-flex align-items-center">
                        <form className="buscaTopo d-flex align-items-center" method="GET" onSubmit={search}>
                            { searchShow && 
                                <input ref={searchQueryInput} name="query" type="text" className="form-control buscaFormTopo mr-2 py-1" id="searchQueryInput" placeholder="Busca..." defaultValue={params.palavraChave} autoFocus={typeof(params.palavraChave) != 'undefined'} />
                            }
                            <a href="#" onClick={clickLupa}>
                                <img src={idd_plus_busca} width="30" />
                            </a>
                        </form>
                    </li>
                    <MenuUsuario toggleModalAssinante={toggleModalAssinante} toggleModalCadastro={toggleModalCadastro} hasTransacao={hasTransacao} updateTimestamp={menuUsuarioTimestamp} />
                </ul>
    
            </div>

        </nav>







        <div style={{minHeight: '700px'}}>
            <Outlet context={{associacao, setMenuUsuarioTimestamp, permitirAcessoSemLogar, toggleModalCadastro, toggleModalAssinante, setModalAssinanteFree}} />
        </div>




        { !User.getAttr('token') && 
            <div className="d-flex fixed-bottom" style={{justifyContent: 'flex-end', marginBottom: '-100px'}}>
                <a className="linkWhatsapp" href="https://wa.me/554188786045" target="_blank" rel="noreferrer">
                    <div style={{textAlign: 'center'}}>
                        precisa de<br /> 
                        ajuda?
                        <br />
                        <img src={icone_whatsapp} />
                    </div>
                </a>
            </div>
        }




        <div className="footerArea">
            <div className="footermenu">
                <a className="footerLink" href={`${getRootPath()}politica-de-privacidade/`}  rel="noreferrer">
                    Termos e Aviso de Privacidade
                </a>
                <a className="footerLink" href="https://www.idd.edu.br/central-de-atendimento/" target="_blank" rel="noreferrer">
                    Central de Atendimento
                </a>
                <a className="footerLink" href="https://www.idd.edu.br/" target="_blank" rel="noreferrer">
                    IDD Educação Avançada
                </a>
            </div>
            <div className="d-flex justify-content-center">
                <a className="ml-3 mr-3" href="https://youtube.com/@IddPlus" target="_blank" rel="noreferrer">
                    <img src={youtube_idd} />
                </a>
                <a className="ml-3 mr-3" href="https://www.instagram.com/idd.plus/" target="_blank" rel="noreferrer">
                    <img src={instagram_idd} />
                </a>
                <a className="ml-3 mr-3" href="https://www.facebook.com/iddplusoficial" target="_blank" rel="noreferrer">
                    <img src={facebook_idd} />
                </a>
                <a className="ml-3 mr-3" href="https://www.linkedin.com/company/100014101" target="_blank" rel="noreferrer">
                    <img src={linked_in_idd} />
                </a>
            </div>
            <div className="footerDireitos">
                <img className="footerLogo" src={iddplus_logomarca} />
                <div className="footerDireitosSeparador">
                </div>
                Grupo IDD - Todos os direitos reservados
            </div>
        </div>


    </>

  );
}

App.isExtending = false;

export default App;
