import React from 'react';

/**
 * Dado uma string de texto, delimita ela e coloca reticências no final.
 */
String.prototype.abbreviate = function(numero_max_caracteres) {
    if (this.length > numero_max_caracteres) {
        return this.substr(0, (numero_max_caracteres-3)) + "...";
    } else {
        return this;
    }
}


/**
 * Formatar preço
 **/ 
String.prototype.toPriceFormat = Number.prototype.toPriceFormat = function() {
    let num = String(this);
    let preco = num.split(",");
    if (!preco[1]) {
        preco = num.split(".");
    }
    if (!preco[1]) {
        preco[1] = "00";
    } 
    if (preco[1].length == 1) {
        preco[1] += "0";
    }
    if (preco[1].length > 2 ) {
        preco[1] = preco[1].substr(0, 2) + "." + preco[1].substr(2);
        preco[1] = Number(preco[1]);
        preco[1] = Math.round(preco[1]);
        preco[1] = String(preco[1]);
        if (preco[1].length == 1) {
            preco[1] = "0"+preco[1];
        }
    }
    if(preco[1] == "100") { 
        preco[0] = Number(preco[0]) + 1;
        preco[1] = "00";
    }
    if (preco[0].length > 3) {
        let precoCasaMilhar = preco[0].substr(0, (preco[0].length-3));
        let precoCasaMilhao
        if (precoCasaMilhar.length > 3) {
            precoCasaMilhao = precoCasaMilhar.substr(0, (precoCasaMilhar.length-3));
            precoCasaMilhar = precoCasaMilhar.substr(precoCasaMilhar.length-3);
            preco[0] = precoCasaMilhao + "." + precoCasaMilhar + "." + preco[0].substr(preco[0].length-3);
        } else {
            preco[0] = precoCasaMilhar + "." + preco[0].substr(preco[0].length-3);
        }
    }
    return preco[0] + "," + preco[1];
}

/**
 * Quebra de linha para textos do banco de dados
 **/ 
String.prototype.nl2br = function() {
    return this.split('\\n').map((item, idx) => {
      return (
        <React.Fragment key={idx}>
          {item}
          <br />
        </React.Fragment>
      )
    })
}

