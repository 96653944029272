import React, { useState, useRef } from 'react';
import Cookies  from 'universal-cookie'

import Button from 'react-bootstrap/Button';

import api     from "../services/api";
import User    from '../utils/User.js';
import IsValid from '../utils/IsValid.js';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';


export default function FormLogin(props) {

    // const [apiReturn, setApiReturn] = useState();
    const [username,           setUserName          ] = useState();
    const [password,           setPassword          ] = useState();
    const [showLoginPreloader, setShowLoginPreloader] = useState(false);
    const [showEsqueciSenha,   setShowEsqueciSenha  ] = useState(false);

    const refEmailEsqueciSenha   = useRef(null);


    React.useEffect(() => {
        props.setShowEsqueciSenha(showEsqueciSenha)
        if(document.getElementById('form-login-email')) { 
            setTimeout(document.getElementById('form-login-email').select, 500)
        }
    }, [showEsqueciSenha]);

    /**
     * Login pela API
     **/ 
    const handleSubmit = async e => {
        
        e.preventDefault();

        var params = new URLSearchParams();
        params.append('email', username);
        params.append('password', password);
        setShowLoginPreloader(true)

        api.post("login/", params,
        {
           withCredentials: true
        })
        .then((response) => {

            if (!response.data) return null;
            if (response.data.status === 0) return null;
            if(response.data.authorized) { 

                if(props.submoduloAposLogar) { 
                    const cookies = new Cookies();
                    cookies.set('autoPlay', props.submoduloAposLogar, { path: '/' });
                }

                User.setUser(response.data)
                if(typeof(props.onLogin) == 'function') { 
                    props.onLogin()
                } else { 
                    window.location.reload()
                }
            } else { 
                setShowLoginPreloader(false)
                setTimeout(() => alert('E-mail ou senha incorretos.'), 50)
                
            }
        })
        .catch((err) => {
            alert('Houve algum erro ao tentar logar.')
            console.error("Erro ao buscar pela API: " + err)
            setShowLoginPreloader(false)
        });

    }


    /**
     * Password reset
     **/ 
    const sendPasswordReset = async e => {
        
        e.preventDefault();

        /**
         * Validar
         **/ 
        const email = refEmailEsqueciSenha.current.value
        if(!IsValid.email(email)) { 
            alert('O e-mail digitado é inválido.')
            return
        }
        setShowLoginPreloader(true)

        /**
         * Verificar (e enviar)
         **/ 
        const params = new URLSearchParams();
        params.append('email', email);
        api.post("login/reset/", params, {
           withCredentials: true
        })
        .then((response) => {

            if(!response.data) return null;
            if(response.data.status === 0) return null;
            if(response.data.mail_sent) { 
                alert(`Foi enviado um link para ${email} para você atualizar sua senha.`)
                setShowEsqueciSenha(false)
            } else { 
                alert(`O e-mail ${email} não foi encontrado em nossos registros.\nVerifique qual o e-mail você utilizou para se cadastrar conosco.`)
            }
            setShowLoginPreloader(false)
        })
        .catch((err) => {
            alert('Houve algum erro ao verificar seu e-mail.')
            console.error("Erro ao buscar pela API: " + err);
            setShowLoginPreloader(false)
        });

    }


    return (
    <>


        { !showEsqueciSenha && 
            <>
                {showLoginPreloader && 
                    <div className="row align-items-center">
                        <div className="col-12 text-center">
                            <FontAwesomeIcon icon={faCircleNotch} className="m-5 fa-spin fa-5x" style={{opacity: 0.5}} /> 
                        </div>
                    </div>
                }
                {!showLoginPreloader && 
                    <>
                        <form onSubmit={handleSubmit}>
                            <div className="row">

                                <div className="col-12 pt-2">
                                    <label className="cadastroLabels">
                                        Login
                                    </label>
                                    <input type="email" className="CadastroCampoNome" id="form-login-email" aria-describedby="emailHelp" placeholder="Ex.: nome@email.com" onChange={e => setUserName(e.target.value)} />
                                </div>
                                <div className="col-12">
                                    <label className="cadastroLabels">
                                        Senha
                                    </label>
                                    <input type="password" className="CadastroCampoNome" id="exampleInputPassword1" placeholder="******" onChange={e => setPassword(e.target.value)} />
                                </div>

                                <div className="col-12">
                                    <label className="cadastroLabels">
                                    </label>
                                    <Button type="submit" className="btn CadastroCampoBotao2 mb-1 mt-0 btn-block">
                                        Entrar
                                    </Button>
                                </div>
                            </div>
                        </form>
                        <button className="textButton" onClick={() => setShowEsqueciSenha(true) }>
                            Esqueci minha senha
                        </button>    
                    </>
                }
            </>
        }

        { showEsqueciSenha && 
            <>
                <form className="modalAlinhamentoForm pt-2" onSubmit={sendPasswordReset}>
                    {showLoginPreloader && 
                        <div className="text-center mx-5">
                            <FontAwesomeIcon icon={faCircleNotch} className="mx-5 my-3 fa-spin fa-5x" style={{opacity: 0.5}} /> 
                        </div>
                    }
                    {!showLoginPreloader && 
                        <>
                            <div className="mb-2 text-white">
                                Digite seu e-mail de cadastro para gerar uma nova senha
                            </div>
                            <div className="form-group">
                                <input ref={refEmailEsqueciSenha} type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="email" onChange={e => setUserName(e.target.value)} />
                            </div>
                            <Button type="submit" className="btn btn-primary">
                                Gerar nova senha
                            </Button>
                        </>
                    }
                </form>
                <p className="mb-2 mt-4">
                    <button className="textButton ml-1" onClick={() => setShowEsqueciSenha(false) }>
                        &laquo; voltar
                    </button>
                </p>
            </>
        }

    </>
  );
}