import React, { useState, useRef } from 'react';
import api                 from "../services/api";
import BannerSlider        from '../components/BannerSlider';
import ListagemVideos      from '../components/ListagemVideos';
import '../routes/Descubra.css';



export default function Descubra() {

    const [categoria, setCategoria] = useState(null);
    const row = useRef([])

    /**
    * Buscar temporadas e agrupar por categoria
    **/ 
    React.useEffect(() => {

        /**
         * Buscar categorias
         **/ 
        api
        .get('categoria/')
        .then((response) => {
            if(response.data.status === 0) { 
                return null;
            }
            setCategoria(response.data.categoria)
        })
        .catch((err) => {
            console.error("Erro ao buscar pela API: " + err);
        });

    }, []);


    const enableTopic = (onLoadKey, items) => {
        if(items.length > 0) {
            row.current[onLoadKey].style.display = 'block'
        }
    }



    return (
    <>


        <div className="margemMenu">
            <BannerSlider />
        </div>


        {categoria && categoria.map((categoria, i) => {

            return (
                <div key={i} ref={(element) => row.current.push(element)} style={{display:'none'}} className="mt-4">
                    <div className="listagemContainer">
                        <div className="titulosCategorias pb-3">
                            <h3>
                                <span className="ml-2">
                                    {categoria.nome}
                                </span>
                            </h3>
                        </div> 
                    </div>

                    <ListagemVideos carousel={false} onLoadKey={i} categoria={categoria.id} onLoadCallback={enableTopic} />

                </div>
            )
        })}

    </>
    )
}