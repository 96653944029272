import React, { useState } from 'react';

import api from "../../services/api";
import User from '../../utils/User.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faCheck } from '@fortawesome/free-solid-svg-icons'
// import { faStar as farStar } from '@fortawesome/free-regular-svg-icons'

export default function Avaliacao(props) {

    const [starActive, setStarActive]     = useState([true, true, true, true, true]);
    const [starKey, setStarKey]           = useState(4);
    const [showSalvando, setShowSalvando] = useState(false);
    const [showObrigado, setShowObrigado] = useState(false);
    const [showButtons, setShowButtons]   = useState(true);

    /**
    * Centralizar modal
    **/ 

    const salvar = () => {

        // Exibir preloader
        setShowSalvando(true)
        setShowButtons(false)


        // Enviar POST
        var params = new URLSearchParams();
        params.append('acao', 'salvarAvaliacao')
        params.append('submodulo', props.submodulo.id)
        params.append('avaliacao', starKey+1)
        api
        .post(`submoduloX_user/?key=${User.getAttr('token')}`, params)
        .then(res => {

            // Erro 
            if(res.data.status === 0) { 
                console.log('Erro ao salvar avaliação');
                return null;
            } 

            // preloader / agradecimento
            setShowSalvando(false)
            setShowObrigado(true)

            // ocultar tudo
            setTimeout(function() {
                setShowObrigado(false)
                setShowButtons(true)
                props.setShow(false)
                props.callback()
            }, 1500)
        })
    }

    const hoverStar = (key) => {
        let starActive = []
        let starKey = 0;
        for(let k=0; k<5; k++) {
            starActive[k] = (k<=key)
            if(starActive[k]) { 
                starKey = k;
            }
        }
        setStarActive(starActive);
        setStarKey(starKey);
    }


    const serieOuTemporada = (props.temMaisModulos ? 'temporada' : 'série')

  return (

    <>
      <div className="modal-sobre-player-backdrop">
      </div>
      <div className="modal-sobre-player" >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Avalie esta {serieOuTemporada}
              </h5>
            </div>
            <div className="modal-body">

              {showButtons && 
                <div className="pb-2 pt-2 text-center">
                  Sua opinião é importante!
                  <br />
                  Clique na quantidade de estrelas que você dá para a {serieOuTemporada}
                  <br />
                  <br />
                  {[...Array(5)].map((e, key) => {
                    const avaliacao = key+1;
                    return (
                      <a href="#" id={`avaliacao-estrela${key}`} className="avaliacao-estrela" onClick={() => { salvar(avaliacao) }} key={key} style={{color: (starActive[key] ? '#F1CC00' : '#CCC')}} onMouseEnter={ () => hoverStar(key) }>
                        <FontAwesomeIcon icon={faStar} size='3x' />
                      </a>
                    )
                  })}
                </div>
              }

              {showSalvando && 
                <div className="avaliacao-processando text-secondary text-center p-3">
                  <i className="fas fa-spinner fa-pulse">
                  </i>
                  salvando
                </div>
              }

              {showObrigado && 
                <div className="avaliacao-depois text-center p-3">
                  <FontAwesomeIcon icon={faCheck} size='2x' />
                  Obrigado por avaliar!
                </div>
              }
              
            </div>

            {showButtons && 
              <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={salvar}>
                  <span className="fa fa-check">
                  </span>
                  avaliar
                </button>
              </div>
            }
          </div>
        </div>
      </div>
    </>

  )
}

