import React from 'react';
import api from "../services/api";
import { useOutletContext, Link } from "react-router-dom";


import banner_v4     from '../imagens/banner_v4.png'
import iddplus_info1 from '../imagens/iddplus-info1.png'
import iddplus_info2 from '../imagens/iddplus-info2.png'
import desktop       from '../imagens/desktop.svg'
import smartphone    from '../imagens/smartphone.svg'
import tablet        from '../imagens/tablet.svg'
// import tv        from '../imagens/tv.svg'
import school        from '../imagens/school.svg'
import search        from  '../imagens/search.svg'
import star          from  '../imagens/star.svg'
import new_releases  from  '../imagens/new_releases.svg'

import ListagemDocentes from '../components/ListagemDocentes';
import ListagemVideos   from '../components/ListagemVideos';
import Faq              from '../components/Faq';
import BannerSlider             from '../components/BannerSlider';


export default function HomeDeslogado() {

    const [ assinaturaPreco, setAssinaturaPreco ] = React.useState(null);
    const [ bannerLink,      setBannerLink      ] = React.useState(null);

    const [associacao] = useOutletContext().associacao
    const toggleModalCadastro = useOutletContext().toggleModalCadastro


    React.useEffect(() => {

        api.get('preco/')
        .then(res => {

            if(!res.data)             return null;
            if(res.data.status === 0) return null;
            if(!res.data.preco)       return null

            setAssinaturaPreco(res.data.preco)

        })


        api.get('programa/143/')
        .then(res => {

            if(!res.data) { 
                console.log('Erro ao buscar programa pela API');
                return null
            }
            if(res.data.status === 0) { 
                console.log('Erro ao buscar programa pela API');
                return null
            }
            setBannerLink(`detalhes/${res.data.programa.site_slug}`)

        })

    }, []);



    return (
    < >

        { bannerLink && 
            <Link to={bannerLink}>
                <img className="img-fluid" src={banner_v4} style={{marginTop:'65px'}} />
            </Link>
        }



        <div style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap', backgroundColor:'#080116'}}>
            <img className="img-fluid" src={iddplus_info1} />
            <img className="img-fluid" src={iddplus_info2} />
        </div>








        <div className="divisorArea">
            <div className="divisorEsquerda">
            </div>
            <div className="divisorConteudo">
                Porque aprender com o IDD plus!
            </div>
            <div className="divisorDireita">
            </div>

        </div>
        <div className="divisorArea" style={{display: 'flex', 'justifyContent': 'center', flexWrap: 'wrap'}}>

            <div className="d-flex flex-column homeAprender" style={{flexBasis: '45%', border: '2px solid #2F9BD3', margin:'0 2% 20px 2%', borderRadius:'10px', padding: '20px'}}>
                <h5 className="homeAprenderTitulo"><img className="mr-3" src={search} />Descubra</h5>
                <p className="homeAprenderTexto">
                    Explore conteúdos de diferentes áreas do conhecimento que se complementam para uma experiência de aprendizado única.
                </p>
            </div>
            <div className="d-flex flex-column homeAprender" style={{flexBasis: '45%', border: '2px solid #2F9BD3', margin:'0 2% 20px 2%', borderRadius:'10px', padding: '20px'}}>
                <h5 className="homeAprenderTitulo"><img className="mr-3" src={school} />Aprenda</h5>
                <p className="homeAprenderTexto">
                    Aprender não deve ser uma tarefa árdua. Com nossa plataforma de streaming, você pode aprender no seu ritmo, em qualquer horário e lugar.
                </p>
            </div>
            <div className="d-flex flex-column homeAprender" style={{flexBasis: '45%', border: '2px solid #2F9BD3', margin:'0 2% 20px 2%', borderRadius:'10px', padding: '20px'}}>
                <h5 className="homeAprenderTitulo"><img className="mr-3" src={star} />Conquiste</h5>
                <p className="homeAprenderTexto">
                    Cada vídeo, cada lição é uma oportunidade de crescimento. Aprofunde-se em tópicos especializados e aprimore suas habilidades.
                </p>
            </div>
            <div className="d-flex flex-column homeAprender" style={{flexBasis: '45%', border: '2px solid #2F9BD3', margin:'0 2% 20px 2%', borderRadius:'10px', padding: '20px'}}>
                <h5 className="homeAprenderTitulo"><img className="mr-3" src={new_releases} />Diferencie-se</h5>
                <p className="homeAprenderTexto">
                    Os conhecimentos adquiridos em nossa plataforma não apenas fortalecerão sua confiança, mas também abrirão portas para novas oportunidades.
                </p>
            </div>
        </div>
        <div style={{display: 'flex', 'justifyContent': 'center', flexWrap: 'wrap', marginTop: '20px'}}>
            <a className="btn transicaoBotoes" onClick={() => toggleModalCadastro(true)}>
                Assine e comece a assistir
            </a>
        </div>



        <div className="divisorArea" style={{display: 'flex', 'justifyContent': 'center', flexWrap: 'wrap'}}>
            
        </div>












        <div className="divisorArea">
            <div className="divisorEsquerda">
            </div>
            <div className="divisorConteudo">
                Benefícios de assinar IDD plus!
            </div>
            <div className="divisorDireita">
            </div>

        </div>

        <div className="beneficiosLandingArea">

            <p className="w-75 pt-3 pb-3">
                Acesso a aulas, materiais e conhecimento das mais novas tecnologias no mercado da Construção Civil, Engenharia e Arquitetura, em alta qualidade para assistir quando e onde quiser.
            </p>
            <a className="btn transicaoBotoes" onClick={() => toggleModalCadastro(true)}>
                Veja o plano!
            </a>

        </div>











        <div className="titulosCategorias pb-3">
            <span className="divisorTitulos">
            </span>
            Lançamentos
        </div>


        <ListagemVideos lancamentos={true} carousel={true} />







        <div className="divisorArea">
            <div className="divisorEsquerda2">
            </div>
            <div className="divisorConteudo2">
                Corpo docente
            </div>
            <div className="divisorDireita2">
            </div>
        </div>
        <ListagemDocentes carousel={true} />




        <div className="divisorArea">
            <div className="divisorEsquerda3">

            </div>
            <div className="divisorConteudo3">
                De qualquer lugar
            </div>
            <div className="divisorDireita3">

            </div>

        </div>


        <div className="dispositivosLandingArea">


            <div className="dispositivosLandingArea2">
                <div className="dispositivosLandingicones">
                    <img className="dispositivosImagem" src={desktop} />
                    <p style={{'paddingTop': '8px'}}>Computador</p>
                </div>
                <div className="dispositivosLandingicones">
                    <img className="dispositivosImagem" src={smartphone} />
                    <p style={{'paddingTop': '8px'}}>Celular</p>
                </div>
                <div className="dispositivosLandingicones">
                    <img className="dispositivosImagem" src={tablet} />
                    <p style={{'paddingTop': '8px'}}>Tablet</p>
                </div>
                {/*
                <div className="dispositivosLandingicones">
                    <img src={tv} height="78">
                    <p style={{'paddingTop': '8px'}}>TV</p>
                </div>
                */}
            </div>

            <a className="btn transicaoBotoes" onClick={() => toggleModalCadastro(true)}>
                Veja o plano!
            </a>
        </div>





        <div className="margemMenu">
            <BannerSlider />
        </div>




        { assinaturaPreco && 


            <>


                {/*<div className="divisorArea">
                    <div className="divisorEsquerda2">
                    </div>
                    <div className="divisorConteudo2">
                        Assine já
                    </div>
                    <div className="divisorDireita2">
                    </div>
                </div>*/}

                {/*<div id="planos" className="planosLandingArea">
                    <p className="pt-3 pl-3 pr-3">
                        Assine e desfrute de diversos conteúdos ligados a Construção Civil!
                    </p>*/}
                    {/*<div className="planosLandingArea2">


                        <div className="planosLandingContorno">
                            <div className="planosLandingBox">
                                <div className="planosLandingTitulo">
                                    <strong>
                                        Assinatura IDD Plus
                                    </strong>
                                </div>
                                
                                <div className="planosLandingPreco">
                                    <div className="planosLandingMoeda">
                                        R$
                                    </div>
                                    <div className="planosLandingValor">
                                        {assinaturaPreco.toPriceFormat()}
                                    </div>
                                    <div className="planosLandingPeriodo">
                                        /mês
                                    </div>
                                </div>

                                <div className="planosLandingLista">
                                    <li>
                                        Assista onde e quando quiser

                                    </li>
                                    <li className="planosLandingLinha">
                                    </li>
                                    <li>
                                        Acesso aos conteúdos
                                    </li>
                                    <li className="planosLandingLinha">
                                    </li>
                                    <li>
                                        1 tela (conexão máxima)
                                    </li>
                                    <li className="planosLandingLinha">
                                    </li>
                                    <li>
                                        Qualidade HD/Full HD
                                    </li>
                                    <li className="planosLandingLinha">
                                    </li>
                                    <li>
                                        Suporte especializado
                                    </li>
                                    <li className="planosLandingLinha"></li>
                                    <li>
                                        Plano anual
                                    </li>
                                </div>
                                <a className="planosLandingBotao2" href="/cadastro/">
                                    Assinar
                                </a>
                            </div>
                        </div>*/}
                        {/* planos
                        <div className="planosLandingContorno">
                            <div className="planosLandingBox">
                                <div className="planosLandingTitulo">
                                    <strong>Premium</strong>
                                </div>
                                <p className="planosLandingDescontos">
                                    10% de desconto
                                </p>
                                <div className="planosLandingPreco">
                                    <div className="planosLandingMoeda">
                                        R$
                                    </div>
                                    <div className="planosLandingValor">
                                        79,90
                                    </div>
                                    <div className="planosLandingPeriodo">
                                        /m&ecirc;s
                                    </div>
                                </div>

                                <div className="planosLandingLista">
                                    <li>
                                        Assista onde e quando quiser
                                    </li>
                                    <li className="planosLandingLinha">
                                    </li>
                                    <li>
                                        Acesso a todos os conte&uacute;dos
                                    </li>
                                    <li className="planosLandingLinha">
                                    </li>
                                    <li>
                                        1 tela (conex&atilde;o m&aacute;xima)
                                    </li>
                                    <li className="planosLandingLinha">
                                    </li>
                                    <li>
                                        Qualidade HD/Full HD
                                    </li>
                                    <li className="planosLandingLinha">
                                    </li>
                                    <li>
                                        Suporte especializado
                                    </li>
                                </div>
                                <a className="planosLandingBotao2" href="/cadastro/">
                                    Quero este!
                                </a>
                            </div>
                        </div>
                        planos        

                    </div> 

                </div>*/}



            </>

        }


        { associacao && associacao.length > 1 && 

            <>


                <div className="divisorArea">
                    <div className="divisorEsquerda2">
                    </div>
                    <div className="divisorConteudo2">
                        Parceiros
                    </div>
                    <div className="divisorDireita2">
                    </div>
                </div>




                <div className="text-center pt-3">

                    {associacao.map((item, key) => {
                        const url_imagem = api.getImageUrl(item.url_imagem, 125, 56)
                        return (
                            <div key={key} style={{display: 'inline-block'}}>
                                <a href="canal.html" className="parceirosLandingBox">
                                    <img className="parceirosLandingLogos" src={url_imagem} />
                                </a>
                            </div>
                        )
                    })}

                </div>




                
            </>
        }









       <Faq />





 



      </ >
  );
}