import React, { useState } from 'react';
import { useOutletContext }        from "react-router-dom";

import User                     from '../utils/User.js';
import api                      from "../services/api";

import BannerSlider             from '../components/BannerSlider';
import ListagemVideosDaSerie    from '../components/ListagemVideosDaSerie';
import ListagemVideos           from '../components/ListagemVideos';
import ListagemVideosAssociacao from '../components/ListagemVideosAssociacao';
import Faq                      from '../components/Faq';

import './HomeLogado.css'

export default function HomeLogado() {

    const [temProgramaX_user, setTemProgramaX_user] = useState(false);
    const [programa, setPrograma] = useState([]);

    const associacao = useOutletContext().associacao;

    React.useEffect(() => {

        /**
         * Ver se tem alguma coisa no "continuar assistindo"
         **/ 
        api
        .get(`programaX_user/?key=${User.getAttr('token')}`)
        .then((response) => {
            if(response.data.status === 0) { 
                return null;
            }
            setTemProgramaX_user(response.data.tem_programaX_user)
        })
        .catch((err) => {
            console.error("Erro ao buscar pela API: " + err);
        });

        /**
         * Buscar programas
         **/ 
        api
        .get(`programa/?key=${User.getAttr('token')}&resumido=1`)
        .then((response) => {
            if(response.data.status === 0) { 
                return null;
            }
            setPrograma(response.data.programa)
        })
        .catch((err) => {
            console.error("Erro ao buscar pela API: " + err);
        });


    }, []);




    return (
    <>


        <div className="margemMenu">
            <BannerSlider />
        </div>



        { temProgramaX_user && 
            <>
                <div className="listagemContainer pt-5">
                    <div className="titulosCategorias pb-3">
                        <span className="divisorTitulos"></span>
                        Continue assistindo
                    </div>
                </div>

                <ListagemVideos continue={true} carousel={false} onLoadKey={0} />

            </>
        }


        <ListagemVideosDaSerie programa={programa} />

        

        {/* Associacoes */}
        { associacao && associacao.map((item, key) => {
            return (
                <div key={key}>
                   <ListagemVideosAssociacao associacao={item} />
                </div>
            )
        }
        )}



        <Faq />


        
    </>

  );
}