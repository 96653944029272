import React, { useState } from 'react';
import api                           from "../services/api";
import User                          from '../utils/User.js';

import BannerSlider             from '../components/BannerSlider';
import ListagemVideosDaSerie    from '../components/ListagemVideosDaSerie';

export default function Lancamentos() {

    const [programa, setPrograma] = useState([]);

    React.useEffect(() => {

        /**
         * Buscar lançamentos
         **/ 
        let url = `programa/lancamentos/?resumido=1`
        if(User.getAttr('token')) { 
            url += `&key=${User.getAttr('token')}`
        }
        api
        .get(url)
        .then((response) => {
            if(response.data.status === 0) { 
                return null;
            }
            setPrograma(response.data.programa)
        })
        .catch((err) => {
            console.error("Erro ao buscar pela API: " + err);
        });


    }, []);





    return (
    <>
        <div className="margemMenu">
            <BannerSlider lancamentos="1" />
        </div>



        <ListagemVideosDaSerie programa={programa} />



    </>
    );
}