import React, { useRef, useState } from 'react'
import Modal               from 'react-bootstrap/Modal';
import { useSearchParams, 
         useOutletContext }        from "react-router-dom";
import InputMask                   from 'react-input-mask';
import User                        from '../utils/User.js';
import Checkout                    from '../utils/Checkout.js';
import IsValid                     from '../utils/IsValid.js';
import PagePreloader               from '../components/PagePreloader.jsx'
import api                         from "../services/api";

export default function ModalCadastro(props) {

    const [ searchParams ] = useSearchParams();
    const [ showPreloader, setShowPreloader ] = useState(true)
    const [ inputVal, setInputVal ] = useState({});
    
    const debugMode = (searchParams.get("debugMode") ? true : false)
    const formName  = (props.options.site_slug ? props.options.site_slug : 'Assinatura IDDplus') + ' - Etapa 1'


    React.useEffect(() => {

        if(!User.getAttr('token')) { 
            setShowPreloader(false)
            return
        }

        api
        .get(`_user/?key=${User.getAttr('token')}`)
        .then((response) => {

            // Erro no ajax
            if(response.data.status === 0) { 
                return
            }
            setShowPreloader(false)

            /**
             * Inserir dados no inputVal
             **/ 
            const _user = response.data._user
            const dados = {}
            dados.nome = _user.userType.nome
            dados.email = _user.email
            if(_user.userType.telefone[0]) { 
                dados.numero_formatado = _user.userType.telefone[0].numero_formatado
                inputRef['numero_formatado'].current.value = dados.numero_formatado
            }
            setInputVal(dados)

        })
    }, [])


    /**
     * Setar inputRef
     **/ 
    const refs = ['nome', 'email', 'numero_formatado']
    const inputRef = []
    for(let name of refs) {
        inputRef[name] = useRef(null);
    }





    /**
     * Ao digitar no input
     **/ 
    const onInputChange = (event) => {

        const value = event.target.value
        const name = event.target.name
        inputVal[name] = value
        setInputVal(inputVal)
    }



    /**
     * Submit
     **/ 
    const onSubmit = (event) => {

        event.preventDefault()

        /**
         * Validar
         **/ 
        let nome = null
        if(inputVal.nome) { 
            if(inputVal.nome.trim() != '') { 
                nome = inputVal.nome
            }
        }
        if(!nome) { 
            alert('Preencha seu nome.')
            return
        }

        let email = null
        if(inputVal.email) { 
            if(inputVal.email.trim() != '') { 
                email = inputVal.email
            }
        }
        if(!email) { 
            alert('Preencha seu e-mail.')
            return
        }
        if(!IsValid.email(email)) { 
            alert('E-mail inválido.')
            return
        }
        let numero_formatado = null
        if(inputVal.numero_formatado) { 
            if(inputVal.numero_formatado.trim() != '') { 
                numero_formatado = inputVal.numero_formatado
            }
        }
        if(!numero_formatado) { 
            alert('Preencha seu telefone.')
            return
        }


        /**
         * Ver se e-mail já existe e trazer dados dele ==================================================
         **/ 
        setShowPreloader(true)

        api
        .get(`_user/email/${email}`)
        .then((response) => {
           
            // Erro no ajax
            if(response.data.status === 0) { 
                return
            }
            if(props.options.free && response.data._user !== null) {
                alert('Você já tem uma conta no IDD.\nUtilize este mesmo e-mail para logar. ')
                props.setModalAssinanteFree(true)
                props.toggleModalAssinante(true)
                props.toggleModalCadastro(false)
                return
            }

            /**
             * Salvar
             **/ 
            const postParams = new URLSearchParams()
            if(response.data._user !== null) { 
                postParams.append('id', response.data._user.id)
            }
            if(props.options.free) {
                postParams.append('get_password_url', 1);
            } else { 
                postParams.append('salvarEtapaFunil', 1)
            }
            postParams.append('email', inputVal['email'])
            postParams.append('nome', inputVal['nome'])
            postParams.append('numero_formatado', inputVal['numero_formatado'])

            let url = `_user/`
            if(User.getAttr('token')) { 
                url += `?key=${User.getAttr('token')}`
            }
            api
            .post(url, postParams)
            .then(res => {

                /**
                 * Erros
                 **/ 
                if(res.data.status === 0) { 
                    alert('Erro ao salvar dados')
                    window.location.reload()
                    return
                } 
                if(res.data.invalid_attribute) { 
                    alert(`Já existe um usuário com este ${res.data.invalid_attribute.replace('email', 'e-mail')}.`)
                    return 
                }

                /**
                 * Alterar nome no menu
                 **/ 
                if(User.getAttr('token')) { 
                    const user = User.getUser()
                    user.name = inputVal['nome']
                    User.setUser(user)
                    useOutletContext().setMenuUsuarioTimestamp(new Date().getTime())
                }


                if(props.options.free) {

                    /**
                     * User free - vai setar senha
                     **/ 
                    document.location = res.data.password_url+'&callback_url=' + encodeURIComponent(document.location.href)

                } else { 
                    /**
                     * Gerar transacao
                     **/ 
                    Checkout.go({
                        _user: res.data.id, 
                        site_slug: props.options.site_slug,
                        onDismiss: () => setShowPreloader(false)
                    })
                }

            }).catch((err) => {
                console.error("Erro ao salvar pela API: " + err);
            })
        })
        .catch((err) => {
            console.error("Erro ao buscar pela API: " + err);
        });

    }

    


    /**
     * Para testes em debugMode
     **/ 
    let autopreencher
    if(debugMode) { 
        autopreencher = () => {
            let attrs;
            attrs = {
                nome:             `Nilson Kiyoshi Sakamoto`, 
                email:            `${new Date().getTime()}@gamma3.com.br`,
                numero_formatado: `(41) 99191-8632`
            }
            for(let name in attrs) {
                document.querySelector(`[name='${name}']`).value = attrs[name]
                onInputChange({target:document.querySelector(`[name='${name}']`)});
                inputVal[name] = attrs[name]
                setInputVal(inputVal)
                setTimeout(() => {
                    document.querySelector(`[name='${name}']`).value = attrs[name]
                }, 500)

            }
        }
    }


    

    return (
    <>

        <Modal show={props.show} onHide={() => props.toggleModalCadastro(false)} backdrop={true} >

            <PagePreloader show={showPreloader} />


            { !showPreloader && 

                <>

                    <div className="cadastroBox2" style={{border:'solid 2px #FFF'}}>

                        { props.site_slug && 
                            <h2 style={{lineHeight: '100%'}}>
                                Conteúdo on-demand 
                                <br />
                                <span className="cadastroSubtitulo">
                                    Confirme seus dados
                                </span>
                            </h2>
                        }

                        { !props.site_slug && 

                            <>

                                <h2>
                                    Cadastro
                                </h2>

                            </>
                        }

                        <form name={formName} onSubmit={onSubmit}>
                            <div className="row">    
                                
                                <div className="col-12">
                                    <label className="cadastroLabels">Nome Completo</label>
                                    <input ref={inputRef['nome']} name="nome" id="nome" placeholder="Seu nome" type="text" className="CadastroCampoNome obrigatorio" defaultValue={inputVal.nome} onChange={onInputChange} />
                                </div>

                                { User.getAttr('token') && 
                                    <input ref={inputRef['email']} value={inputVal.email} type="hidden" />
                                }

                                { !User.getAttr('token') && 
                                    <div className="col-12">
                                        <label className="cadastroLabels">
                                            E-mail
                                        </label>
                                        <input ref={inputRef['email']} name="email" id="email" type="text" className="CadastroCampoEmail obrigatorio inputEmail" defaultValue={inputVal.email} onChange={onInputChange} placeholder="email@exemplo.com" />
                                    </div>
                                }


                                <div className="col-12 col-sm-8">
                                    <label className="cadastroLabels">Telefone</label>
                                    <InputMask ref={inputRef['numero_formatado']} mask="(99) 99999-9999" defaultValue={inputVal.numero_formatado} name="numero_formatado" id="fone" placeholder="(00) 00000-0000" type="text" className="CadastroCampoFone" onChange={onInputChange} />
                                </div> 
                                <div className="col-12 col-sm-4">
                                    <label className="cadastroLabels"></label>
                                    <button type="submit" className="btn CadastroCampoBotao2" style={{width:'100%'}}>
                                        continuar
                                    </button>
                                </div>
                            </div>                                  
                        </form>

                    </div>

                    { debugMode && 
                        <>
                            <button className="btn btn-xs btn-light mt-2" type="button" onClick={autopreencher} >
                            autopreencher
                            </button>
                        </>
                    }

                </>

            }



        </Modal>


    </>
  );
}