export default function BarraCarregamento(props) {

    let porcentagem = 0
    let isQuizz = false

    if(typeof(props.submodulo) != 'undefined') { 
        /**
         * de episódio
         **/ 
        if(typeof(props.submodulo.submoduloX_user) != 'undefined') { 

            let isQuizz = typeof(props.submodulo.questionario) != 'undefined'
            if(isQuizz) { 
                porcentagem = props.submodulo.submoduloX_user.questionarios_acertos / props.submodulo.submoduloX_user.questionarios_questoes * 100
            } else { 
                const trechos = props.submodulo.submoduloX_user.trechos_assistidos
                if(trechos) { 
                    const duracao = Number(trechos.substr(0, trechos.indexOf('|')))
                    porcentagem = props.submodulo.submoduloX_user.video_actual_time * 100 / duracao
                }
            }

        }

    } else if(typeof(props.programa) != 'undefined') { 

        /**
         * de programa
         **/
        if(!props.programa.quantidade_submodulos_assistidos) { 
            return null
        }
        porcentagem = props.programa.quantidade_submodulos_assistidos * 100 / props.programa.quantidade_submodulos;

    } else if(typeof(props.porcentagem) != 'undefined') { 
        porcentagem = props.porcentagem
    } else { 
        porcentagem = 0;
    }

    porcentagem = Math.round(porcentagem)

    if(props.deThumbnail) { 

        return(
            <div className="listagemBoxCarregamento">
                <div className="barraCarregamento">
                    <div className="barraCarregamentoValor" style={{width:porcentagem+'%'}}>
                    </div>
                </div>
            </div>
        )

    } else { 

        return(
            <>
                <div className="abasBarraCarregamento">
                    <div className="abasBarraCarregamentoValor" style={{width:`${porcentagem}%`}}>
                    </div>
                </div>
                <div className="abasBarraProgresso">
                    {props.duracao && 
                        <div>
                            {props.duracao}
                        </div>
                    }
                    {porcentagem > 0 && 
                        <>
                            {!isQuizz && 
                                <div>
                                    Progresso do episódio: {porcentagem}%
                                </div>
                            }
                        </>
                    }
                </div>       
            </>
            )
    }
}