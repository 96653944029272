import React   from 'react'

import star1 from '../imagens/star1.svg'
import star2 from '../imagens/star2.svg'
import star3 from '../imagens/star3.svg'

import './Estrelas.css'

export default function Estrelas(props) {


    /**
    * Ainda não avaliado
    **/ 
    if(!props.avaliacao) { 
        return (
            <div className="listagemEstrelasNaoAvaliado">
            não avaliado
            </div>
            )
    }

    /**
    * Classificação inválida
    **/ 
    if(props.avaliacao > 5) { 
        throw('Foi dada uma classificação de estrelas maior que 5')
    }

    /**
     * className
     **/ 
    let estrelaClassName = ''
    if(typeof(props.estrelaClassName) != 'undefined') {
        estrelaClassName = props.estrelaClassName
    }

    /**
    * Setar estrelas cheias / metade / vazias
    **/ 
    const estrelasCheias = Math.floor(props.avaliacao)

    let star = [];
    for(let i=0; i<estrelasCheias; i++) {
        star.push(star3)
    }
    const temMeiaEstrela = (props.avaliacao - estrelasCheias >= 0.5)
    if(temMeiaEstrela) { 
        star.push(star2);
    }
    for(let i=star.length; i<5; i++) {
        star.push(star1)
    }

    /**
     * Plotar estrelas
     **/ 
    return(
    <>
      <div className="listagemEstrelasVideo">
        {star.map((src, i) =>
            <img className={estrelaClassName} src={src} key={i} />
        )}
      </div>
    </>
  )
}