import Faq from '../components/Faq';


export default function Ajuda() {

    return (

    <>
        <div style={{marginTop:'110px'}}>
            <Faq />
        </div>
    </>
    )
}


