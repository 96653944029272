import React from 'react';
import api from "../services/api";

import barra_idd_plus from '../imagens/barra_idd_plus.jpg';
import ListagemVideos from '../components/ListagemVideos';

export default function ListagemVideosAssociacao(props) {
    return (
    < >
        <div className="listagemContainer">
            <div className="listagemTextos mb-4">
                <div className="listagemTitulo">
                    Canal 
                    <img src={barra_idd_plus} className="mx-2" /> 
                    <img src={api.getImageUrl(props.associacao.url_imagem, 250, 56)} />
                </div>
                <div className="listagemDescricao">
                    {props.associacao.descricao_canal && props.associacao.descricao_canal.nl2br()}
                </div>
            </div>
            <ListagemVideos associacao={props.associacao.id} />
        </div>
    </ >);
}

