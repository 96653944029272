
export default class IsValid {

    static cep(string) {
        if(string.length != 9) {
            return false;
        } else if(isNaN(string.substr(0,5))) {
            return false;
        } else if(isNaN(string.substr(6))) {
            return false;
        } else if(string.substr(5,1) != '-') {
            return false;
        } else {
            return true;
        }
    }

    static date(data) { 

        if(data.length != 10) {
            return false;
        }

        const __dia = (data.substring(0,2)); 
        const __mes = (data.substring(3,5)); 
        const __ano = (data.substring(6,10));

        if(isNaN(__dia) || isNaN(__mes) || isNaN(__ano)) {
            return false;
        }

        if (data == "") { 
            return false; 
        } else if(data.length < 10) { 
            return false; 
        } else if(__dia < 1 || __dia > 31) {
            return false; 
        } else if(__dia == 31 && (  __mes == 4 || __mes == 6 || __mes == 9 || __mes == 11 ) ) { 
            // verifica o __dia valido para cada __mes 
            return false; 
        } else if (__mes < 1 || __mes > 12 ) {  
            // verifica se o __mes e valido 
            return false; 
        } else if (__mes == 2 && ( __dia < 1 || __dia > 29 || ( __dia > 28 && (parseInt(__ano / 4) != __ano / 4)))) { 
            // verifica se e __ano bissexto 
            return false; 
        } else {
            return true;
        }
    } 


    static fileExtension(arquivo, extensoes) {
        if(typeof(extensoes) == 'string') {
            extensoes = [extensoes];
        }
        const ext = arquivo.substring(arquivo.lastIndexOf(".")+1).toLowerCase();
        for(var i = 0; i <= arquivo.length; i++){
            if(extensoes[i] == ext){
                return true;
            }
        }
        return false;
    }


    /**
    * Valida o tamanho em Kbytes.
    */
    // static fileSize(id_input, tamanho_maximo) {
    //     var size = document.getElementById(id_input).files[0].size;
    //     if(size > tamanho_maximo) { 
    //         alert('O tamanho máximo do arquivo é ' + formatBytes(tamanho_maximo, 2) + '.\n(o arquivo inserido tem ' + formatBytes(size,  2) + ')');
    //         return false;
    //     }
    //     return true;
    // }


    // FUNÇÃO UTILIZADA PELO validarEmail():
    static Validate_String(string, return_invalid_chars) {
        const valid_chars = '1234567890-_.^~abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let invalid_chars = '';
        if(string == null || string == '') {
            return(true);
        }
        //For every character on the string.   
        for(let index = 0; index < string.length; index++) {
            const char = string.substr(index, 1);                        
            //Is it a valid character?
            if(valid_chars.indexOf(char) == -1) {
                //If not, is it already on the list of invalid characters?
                if(invalid_chars.indexOf(char) == -1) {
                    //If it’s not, add it.
                    if(invalid_chars == '') {
                        invalid_chars += char;
                    } else {
                        invalid_chars += ', ' + char;
                    }
                }
            }
        }
        //If the string does not contain invalid characters, the function will return true.
        //If it does, it will either return false or a list of the invalid characters used
        //in the string, depending on the value of the second parameter.
        if(return_invalid_chars == true && invalid_chars != '') {
            const last_comma = invalid_chars.lastIndexOf(',');
            if(last_comma != -1)
                invalid_chars = invalid_chars.substr(0, last_comma) + 
            ' and ' + invalid_chars.substr(last_comma + 1, invalid_chars.length);
            return(invalid_chars);
        } else {
            return(invalid_chars == ''); 
        }
    }


    static email(email_address) {
        //Assumes that valid email addresses consist of user_name@domain.tld
        const at = email_address.indexOf('@');
        const depois_do_arroba = email_address.substr(at+1);
        const ponto1 = email_address.indexOf('.');
        const ponto2 = depois_do_arroba.indexOf('.');
        let depois_do_ponto2 = depois_do_arroba.substr(ponto2+1);
        const ponto3 = depois_do_ponto2.indexOf('.');
        let depois_do_ponto3 = depois_do_ponto2.substr(ponto3+1);
        const ponto4 = depois_do_ponto3.indexOf('.');


        let _fim = (ponto3 != -1 ? ponto3 : depois_do_ponto2.length);
        depois_do_ponto2 = depois_do_ponto2.substr(0, _fim);
        _fim = (ponto4 != -1 ? ponto4 : depois_do_ponto3.length);
        depois_do_ponto3 = depois_do_ponto3.substr(0, _fim);

        if(at == -1 ||                          // sem arroba
            ponto1 == 0 ||                          // com ponto no início
            ponto1 == at-1 ||                       // com ponto logo antes do arroba
            ponto2 == -1 ||                         // sem ponto depois do arroba
            ponto2 == 0 ||                          // com ponto logo depois do arroba
            ponto2 == depois_do_arroba.length ||    // com ponto no final
            ponto3 == 0 ||                          // com ponto logo depois do arroba
            ponto3 == depois_do_arroba.length       // com ponto no final
        )
            return(false);
        
        const user_name = email_address.substr(0, at);
        const domain_name = email_address.substr(at + 1, email_address.length);                  

        if(this.Validate_String(user_name) === false || 
            this.Validate_String(domain_name) === false)
            return(false);                     

        return(true);
    }










    static cpf(cpf) {
        cpf = cpf.replace(/\./g, '');
        cpf = cpf.replace(/\//g, '');
        cpf = cpf.replace(/-/g, '');

        let erro = new String;
        if (cpf.length < 11) erro += "Sao necessarios 11 digitos para verificacao do CPF! \n\n"; 
        var nonNumbers = /\D/;
        if (nonNumbers.test(cpf)) erro += "A verificacao de CPF suporta apenas numeros! \n\n"; 
        if (cpf == "00000000000" || cpf == "11111111111" || cpf == "22222222222" || cpf == "33333333333" || cpf == "44444444444" || cpf == "55555555555" || cpf == "66666666666" || cpf == "77777777777" || cpf == "88888888888" || cpf == "99999999999"){
            erro += "Numero de CPF invalido!"
        }
        let a = [];
        let b = new Number;
        let c = 11;
        for (let i=0; i<11; i++){
            a[i] = cpf.charAt(i);
            if (i < 9) b += (a[i] * --c);
        }
        let x
        if ((x = b % 11) < 2) { a[9] = 0 } else { a[9] = 11-x }
            b = 0;
        c = 11;
        let y
        for (y=0; y<10; y++) b += (a[y] * c--); 
            if ((x = b % 11) < 2) { a[10] = 0; } else { a[10] = 11-x; }
        if ((cpf.charAt(9) != a[9]) || (cpf.charAt(10) != a[10])){
            erro +="Digito verificador com problema!";
        }
        if (erro.length > 0){
            // alert(erro);
            return false;
        }
        return true;
    }






    static phone(telefone) {

        // Tirar "_" da máscara
        telefone = telefone.replace(/_/g, '');

        // Validar tamanho da string:
        if(telefone.length != 14 && telefone.length != 15) {
            return false;
        }

        // Verificar caracteres: 
        if(telefone.substr(0,1) != '(' || telefone.substr(3,2) != ') ') {
            return false;
        }
        var ddd = telefone.substr(1,2);
        let prefixo, sufixo
        if(telefone.substr(9,1) == '-') {
            prefixo = telefone.substr(5,4);
            sufixo = telefone.substr(10);
        } else {
            prefixo = telefone.substr(5,5);
            sufixo = telefone.substr(11);
        }

        // Verificar se são numéricos: 
        if(isNaN(ddd) || isNaN(prefixo) || isNaN(sufixo)) {
            return false;
        }

        return true;

    }

}