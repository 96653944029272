import React, { useRef} from 'react'
import { useParams } from "react-router-dom"

import { FontAwesomeIcon }           from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import ListagemVideos       from '../components/ListagemVideos'
import ListagemMateriais    from '../components/ListagemMateriais'
import ListagemBibliografia from '../components/ListagemBibliografia'

import '../routes/Busca.css'




export default function Busca() {

    const [buscasConcluidas, setBuscasConcluidas]     = React.useState([]);
    const [showPreloaderBusca, setShowPreloaderBusca] = React.useState(true);
    const [itensEncontrados, setItensEncontrados]     = React.useState(false);

    const params = useParams();

    const palavraChave = (typeof(params.palavraChave) != 'undefined' ? params.palavraChave : '');

    const row = []
    row['programa']     = useRef(null)
    row['material']     = useRef(null)
    row['bibliografia'] = useRef(null)

    const enableTopic = (type, items) => {
        // Exibir row
        if(items.length > 0) {
            setItensEncontrados(true)
            row[type].current.style.display = 'block'
        }
        const temp = buscasConcluidas
        temp.push(type)
        setBuscasConcluidas(temp);
        if(temp.length == 3) { 
            setShowPreloaderBusca(false);
        }
    }


    const enablePrograma = (onLoadKey, items) => {
        enableTopic('programa', items)
    }
    const enableMaterial = (onLoadKey, items) => {
        enableTopic('material', items)
    }
    const enableBibliografia = (onLoadKey, items) => {
        enableTopic('bibliografia', items)
    }


    return (




        <>

            <div className="listagemContainer" style={{marginTop: '100px'}} >

                <div className="titulosCategorias pb-3">
                    { showPreloaderBusca && 
                        <>
                            <span style={{fontSize:'20px'}}>
                            <FontAwesomeIcon icon={faCircleNotch} className="fa-spin mr-2 mt-3" /> 
                            </span>
                            Buscando...
                        </>
                    }
                    {!showPreloaderBusca && !itensEncontrados && 
                        <>
                            <span className="divisorTitulos">
                            </span>
                            Não encontramos nada com o termo &quot;{palavraChave}&quot;
                        </>
                    }
                    {!showPreloaderBusca && itensEncontrados && 
                        <>
                            <span className="divisorTitulos">
                            </span>
                            Resultados da busca: &quot;{palavraChave}&quot;
                        </>
                    }
                </div>
            </div>


            <div ref={row['programa']} style={{display:'none'}} className="mt-2" >
                <ListagemVideos carousel={false} onLoadKey={0} palavraChave={palavraChave} onLoadCallback={enablePrograma} />
            </div>



            <div ref={row['material']} style={{display:'none'}} >

                <div className="buscaLinhaBase">
                </div>

                <div className="listagemContainer">
                    <div className="titulosCategorias pb-3">
                        <span className="divisorTitulos">
                        </span>
                        E-books
                    </div>
                </div>

                <ListagemMateriais palavraChave={palavraChave} onLoadCallback={enableMaterial} comCategoria={true} />

            </div>







            <div ref={row['bibliografia']} style={{display:'none'}} >

                <div className="buscaLinhaBase">
                </div>

                <div className="listagemContainer">
                    <div className="titulosCategorias pb-3">
                        <span className="divisorTitulos">
                        </span>
                        Bibliografia
                    </div>
                </div>

                <div  className="AbaListagemSobreSerie">
                    <div className="docenteAreaGeral">
                        <ListagemBibliografia palavraChave={palavraChave} onLoadCallback={enableBibliografia} comCategoria={true} />
                    </div>
                </div>

            </div>










        </>
    )
}