import React, { useRef }   from 'react'
import api                 from "../services/api";
import InputMask           from 'react-input-mask';
import { useOutletContext, useNavigate } from "react-router-dom";
import User                       from '../utils/User.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faInfoCircle }    from '@fortawesome/free-solid-svg-icons';

import PagePreloader from './PagePreloader.jsx'

import IsValid    from '../utils/IsValid.js';


export default function FormularioCadastro(props) {


    const setMenuUsuarioTimestamp = useOutletContext().setMenuUsuarioTimestamp;


    const inputSpecs = {
        'id': {
            scope: '_user', 
            mandadory: false,
        },
        'nome': {
            scope: '_user.userType',
            mandadory: true,
            exampleVal: 'Nilson Kiyoshi Sakamoto',
        },
        'email': {
            scope: '_user',
            mandadory: true,
            exampleVal: () => {
                return `${new Date().getTime()}@gamma3.com.br`
            },
            validation: () => {
                if(!IsValid.email(getUserAttr('email'))) { 
                    alert('O e-mail é inválido.')
                    inputRef['email'].current.select()
                    focusInvalidInput('email')
                    return false
                }
                return true
            }
        },

        'rg': {
            scope: '_user.userType',
            mandadory: true,
            exampleVal: '6.866.772-0',
        },
        'cpf_formatado': {
            scope: '_user.userType',
            mandadory: true,
            exampleVal: '031.094.809-64',
            validation: () => {
                if(!IsValid.cpf(getUserAttr('cpf_formatado')) && getUserAttr('cpf_formatado') != '') { 
                    alert('O CPF digitado é inválido.')
                    inputRef['cpf_formatado'].current.setSelection(0, 14)
                    return false
                }
                return true
            }
        },
        'data_nascimento_formatado': {
            scope: '_user.userType',
            mandadory: true,
            exampleVal: '12/06/1980',
            validation: () => {
                if(!IsValid.date(getUserAttr('data_nascimento_formatado'))) { 
                    alert('A data de nascimento digitada é inválida.')
                    inputRef['data_nascimento_formatado'].current.setSelection(0, 10)
                    return false
                }
                return true
            }
        },
        'sexo': {
            scope: '_user.userType',
            mandadory: false,
            exampleVal: 'm',
        },
        'escolaridade': {
            scope: '_user.userType',
            mandadory: false,
            exampleVal: 'superior',
        },
        'numero_formatado': {
            scope: '_user.userType.telefone',
            mandadory: true,
            exampleVal: '(41) 99191-8632',
            validation: () => {
                if(!IsValid.phone(getUserAttr('numero_formatado')) && getUserAttr('numero_formatado') != '') { 
                    alert('O telefone digitado é inválido.')
                    inputRef['numero_formatado'].current.setSelection(0, 9)
                    return false
                }
                return true
            }
        },
        'cep_formatado': {
            scope: '_user.userType.endereco',
            mandadory: true,
            exampleVal: '81070-190',
            validation: () => {
                if(!IsValid.cep(getUserAttr('cep_formatado'))) { 
                    alert('O CEP digitado é inválido.')
                    inputRef['cep_formatado'].current.setSelection(0, 9)
                    return false
                }
                return true
            }
        },
        'logradouro': {
            scope: '_user.userType.endereco',
            mandadory: true,
            exampleVal: 'Rua Itajubá',
        },
        'numero': {
            scope: '_user.userType.endereco',
            mandadory: false,
            exampleVal: '810',
        },
        'bairro': {
            scope: '_user.userType.endereco',
            mandadory: true,
            exampleVal: 'Portão',
        },
        'complemento': {
            scope: '_user.userType.endereco',
            mandadory: false,
            exampleVal: 'bloco 5, apto 406',
        },
        'estado': {
            scope: '_user.userType.endereco',
            mandadory: true,
            exampleVal: 'PR',
        },
        'cidade': {
            scope: '_user.userType.endereco',
            mandadory: true,
            exampleVal: 'Curitiba',
        },
    }

    if(props.showCupom) { 
        inputSpecs.cupom_codigo = {
            scope: 'cupom',
            mandadory: false,
            exampleVal: '',
        }
    }



    const getUserFromProps = () => {
        const tempUser = props._user
        if(!tempUser.userType.endereco) { 
            tempUser.userType.endereco = {}
        }
        return tempUser
    }

    /**
     * Instanciar ref dos inputs e selects
     **/ 
    const inputRef = []
    for(let name of Object.keys(inputSpecs)) {
        inputRef[name] = useRef(null)
    }

    const [ userChanged,               setUserChanged               ] = React.useState(0);
    const [ cidade,                    setCidade                    ] = React.useState([]);
    const [ escolaridade,              setEscolaridade              ] = React.useState([]);
    const [ escolaridadeKeys,          setEscolaridadeKeys          ] = React.useState([]);
    const [ showPreloaderForm,         setShowPreloaderForm         ] = React.useState(false);
    const [ showPreloaderEscolaridade, setShowPreloaderEscolaridade ] = React.useState(false);
    const [ showPreloaderCep,          setShowPreloaderCep          ] = React.useState(false);
    const [ showPreloaderCidade,       setShowPreloaderCidade       ] = React.useState(false);
    const [ _user,                     setUser                      ] = React.useState(getUserFromProps);
    const [ cupom,                     setCupom                     ] = React.useState(props.cupom);

    let objCupomVerificado = null
    const navigate = useNavigate()



    const siglas = ['AC','AL','AP','AM','BA','CE','DF','ES','GO','MA','MT','MS','MG','PA','PB','PR','PE','PI','RJ','RN','RS','RO','RR','SC','SP','SE','TO']
    const capitais = {
        'SE': 'Aracaju',
        'PA': 'Belém',
        'MG': 'Belo Horizonte',
        'RR': 'Boa Vista',
        'DF': 'Brasília',
        'MS': 'Campo Grande',
        'MT': 'Cuiabá',
        'PR': 'Curitiba',
        'SC': 'Florianópolis',
        'CE': 'Fortaleza',
        'GO': 'Goiânia',
        'PB': 'João Pessoa',
        'AP': 'Macapá',
        'AL': 'Maceió',
        'AM': 'Manaus',
        'RN': 'Natal',
        'TO': 'Palmas',
        'RS': 'Porto Alegre',
        'RO': 'Porto Velho',
        'PE': 'Recife',
        'AC': 'Rio Branco',
        'RJ': 'Rio de Janeiro',
        'BA': 'Salvador',
        'MA': 'São Luís',
        'SP': 'São Paulo',
        'PI': 'Teresina',
        'ES': 'Vitória'
    }


    const setUserAttr = (name, value) => {
        if(!value && typeof(value) != 'number') { 
            value = '""'
        } else if(typeof(value) != 'number') {
            value = '"'+value+'"'
        }
        eval(inputSpecs[name].scope + '.' + name + ' = ' + value + ';')
        if(inputSpecs[name].scope == 'cupom') { 
            setCupom(cupom)
        } else { 
            setUser(_user)
        }
    }

    const getUserAttr = (name) => {

        let value
        let obj
        eval(`obj = ${inputSpecs[name].scope};`);
        if(!obj) { 
            return "";
        }
        eval(`value = obj.${name};`)
        if(!value && typeof(value) != 'number') { 
            value = "";
        }
        return value
    }

    const buscarCidades = (estado, cidadeSelecionada=null) => {

        if(estado == 'escolha...') { 
            setCidade([])
            setUserAttr('cidade', null)
            return
        }

        setShowPreloaderCidade(true)
        api.get(`cidade/?estado=${estado}`)
        .then((response) => {

            setShowPreloaderCidade(false)

            if(response.data.status === 0) { 
                console.log('Erro ao buscar cidades')
                return
            }

            /**
             * Separar só os nomes
             **/ 
            const nomes = []
            for(const item of response.data.cidade) {
                nomes.push(item.nome)
            }
            setCidade(nomes)
            
            /**
             * Se não tiver cidade selecionada, seleciona a capital
             **/ 
            let _cid;
            if(response.data.cidade.includes(cidadeSelecionada)) { 
                _cid = cidadeSelecionada;
            } else { 
                _cid = capitais[estado]
            }
            inputRef['cidade'].current.value = _cid
            setUserAttr('cidade', _cid)
        })
        .catch((err) => {
            console.error("Erro ao buscar pela API: " + err);
        });
    }






    React.useEffect(() => {

        setUser(getUserFromProps())
        setUserChanged(userChanged ? 0 : 1)

    }, [props._user])



    React.useEffect(() => {

        /**
         * Inputs que não pegam o defaultValue
         **/ 
        if(inputRef['escolaridade'].current || inputRef['estado'].current || inputRef['sexo'].current) { 

            setTimeout(function() {
                if(inputRef['escolaridade'].current) { 
                    inputRef['escolaridade'].current.value = getUserAttr('escolaridade')
                }
                if(inputRef['estado'].current) { 
                    inputRef['estado'].current.value = getUserAttr('estado')
                }
                if(inputRef['sexo'].current) { 
                    inputRef['sexo'].current.value = getUserAttr('sexo')
                }
            }, 3000)
        }

        if(inputRef['numero_formatado'].current) { 
            inputRef['numero_formatado'].current.value = getUserAttr('numero_formatado')
        }
        if(inputRef['cep_formatado'].current) { 
            inputRef['cep_formatado'].current.value = getUserAttr('cep_formatado')
        }
        if(inputRef['cpf_formatado'].current) { 
            inputRef['cpf_formatado'].current.value = getUserAttr('cpf_formatado')
        }
        if(inputRef['data_nascimento_formatado'].current) { 
            inputRef['data_nascimento_formatado'].current.value = getUserAttr('data_nascimento_formatado')
        }

        // Buscar cidades do estado
        if(getUserAttr('estado')) { 
            buscarCidades(getUserAttr('estado'), getUserAttr('cidade'))
        }


    }, [userChanged])



    React.useEffect(() => {

        /**
         * Buscar opções de escolaridade
         **/ 
        if(inputRef['escolaridade'].current) { 
            setShowPreloaderEscolaridade(true)

            api
            .get(`escolaridade/`)
            .then((response) => {

                // Erro no ajax
                if(response.data.status === 0) { 
                    return
                }
                let escolaridade = response.data.escolaridade
                setEscolaridade(escolaridade)
                for(let key in escolaridade) {
                    escolaridadeKeys.push(key)
                }
                setEscolaridadeKeys(escolaridadeKeys)
                setShowPreloaderEscolaridade(false)
            })
            .catch((err) => {
                console.error("Erro ao buscar pela API: " + err);
            });
        }

    }, [])





    const onInputChange = (event) => {

        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        setUserAttr(name, value)

        if(typeof(props.setUser) == 'function') { 
            props.setUser(_user)
        }
    }




    const focusInvalidInput = (inputName) => {
        if(typeof(inputRef[inputName].current.focus) == 'function') { 
            inputRef[inputName].current.focus()
            inputRef[inputName].current.classList.add('nao-validado');
            setTimeout(() => {
                inputRef[inputName].current.classList.remove('nao-validado');
            }, 3000)
        }
    }




    const onSubmit = (event, formRef) => {

        event.preventDefault()

        /**
         * Todos inputs
         **/ 
        const inputsForm = [];
        for(let i=0; i<formRef.current.length; i++) {
            const name = formRef.current[i].getAttribute('name')
            if(name) { 
                inputsForm.push(name)
            }
        }

        /**
         * Campos obrigatorios
         **/ 
        let campoFaltando = null;
        for(let name in inputSpecs) {

            // Faz parte do form?
            if(!inputsForm.includes(name)) { 
                continue;
            }
            // É obrigatorio?
            if(!inputSpecs[name].mandadory) { 
                continue;
            }
            // Está em branco?
            const value = getUserAttr(name)
            if(!value) { 
                campoFaltando = name;
            } else if(value.trim() == '') { 
                campoFaltando = name;
            } else if(value == 'escolha...' && name == 'estado') {
                campoFaltando = name;
            }
            if(campoFaltando) { 
                break;
            }
        }
        console.log(campoFaltando);
        if(campoFaltando) { 
            alert('Há um dado obrigatório não preenchido.')
            focusInvalidInput(campoFaltando)
            return
        }

        /**
         * Outras validações
         **/ 
        for(let name in inputSpecs) {

            // Faz parte do form?
            if(!inputsForm.includes(name)) { 
                continue;
            }
            // Tem validação? 
            if(typeof(inputSpecs[name].validation) != 'function') { 
                continue
            }
            // Validar
            if(!inputSpecs[name].validation()) { 
                return
            }
        }



        /**
         * Verificar código do cupom
         **/ 
        // if(props.showCupom) {

        //     const cupom_codigo = inputRef['cupom_codigo'].current.value.trim()

        //     if(cupom_codigo != '') { 
        //         let url = `cupom/codigo/`+cupom_codigo
        //         if(props.site_slug) { 
        //             url += '/?site_slug='.props.site_slug
        //         } else if(props.associacao) { 
        //             url += '/?associacao='.props.associacao
        //         }
        //         api
        //         .get(url)
        //         .then((response) => {

        //             // Erro no ajax
        //             if(response.data.status === 0) { 
        //                 return
        //             }
        //             // Código inválido
        //             if(!response.data.cupom) { 
        //                 alert("O código de cupom que você digitou é inválido ou já expirou. ");
        //                 return;
        //             }
        //             // Funcao setCupom do componente pai
        //             objCupomVerificado = response.data.cupom
        //             if(typeof(props.setCupom) == 'function') { 
        //                 setCupom(objCupomVerificado)
        //             }

        //             // Salvar formulário
        //             submitData(inputsForm)
        //         })


        //         return
        //     }
        // }


        /**
         * Salvar
         **/ 
        submitData(inputsForm)
        
    }




    const submitData = (inputsForm) => {

        /**
        * Enviar dados
        **/ 
        setShowPreloaderForm(true)

        var params = new URLSearchParams()
        for(let name of inputsForm) {
            const val = getUserAttr(name)
            params.append(name, val)
        }
        let url = `_user/`
        if(props.userToken) { 
            url += `?key=${props.userToken}`
        }
        api
        .post(url, params)
        .then(res => {
            if(res.data.status === 0 || typeof(res.data.id) == 'undefined') { 
                alert('Erro ao salvar dados')
                window.location.reload()
            } else if(res.data.invalid_attribute) { 
                alert(`Já existe outro usuário com este ${res.data.invalid_attribute.replace('email', 'e-mail').replace('cpf', 'CPF') }.\nInsira outro e salve novamente.`)
            } else {

                if(inputsForm.includes('nome') && props.userToken) { 
                    /**
                     * Alterar nome no menu
                     **/ 
                    const user = User.getUser()
                    user.name = getUserAttr('nome')
                    User.setUser(user)
                    setMenuUsuarioTimestamp(new Date().getTime())

                    /**
                     * Alterar nome na página
                     **/ 
                    if(typeof(props.setNome) == 'function') { 
                        props.setNome(getUserAttr('nome'))
                    }
                }

                const addedAddress = inputsForm.includes('logradouro') && User.getAttr('need_address')
                if(addedAddress) { 
                    User.setUser({need_address:false});
                }
                
                setShowPreloaderForm(false)

                /**
                 * Callback
                 **/ 
                if(typeof(props.callback) == 'function') { 
                    setUserAttr('id', res.data.id)
                    props.callback(objCupomVerificado)
                } else { 
                    alert('Dados atualizados.')
                    if(addedAddress) { 
                        navigate('/');
                    }
                }
            }
        })
    }





    /**
     * Buscar endereço do CEP
     **/ 
    const onChangeCep = (event) => {

        if(IsValid.cep(event.target.value)) { 

            setShowPreloaderCep(true)

            const url = `https://brasilapi.com.br/api/cep/v2/${event.target.value.replace('-', '')}`

            api.get(url, { validateStatus: false })
            .then((result) => {

                setShowPreloaderCep(false)

                /**
                 * Erro ou cep não encontrado
                 **/ 
                if(result.status == 404) { 
                    alert('O CEP não foi encontrado. \nVerifique se foi digitado corretamente.')
                    inputRef['cep_formatado'].current.select()
                    return
                }

                /**
                 * Preencher inputs
                 **/ 
                inputRef['logradouro'].current.value    = result.data.street
                inputRef['bairro'].current.value        = result.data.neighborhood
                inputRef['cep_formatado'].current.value = result.data.cep.substring(0,5)+'-'+result.data.cep.substring(5)
                inputRef['complemento'].current.value   = ''
                inputRef['estado'].current.value        = result.data.state

                setUserAttr('logradouro',    inputRef['logradouro']   .current.value)
                setUserAttr('bairro',        inputRef['bairro']       .current.value)
                setUserAttr('cep_formatado', inputRef['cep_formatado'].current.value)
                setUserAttr('complemento',   inputRef['complemento']  .current.value)
                setUserAttr('estado',        inputRef['estado']       .current.value)

                /**
                 * Pedir pra preencher número e complemento
                 **/
                document.getElementById('preencha_numero').style.opacity = '1'
                setTimeout(() => document.getElementById('preencha_numero').style.opacity = '0', 4000)

                focusInvalidInput('numero')
                focusInvalidInput('complemento')
                inputRef['numero'].current.select()

                /**
                 * Buscar cidades (e trazer selecionado o correto)
                 **/ 
                buscarCidades(result.data.state, result.data.city)

            })
            .catch((err) => {
                console.error("Erro ao buscar pela API: " + err)
            })
        }





        onInputChange(event)




    }





    const onChangeEstado = (event) => {
        buscarCidades(event.target.value, getUserAttr('cidade'))
        onInputChange(event)
    }






    const clickVoltar = () => {
        props.voltar(cupom)
    }






    const autopreencher = () => {
        const attrs = {};
        for(let name in inputSpecs) {
            if(!inputRef[name].current) {
                continue
            }
            if(inputRef[name].current.value != '') {
                continue
            }
            if(typeof(inputSpecs[name].exampleVal) == 'function') { 
                attrs[name] = inputSpecs[name].exampleVal()
            } else if(inputSpecs[name].exampleVal) { 
                attrs[name] = inputSpecs[name].exampleVal
            }
        }
        for(let name in attrs) {
            if(name == 'cidade') { 
                continue
            }
            document.querySelector(`[name='${name}']`).value = attrs[name]
            onInputChange({target:document.querySelector(`[name='${name}']`)});
            setUserAttr(name, attrs[name])
            setTimeout(() => {
                document.querySelector(`[name='${name}']`).value = attrs[name]
            }, 500)

        }

        if(attrs.estado) { 
            buscarCidades(attrs.estado, attrs.cidade)
        }

    }




    let formHtml


    if(props.page == 'MeusDados') {



        /**
         * Página MeusDados ==============================================================================================================
         **/ 



        const refFormDadosPrincipal = useRef(null)
        const refFormEndereco       = useRef(null)


        formHtml = 

            <div className="areaDados">
                <form ref={refFormDadosPrincipal} className="dadosConfirmoArea">

                    <h4>
                        Informações principais
                    </h4>

                    <div className="cadastroContorno">
                        <div className="cadastroBox2">
                            <div className="row">                                  
                                <div className="col-12 col-sm-6">
                                    <label className="cadastroLabels">
                                        Nome Completo
                                    </label>
                                    <input ref={inputRef['nome']} name="nome" placeholder="Seu nome" type="text" className="CadastroPreto CadastroCampoNome obrigatorio" defaultValue={getUserAttr('nome')} onChange={onInputChange} />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label className="cadastroLabels">
                                        E-mail
                                    </label>
                                    <input ref={inputRef['email']} name="email" type="text" className="CadastroPreto CadastroCampoEmail obrigatorio inputEmail" defaultValue={getUserAttr('email')} onChange={onInputChange} readOnly="1" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label className="cadastroLabels">
                                        Escolaridade
                                    </label>

                                    {showPreloaderEscolaridade && 
                                        <div className="m-2 text-info">
                                            <FontAwesomeIcon icon={faCircleNotch} className="mr-1 fa-spin" /> 
                                            aguarde...
                                        </div>
                                    }

                                    {!showPreloaderEscolaridade && 
                                        <select ref={inputRef['escolaridade']} defaultValue={getUserAttr('escolaridade')} name="escolaridade" id="escolaridade" className="CadastroPreto CadastroCampoBairro" onChange={onInputChange} >
                                            <option value="">
                                                não informado
                                            </option>
                                            {escolaridadeKeys.map((value, key) => {
                                                return (
                                                    <option value={value} key={key} >
                                                        {escolaridade[value]}
                                                    </option>
                                                    )
                                            })}
                                        </select>
                                    }
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label className="cadastroLabels">
                                        Telefone
                                    </label>
                                    <InputMask ref={inputRef['numero_formatado']} mask="(99) 99999-9999" defaultValue={getUserAttr('numero_formatado')} name="numero_formatado" type="text" className="CadastroPreto CadastroCampoComplemento obrigatorio" id="inputfone" onChange={onInputChange} />
                                </div>
                                <div className="col-12 col-sm-4">
                                    <label className="cadastroLabels">
                                        CPF
                                    </label>
                                    <InputMask ref={inputRef['cpf_formatado']} name="cpf_formatado" mask="999.999.999-99" className="CadastroPreto CadastroCampoBairro obrigatorio" maskChar="_" defaultValue={getUserAttr('cpf_formatado')} onChange={onInputChange} />
                                </div>
                                <div className="col-6 col-sm-4">
                                    <label className="cadastroLabels">
                                        Nasc
                                    </label>
                                    <InputMask ref={inputRef.data_nascimento_formatado} mask="99/99/9999" defaultValue={getUserAttr('data_nascimento_formatado')} name="data_nascimento_formatado" className="CadastroPreto CadastroCampoComplemento obrigatorio" onChange={onInputChange} />
                                </div>
                                <div className="col-6 col-sm-4">
                                    <label className="cadastroLabels">
                                        Sexo
                                    </label>
                                    <select ref={inputRef['sexo']} defaultValue={getUserAttr('sexo')} name="sexo" className="CadastroPreto CadastroCampoComplemento" onChange={onInputChange} >
                                        <option value="">
                                            não informado
                                        </option>
                                        <option value="m">
                                            masculino
                                        </option>
                                        <option value="f">
                                            feminino
                                        </option>
                                        <option value="n">
                                            não-binário
                                        </option>
                                    </select>
                                </div>                 
                            </div>                                  
                        </div>
                    </div>
                    <div style={{textAlign: 'right'}}>
                        <button className="btn btn-info meusDadosBotao mt-2" type="submit" onClick={event => { onSubmit(event, refFormDadosPrincipal) }}>
                            salvar alterações
                        </button>
                    </div>
                </form>


                <form ref={refFormEndereco} className="dadosConfirmoArea dadosConfirmoMargem">

                    <h4>
                        Endereço
                    </h4>

                    <div className="cadastroContorno">
                        <span id="preencha_numero" className="preencha_numero bg-light ml-2 p-1 rounded text-dark">
                            <FontAwesomeIcon icon={faInfoCircle} className="mr-2" /> 
                            Preencha o número e complemento
                        </span>
                        <div className="cadastroBox2">
                            <div className="row">                                  
                                <div className="col-12 col-sm-4 col-md-3">
                                    <label className="cadastroLabels">
                                        CEP
                                    </label>
                                    <InputMask ref={inputRef['cep_formatado']} mask="99999-999" name="cep_formatado" defaultValue={getUserAttr('cep_formatado')} type="text" className="CadastroPreto CadastroCampoNome inputCep obrigatorio" onChange={onChangeCep} indice="0" />
                                </div>
                                
                                {showPreloaderCep && 
                                    <>
                                        <div className="col-12 col-sm-8 col-md-6">
                                            <div className="mt-5">
                                                <FontAwesomeIcon icon={faCircleNotch} className="fa-spin mr-2" />
                                                consultando CEP...
                                            </div>
                                        </div>
                                        <div className="col-3 col-sm-3 col-md-3">
                                            <div style={{height:'78px'}}>
                                            </div>
                                        </div>
                                        <div className="col-9 col-sm-9 col-md-6">
                                            <div style={{height:'78px'}}>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div style={{height:'78px'}}>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-3">
                                            <div style={{height:'78px'}}>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-9">
                                            <div style={{height:'78px'}}>
                                            </div>
                                        </div>
                                    </>
                                }
                                


                                { !showPreloaderCep && 
                                    <>
                                        <div className="col-12 col-sm-8 col-md-6">
                                            <label className="cadastroLabels">
                                                Logradouro
                                            </label>
                                            <input ref={inputRef['logradouro']} name="logradouro" defaultValue={getUserAttr('logradouro')} type="text" className="CadastroPreto CadastroCampoNome logradouro obrigatorio" onChange={onInputChange}/>
                                        </div>
                                        <div className="col-3 col-sm-3 col-md-3">
                                            <label className="cadastroLabels">
                                                Número
                                            </label>
                                            <input ref={inputRef['numero']} name="numero" defaultValue={getUserAttr('numero')} type="text" className="CadastroPreto CadastroCampoBairro numero" onChange={onInputChange} />
                                        </div>
                                        <div className="col-9 col-sm-9 col-md-6">
                                            <label className="cadastroLabels">
                                                Complemento
                                            </label>
                                            <input ref={inputRef['complemento']} name="complemento" defaultValue={getUserAttr('complemento')} type="text" className="CadastroPreto CadastroCampoComplemento complemento" onChange={onInputChange} />
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <label className="cadastroLabels">
                                                Bairro
                                            </label>
                                            <input ref={inputRef['bairro']} name="bairro" defaultValue={getUserAttr('bairro')} type="text" className="CadastroPreto CadastroCampoBairro bairro" onChange={onInputChange} />
                                        </div>
                                        <div className="col-6 col-md-3">
                                            <label className="cadastroLabels">
                                                Estado
                                            </label>
                                            <select ref={inputRef['estado']} name="estado" className="CadastroPreto CadastroCampoComplemento obrigatorio" defaultValue={getUserAttr('estado')} onChange={(event) => { onChangeEstado(event); onInputChange }} >
                                                <option>escolha...</option>
                                                {siglas.map((estado, i) => 
                                                    <option value={estado} key={i}>
                                                        {estado}
                                                    </option>
                                                )}
                                            </select>
                                        </div>
                                        <div className="col-6 col-md-9">
                                            <label className="cadastroLabels">
                                                Cidade
                                            </label>
                                            {showPreloaderCidade && 
                                                <div className="m-2 text-info">
                                                    <FontAwesomeIcon icon={faCircleNotch} className="mr-1 fa-spin" /> 
                                                    carregando cidades...
                                                </div>
                                            }
                                            {!showPreloaderCidade && 
                                                <select ref={inputRef['cidade']} name="cidade" className="CadastroPreto CadastroCampoComplemento cidade obrigatorio" defaultValue={getUserAttr('cidade')} onChange={onInputChange} >
                                                    {cidade.map((cidade, i) => 
                                                        <option value={cidade} key={i}>
                                                            {cidade}
                                                        </option>
                                                    )}
                                                </select>
                                            }
                                        </div>
                                    </>
                                }

                            </div>                                  
                        </div>
                    </div>
                    <div style={{textAlign: 'right'}}>
                        <button className="btn btn-info meusDadosBotao mt-2" type="submit" onClick={event => { onSubmit(event, refFormEndereco) }}>
                            salvar alterações
                        </button>
                    </div>
                </form>


            </div>




    } else if(props.page == 'CadastroComplemento') {


        const refForm = useRef(null)

        formHtml = 

            <div className="cadastroConteudoGeral">
                <div className="cadastroArea">
                    <div className="cadastroCamposTopo">
                        <h2>Cadastro | <span className="cadastroSubtitulo">Informações complementares</span></h2>
                        <div className="cadastroAviso">Solicitamos o endereço para emissão da nota fiscal</div>
                    </div>
                    <form ref={refForm} onSubmit={event => { onSubmit(event, refForm) }}>

                        <input ref={inputRef['id']}               name="id"               defaultValue={getUserAttr('id')}               type="hidden" />
                        <input ref={inputRef['nome']}             name="nome"             defaultValue={getUserAttr('nome')}             type="hidden" />
                        <input ref={inputRef['email']}            name="email"            defaultValue={getUserAttr('email')}            type="hidden" />
                        <input ref={inputRef['numero_formatado']} name="numero_formatado" defaultValue={getUserAttr('numero_formatado')} type="hidden" />

                        <div className="cadastroArea2">
                            <div className="cadastroContorno">
                                <div className="cadastroBox2">

                                    <div className="cadastroMarcadores">
                                        <div className="cadastroMarcadoresAtivo">
                                        </div>
                                        <div className="cadastroMarcadoresAtivo">
                                        </div>
                                        <div className="cadastroMarcadoresInativo">
                                        </div>
                                    </div>

                                    <div className="cadastroCampos" style={{alignItems: 'flex-start'}}>

                                        <PagePreloader show={showPreloaderForm} />

                                        <div className="cadastroColuna1">

                                            <div className="col-12">
                                                <label className="cadastroLabels">CPF</label>
                                                <InputMask ref={inputRef['cpf_formatado']} name="cpf_formatado" mask="999.999.999-99" placeholder="000.000.000-00" className="CadastroCampoCpf" maskChar="_" defaultValue={getUserAttr('cpf_formatado')} onChange={onInputChange} />
                                            </div>
                                            <div className="col-12">
                                                <label className="cadastroLabels">Nasc.</label>
                                                <InputMask ref={inputRef.data_nascimento_formatado} mask="99/99/9999" placeholder="00/00/0000" defaultValue={getUserAttr('data_nascimento_formatado')} name="data_nascimento_formatado" className="CadastroCampoNascimento" onChange={onInputChange} />
                                            </div>

                                            <div className="col-12 list-item d-none d-lg-block">
                                                <br /><br /><br />
                                                <label className="cadastroLabels"></label>
                                                <a type="button" className="btn CadastroCampoBotao" onClick={clickVoltar}>
                                                    voltar
                                                </a>
                                            </div>

                                        </div> 



                                        <div className="cadastroColuna2">
                                            <div className="row">
                                                <div className="col-12 col-sm-4 col-md-2">
                                                    <label className="cadastroLabels">CEP</label>
                                                    <InputMask ref={inputRef['cep_formatado']} mask="99999-999" name="cep_formatado" defaultValue={getUserAttr('cep_formatado')} type="text" className="CadastroCampoCep" onChange={onChangeCep} indice="0" placeholder="00.000-000" />
                                                </div>

                                                {showPreloaderCep && 
                                                    <div className="col-12 col-sm-8 col-md-10">
                                                        <div className="pt-5 text-info">
                                                            <FontAwesomeIcon icon={faCircleNotch} className="mr-1 fa-spin" /> 
                                                            consultando CEP...
                                                        </div>
                                                    </div>
                                                }



                                                {!showPreloaderCep && 

                                                    <>
                                                        <div className="col-12 col-sm-8 col-md-8">
                                                            <label className="cadastroLabels">Logradouro</label>
                                                            <input ref={inputRef['logradouro']} name="logradouro" defaultValue={getUserAttr('logradouro')} type="text" className="CadastroCampoRua" onChange={onInputChange} placeholder="Rua João da Silva" />
                                                        </div>

                                                        <span id="preencha_numero" ref={inputRef['divPreenchaNumero']} className="preencha_numero_cadastro bg-warning rounded text-dark">
                                                            <FontAwesomeIcon icon={faInfoCircle} className="mr-2" /> 
                                                            Preencha o número e complemento
                                                        </span>


                                                        <div className="col-12 col-sm-3 col-md-2">
                                                            <label className="cadastroLabels">Número</label>
                                                            <input ref={inputRef['numero']} name="numero" defaultValue={getUserAttr('numero')} type="text" className="CadastroCampoNumero" onChange={onInputChange} placeholder="00" />
                                                        </div>
                                                    </>
                                                }


                                                <div className="col-12 col-sm-9 col-md-6">
                                                    <label className="cadastroLabels">Complemento</label>
                                                    <input ref={inputRef['complemento']} name="complemento" defaultValue={getUserAttr('complemento')} type="text" className="CadastroCampoComplemento" onChange={onInputChange} placeholder="apto 101" />
                                                </div>


                                                <div className="col-12 col-sm-6 col-md-6">
                                                    <label className="cadastroLabels">Bairro</label>
                                                    <input ref={inputRef['bairro']} name="bairro" defaultValue={getUserAttr('bairro')} type="text" className="CadastroCampoBairro" onChange={onInputChange} placeholder="Centro" />
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-3">
                                                    <label className="cadastroLabels">Estado</label>
                                                    <select ref={inputRef['estado']} name="estado" className="CadastroCampoEstado" defaultValue={getUserAttr('estado')} onChange={(event) => { onChangeEstado(event); onInputChange }} >
                                                        <option>
                                                            escolha...
                                                        </option>
                                                        {siglas.map((estado, i) => 
                                                            <option value={estado} key={i}>
                                                                {estado}
                                                            </option>
                                                        )}
                                                    </select>
                                                </div>

                                                <div className="col-12 col-md-9 col-lg-6">
                                                    <label className="cadastroLabels">Cidade</label>
                                                    {showPreloaderCidade && 
                                                        <div className="mx-2 mb-1 mt-2 text-info">
                                                            <FontAwesomeIcon icon={faCircleNotch} className="mr-1 fa-spin" /> 
                                                            carreg. cidades...
                                                        </div>
                                                    }

                                                    {!showPreloaderCidade && 
                                                        <select ref={inputRef['cidade']} name="cidade" className="CadastroCampoCidade" defaultValue={getUserAttr('cidade')} onChange={onInputChange} >
                                                            {cidade.map((cidade, i) => 
                                                                <option value={cidade} key={i}>
                                                                    {cidade}
                                                                </option>
                                                            )}
                                                        </select>
                                                    }
                                                </div>

                                                { !props.showCupom && 

                                                    <>
                                                        <div className="col-12 col-md-6 list-item d-inline d-lg-none">
                                                            <label className="cadastroLabels"></label>
                                                            <a type="button" className="btn CadastroCampoBotao" onClick={clickVoltar}>
                                                                voltar
                                                            </a>
                                                        </div>

                                                        <div className="col-12 col-md-6 col-lg-3">
                                                            <label className="cadastroLabels"></label>
                                                            <button type="submit" className="btn CadastroCampoBotao2" style={{width: '100%'}} >
                                                                continuar
                                                            </button>
                                                        </div>
                                                    </>
                                                }

                                                { props.showCupom && 

                                                    <div className="col-12 col-lg-3">
                                                        <label className="cadastroLabels">
                                                            Cupom de desconto
                                                        </label>
                                                        <input placeholder="(se houver)" ref={inputRef['cupom_codigo']} name="cupom_codigo" defaultValue={getUserAttr('cupom_codigo')} type="text" className="CadastroCampoBairro" onChange={onInputChange} />
                                                    </div>

                                                }

                                            </div>


                                            { props.showCupom && 

                                                <div className="row justify-content-between">

                                                    <div className="d-none d-lg-block">
                                                    </div>

                                                    <div className="col-12 col-md-6 list-item d-inline d-lg-none">
                                                        <label className="cadastroLabels"></label>
                                                        <a type="button" className="btn CadastroCampoBotao" onClick={clickVoltar}>
                                                            voltar
                                                        </a>
                                                    </div>

                                                    <div className="col-12 col-md-6 col-lg-3">
                                                        <label className="cadastroLabels"></label>
                                                        <button type="submit" className="btn CadastroCampoBotao2" style={{width: '100%'}} >
                                                            continuar
                                                        </button>
                                                    </div>
                                                </div>
                                            }

                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                { props.debugMode && 
                    <>
                        <button className="btn btn-xs btn-light mt-2" type="button" onClick={autopreencher}>
                        autopreencher
                        </button>
                    </>
                }

            </div>


    }







  return (
    <>

        { props.debugMode && 
            <>
                <button className="btn btn-xs btn-light float-right mb-2" type="button" onClick={autopreencher}>
                    autopreencher
                </button>
            </>
        }




        { formHtml }










        <PagePreloader show={showPreloaderForm} />

    </>
  )


}