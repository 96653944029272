import React         from 'react'
import { useParams, useSearchParams } from "react-router-dom"
import Cookies               from 'universal-cookie';

import PagePreloader from '../components/PagePreloader.jsx'
import api           from "../services/api";
import User from '../utils/User.js';


export default function Login() {

    const urlPathParams = useParams();
    const [searchParams] = useSearchParams();

    React.useEffect(() => {

        /**
         * Foi dado token
         * Seta para extender token (pelo App.js)
         **/ 
        if(urlPathParams.token) { 
            User.setUser({token:urlPathParams.token})
            const cookies = new Cookies();
            cookies.remove('token_extended', { path: '/' })
            document.location.href = searchParams.get('callback_url')
            return
        }


        /**
         * Foi dado reset_token, então loga pelo reset_token
         **/ 
        var postParams = new URLSearchParams();
        postParams.append('reset_token', urlPathParams.reset_token);

        api.post("login/", postParams,
        {
           withCredentials: true
        })
        .then((response) => {

            if (!response.data) return null;
            if (response.data.status === 0) return null;
            if(response.data.authorized) { 

                User.setUser(response.data)
                let url
                if(searchParams.get('callback_url')) { 
                    url = searchParams.get('callback_url')
                } else { 
                    url = '/'
                }
                document.location.href = url
            } else { 
                alert('Erro ao tentar logar')
            }
        })
        .catch((err) => {
            alert('Houve algum erro ao tentar logar.')
            console.error("Erro ao buscar pela API: " + err);
        });
    }, [urlPathParams.reset_token]);


    return (
        <>
            <PagePreloader show={true} />
        </>
    )
}


