import React, { useState } from 'react';
import Modal               from 'react-bootstrap/Modal';

import FormLogin           from './FormLogin.jsx';
import api                 from "../services/api";

import './ModalAssinante.css';

export default function ModalAssinante(props) {

    const [installmentValue,            setInstallmentValue            ] = useState();
    const [showEsqueciSenha, setShowEsqueciSenha ] = useState(false);

    const installmentQuantity = 12;

    /**
    * Ver preço da assinatura
    **/ 
    React.useEffect(() => {

        api.get('preco/')
          .then(res => {

            if(!res.data)             return null;
            if(res.data.status === 0) return null;
            if(!res.data.preco)       return null

            setInstallmentValue(res.data.preco / installmentQuantity)

          })
    }, []);


    const openModalCadastro = () => {
        props.toggleModalAssinante(false)
        props.toggleModalCadastro(true, {free: (props.free ? true : false)})
    }

    const title = props.free ? 'Já tenho cadastro' : 'Já sou assinante'

    return (
    <>

        <Modal show={props.show} onHide={() => props.toggleModalAssinante(false)} backdrop={true} >


            <div className="modalContorno" role="document">
                <div className="modalInscricaoGeral modalBox2">

                    <div className="row align-items-stretch">
                        <div className="col-12 col-md-6">
                            <div className="cadastroArea2">
                                <div className="row" style={{display:'flex', flexDirection: 'column', padding: '20px', paddingBottom: '0px'}}> 
                                    <h2>
                                        { showEsqueciSenha && 
                                            <>Esqueci minha senha</>
                                        }
                                        { !showEsqueciSenha && 
                                            <>{title}</>
                                        }
                                        <span className="cadastroSubtitulo">
                                        </span>
                                    </h2>
                                    <FormLogin setShowEsqueciSenha={setShowEsqueciSenha} onLogin={props.onLogin} />
                                </div>        
                            </div>
                        </div>
                        <div className="col-12 col-md-6 align-items-stretch d-flex">
                            { installmentValue && !props.free && 
                                <div className="modalInscricaoInscrever modalInscricaoBorda">
                                    <h2>Assine agora!</h2>
                                    <div className="planosLandingContorno">
                                        <div className="planosLandingBox" style={{minHeight: 0}}>
                                            <div className="planosLandingTitulo">
                                                <strong>
                                                    Assinatura IDD Plus
                                                </strong>
                                            </div>
                                            <div className="planosLandingPreco">
                                                <div className="planosLandingMoeda">
                                                    Em {installmentQuantity}x de R$
                                                </div>
                                                <div className="planosLandingValor">
                                                    {installmentValue.toPriceFormat()}
                                                </div>
                                                <div className="planosLandingPeriodo">
                                                    /mês
                                                </div>
                                            </div>
                                            <button className="planosLandingBotao2" onClick={openModalCadastro}>
                                                Assinar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }

                            { props.free && 
                                <div className="modalInscricaoInscrever modalInscricaoBorda flex-grow-1 justify-content-start">
                                    <h2>Não tenho cadastro</h2>
                                    <div style={{minHeight: 0}}>
                                        O cadastro é gratuito e lhe dará acesso a este conteúdo.
                                        <br />
                                        <button className="btn btn-success mt-3 btn-block py-3" onClick={openModalCadastro}>
                                            <strong>
                                                Quero me cadastrar!
                                            </strong>
                                        </button>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>



                </div>
            </div>

        </Modal>


    </>
  );
}