
export default class Docente {


  static getOcupacaoLocalidade = (docente) => {
    let ocupacao_localidade = '';
    if(docente.empresa) { 
      for(const e in docente.empresa) {
        if(e > 0) { 
          ocupacao_localidade += ' / ';
        }
        ocupacao_localidade += docente.empresa[e].nome;
        if(docente.empresa[e].estado) { 
            ocupacao_localidade += ' - ' + docente.empresa[e].estado;
        }
      }
      if(docente.pais) { 
          if(docente.empresa) { 
              ocupacao_localidade += ' - ';
          }
          ocupacao_localidade += docente.pais;
      }
    }
    return ocupacao_localidade;
  };

}
