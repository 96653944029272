import React                            from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {getEnviroment}                    from '../utils/Utils.js'

import App                   from '../App';
import Home                  from "./Home.jsx"
import Error404              from "./Error404.jsx"
import Lancamentos           from "./Lancamentos.jsx"
import Descubra              from "./Descubra.jsx"
import Busca                 from "./Busca.jsx"
import Detalhes              from "./Detalhes.jsx"
import Quiz                  from "./Quiz.jsx"
import ResultadoQuiz         from "./ResultadoQuiz.jsx"
import Certificado           from "./Certificado.jsx"
import MeusDados             from "./MeusDados.jsx"
import MeusPagamentos        from "./MeusPagamentos.jsx"
import MeusCartoes           from "./MeusCartoes.jsx"
import AdicionarCartao       from "./AdicionarCartao.jsx"
import MeusCertificados      from "./MeusCertificados.jsx"
import Canal                 from "./Canal.jsx"
import Ajuda                 from "./Ajuda.jsx"
import PoliticaDePrivacidade from "./PoliticaDePrivacidade.jsx"
import Login                 from "./Login.jsx"
import HomeLogin             from "./HomeLogin.jsx"
// import MinhaLista            from "./MinhaLista.jsx"
// import Cadastro              from "./Cadastro.jsx"
// import CadastroConfirmacao   from "./CadastroConfirmacao.jsx"
// import Loja               from "./Loja.jsx"
// import MinhasConquistas   from "./MinhasConquistas.jsx"
// import AssinarCanal       from "./AssinarCanal.jsx"

export default function AppRoutes() {

    const basename = (getEnviroment() == 'test' ? '/iddplus/' : '/')

    return (
    <>
        <BrowserRouter basename={basename}>
            <Routes>
                <Route path="/" element={<App />} >
                    <Route index                                            element={<Home />}                  />
                    <Route path="lancamentos"                               element={<Lancamentos />}           />
                    <Route path="descubra/"                                 element={<Descubra />}              />
                    <Route path="detalhes/:site_slug"                       element={<Detalhes />}              />
                    <Route path="quiz/:site_slug/:submodulo"                element={<Quiz />}                  />
                    <Route path="resultado-quiz/:submodulo"                 element={<ResultadoQuiz />}         />
                    <Route path="busca/"                                    element={<Busca />}                 />
                    <Route path="busca/:palavraChave"                       element={<Busca />}                 />

                    <Route path="meus-dados"                                element={<MeusDados />}             />
                    <Route path="meus-certificados"                         element={<MeusCertificados />}      />
                    <Route path="certificado/:certificadoId"                element={<Certificado />}           />
                    <Route path="meus-pagamentos"                           element={<MeusPagamentos />}        />
                    <Route path="meus-cartoes"                              element={<MeusCartoes />}           />
                    <Route path="meus-cartoes/adicionar"                    element={<AdicionarCartao />}       />
                    <Route path="canal/:slug"                               element={<Canal />}                 />

                    <Route path="ajuda/"                                    element={<Ajuda />}                 />
                    <Route path="politica-de-privacidade/"                  element={<PoliticaDePrivacidade />} />

                    <Route path="login/"                                    element={<HomeLogin />}             />
                    <Route path="login/:reset_token"                        element={<Login />}                 />
                    <Route path="login/token/:token"                        element={<Login />}                 />

                    {/*<Route path="minha-lista"                               element={<MinhaLista />}            />*/}
                    {/*<Route path="loja"                                   element={<Loja />}             />*/}
                    {/*<Route path="minhas-conquistas"                      element={<MinhasConquistas />} />*/}

                    <Route path="*"                                         element={<Error404 />}              />
                </Route>
            </Routes>
        </BrowserRouter>
    </>
    )
}


