import Cookies from 'universal-cookie';
import { getRootPath } from '../utils/Utils.js'

export default class User {


    static getAttr = attrName => {
        const UserData = localStorage.getItem('UserData');
        if(UserData == 'undefined' || UserData === null) { 
            return null
        }
        const obj = JSON.parse(UserData);
        return obj[attrName]
    };


    static getUser = () => {
        return JSON.parse(localStorage.getItem('UserData'))
    }

    static setUser = (user) => {
        let userObj = JSON.parse(localStorage.getItem('UserData'));
        if(!userObj) { 
            userObj = {}
        }
        if(user.id) { 
            userObj.id = user.id
        }
        if(user.name) { 
            userObj.name = user.name
        }
        if(user.token) { 
            userObj.token = user.token
        }
        if(user.url_imagem) { 
            userObj.url_imagem = user.url_imagem
        }
        if(typeof(user.need_address) != 'undefined') { 
            userObj.need_address = user.need_address
        }
        localStorage.setItem('UserData', JSON.stringify(userObj));
    }


    static getObjectForState(state, debugMode, email, nome, numero_formatado) {

        if(!state) { 
            state = {}
        }
        if(!state._user) { 
            state._user = {}
        }
        if(!state._user.userType) { 
            state._user.userType = {}
        }
        if(!state._user.userType.telefone) { 
            state._user.userType.telefone = {}
        }
        if(debugMode) { 
            state.debugMode = debugMode
        }
        if(email) { 
            state._user.email = email
        }
        if(nome) { 
            state._user.userType.nome = nome
        }
        if(numero_formatado) { 
            state._user.userType.telefone.numero_formatado = numero_formatado
        }
        return state

    }



    static logout = (without_redirect) => {

        localStorage.removeItem('UserData')
        const cookies = new Cookies();
        cookies.remove('token_extended', { path: '/' });
        
        if(!without_redirect) { 
            document.location.href = '/';
        }
    }


    static blockIfNonLogged = () => {
        if(!this.getAttr('token')) { 
            document.location = getRootPath();
        }
    }
}
