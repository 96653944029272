import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch }    from '@fortawesome/free-solid-svg-icons';
import './PagePreloader.css'


export default function PagePreloader(props) {

    return ( 
        <>
        { props.show && 
            <div id="page-preloader-overlay">
                <FontAwesomeIcon icon={faCircleNotch} className="fa-spin" /> 
            </div>
        }
        </>
    )

}