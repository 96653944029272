import React         from 'react';


export default function Certificado() {


    return (
    <>


        <div className="divisorArea" style={{paddingTop:'60px'}}>
            <div className="divisorEsquerda2">
            </div>
            <div className="divisorConteudo2">
                Política de Privacidade
            </div>
            <div className="divisorDireita2">
            </div>
        </div>

        <div className="p-5">



            <p>
                Por meio dessa Política de Privacidade, o IDD Online Tecnologia LTDA, doravante denominado pela sigla IDD, pretende mostrar seu compromisso com o cumprimento de regulamentos e leis que derivam do processamento das informações necessárias para a prestação de seus serviços e do uso de Tecnologias de Informação e Comunicação. Nesse sentido, são consideradas como quadro de referência, principalmente, a Lei 13.709/2018 LGPD e a Lei nº 12.965/2014 Marco Civil da Internet. Esta declaração se aplica a qualquer site, aplicativo, produto, software ou serviço pertencente ou vinculado ao IDD.<br />
            </p>

            <p>
                <br />
                <h3>1. QUEM SOMOS</h3>
                O IDD possui como endereço para fins de notificação, comunicação e contato na Rua Emiliano Perneta, 174 (7° andar) - Centro, Curitiba - PR, 80010-050, que disponibiliza o endereço de e-mail suporte@iddplus.com.br para comunicações e notificações relacionadas ao processamento de informações e dados pessoais, incluindo o exercício de direitos estabelecidos neste documento.<br />
            </p>

            <p>
                <br />
                <h3>2. INFORMAÇÕES PESSOAIS E DADOS COLETADOS</h3>
                Considerando-se que informações pessoais são quaisquer informações relacionadas a uma pessoa natural identificada ou identificável, é realizada a coleta, o armazenamento e o processamento de todas as informações pessoais necessárias para a prestação de serviços educacionais, incluindo dados pessoais (identificação e contato) e bancários, das quais são mantidas a confidencialidade e a segurança, exigindo-se a extensão deste compromisso às contratadas.<br />
                São coletadas informações pessoais de terceiros, prestadores de serviços e sites disponíveis publicamente, para fornecer serviços de interesse dos nossos clientes, bem como, para manter a precisão dos dados e melhorar os serviços prestados. 
                O IDD utiliza servidores, registros (logs) e outras tecnologias que coletam automaticamente informações de navegação para ajudar a analisar, gerenciar, proteger e melhorar os serviços, além de melhorar a experiência dos usuários. 
                O IDD poderá coletar informações pessoais por meio do site, nos eventos anunciados, ou sempre que os mecanismos de contato, ou solicitações de informações, forem preenchidos pelo próprio titular.<br />
                Com o consentimento do titular dos dados e, sendo este, a parte interessada, o IDD pode enviar, por e-mail ou WhatsApp, comunicações comerciais ou informativas, podendo o consentimento ser retirado a qualquer momento através de mecanismos informados no veículo de comunicação utilizado.<br />
                Para o desenvolvimento adequado dos serviços oferecidos, o IDD informa que necessita compartilhar informações pessoais de nossos estudantes ao Ministério da Educação periodicamente e sempre que solicitado pelo órgão regulador.<br />
            </p>
            <p>
                <br />
                <h3>3. PROTEÇÃO DAS INFORMAÇÕES E DOS DADOS PESSOAIS</h3>
                Neste instrumento, o IDD define sua Política de Proteção de Dados de cumprimento obrigatório para todas as partes envolvidas no tratamento das informações pessoais, estabelecendo diretrizes para os processos internos, visando privacidade, integridade, disponibilidade, confidencialidade e a disseminação da cultura de segurança das informações pessoais em consonância com os princípios da LGPD (finalidade, necessidade, transparência, adequação, livre acesso, qualidade dos dados, segurança e prevenção).
                No IDD, há um forte compromisso com a segurança das informações e com o cumprimento dos requisitos legais, garantindo confidencialidade, disponibilidade e integridade das informações, em particular dos dados pessoais, sistemas, redes, aplicações e bases de dados utilizadas para o seu tratamento. Para isso, avaliamos regularmente os riscos associados à segurança e proteção das informações e definimos planos de ação em conformidade com a legislação vigente.<br />
                Para isso, foram desenvolvidos procedimentos de controle de acesso, segurança de sistemas e comunicações, gestão de incidentes e violações de segurança e procedimentos de suporte à informação, utilizando IT – Análise e Avaliação de Riscos e Fichas de Processo de Adequação à LGPD.<br />
                São desenvolvidas, também, ações de sensibilização e formação necessárias aos nossos colaboradores para garantir o cumprimento desta Política. <br />
                Em qualquer assunto referente a dados pessoais, o IDD disponibiliza o e-mail suporte@iddplus.com.br, por meio do qual os titulares dos dados podem enviar solicitações ou solicitar informações adicionais.<br />
            </p>
            <p>
                <br />
                <h3>4. DIREITOS DOS TITULARES DE DADOS</h3>
                O IDD habilita meios necessários para atender ao direito de informação e para obtenção de consentimento nos casos necessários, garantindo a legalidade do processamento de dados pessoais. No momento da obtenção ou coleta das informações, o IDD compromete-se a informar aos titulares a identidade do Controlador, a finalidade, as possíveis comunicações ou transferências, e a possibilidade de exercer os direitos estabelecidos na LGPD.  <br />

                O IDD, portanto, reconhece e garante a possibilidade de exercício dos direitos de acesso, retificação, cancelamento, oposição, limitação do tratamento e portabilidade, ou seja:<br /><br />

                • DIREITO DE ACESSO: Obter confirmação da existência do tratamento de seus dados pessoais, se estão sendo tratados e, em caso afirmativo, terá o direito de acessar as seguintes informações sobre o tratamento de seus dados:

                <ul className="pl-3">
                    <li>
                        A finalidade do tratamento;
                    </li>
                    <li>
                        As categorias de dados pessoais a serem tratados;
                    </li>
                    <li>
                        Os destinatários ou categorias de destinatários aos quais os seus dados pessoais podem ser comunicados;
                    </li>
                    <li>
                        O prazo previsto para a conservação dos dados pessoais;
                    </li>
                    <li>
                        A existência do direito de solicitar a retificação, exclusão, oposição de dados, ou a limitação do processamento; 
                    </li>
                    <li>
                        O direito de apresentar uma reclamação perante uma autoridade de controle;
                    </li>
                    <li>
                        Quando os dados pessoais não foram obtidos da parte interessada, qualquer informação disponível sobre sua origem;
                    </li>
                    <li>
                        A existência de decisões automatizadas, incluindo a elaboração de perfis e informações significativas sobre a lógica aplicada, bem como a importância e as consequências previstas do referido tratamento para a parte interessada;
                    </li>
                    <li>
                        Quando houver transferência internacional de dados pessoais o sujeito dos dados terá o direito de ser informado das salvaguardas adequadas nos termos dos artigos 33 ao 36 da LGPD.
                    </li>
                </ul>
                <br />
                • DIREITO À ELIMINAÇÃO: Obter a exclusão de seus dados quando ocorrer qualquer uma das seguintes circunstâncias (desde que os dados não estejam em conformidade com o previsto pela LGPD para que seu tratamento seja realizado mesmo sem o consentimento dos titulares:

                <ul className="pl-3">
                    <li>
                        Os dados pessoais não são necessários para a finalidade para o qual foram recolhidos;
                    </li>
                    <li>
                        O titular dos dados pessoais retira o consentimento para o tratamento dos dados;
                    </li>
                    <li>
                        O titular dos dados pessoais que se oponha ao tratamento;
                    </li>
                    <li>
                        Os dados pessoais foram processados ilegalmente;
                    </li>
                    <li>
                        Os dados pessoais devem ser excluídos para o cumprimento de uma obrigação legal que possa ser estabelecida.
                    </li>
                </ul>
                <br />
                • DIREITO À RETIFICAÇÃO: Modificar dados imprecisos, errôneos ou incompletos.
                <br /><br />
                • DIREITO DE PORTABILIDADE: O titular dos dados poderá solicitar à empresa que está tratando seus dados pessoais para que os transmita a outro Controlador quando:

                <ul className="pl-3">
                    <li>
                        Mediante requisição expressa, de acordo com a regulamentação da ANPD, observados os segredos comerciais e industriais.
                    </li>
                </ul>

                <br />
                • DIREITO DE ANONIMIZAÇÃO, BLOQUEIO OU ELIMINAÇÃO DE DADOS DESNECESSÁRIOS:

                <ul className="pl-3">
                    <li>
                        Que sejam excessivos ou tratados em desconformidade com a LGPD.
                    </li>
                </ul>
                <br />                    
                • DIREITO A INFORMAÇÃO DAS ENTIDADES PÚBLICAS E PRIVADAS COM AS QUAIS O CONTROLADOR REALIZOU USO COMPARTILHADO DE DADOS.
                <br /><br />
                • DIREITO A INFORMAÇÃO SOBRE A POSSIBILIDADE DE NÃO FORNECER CONSENTIMENTO:
                <ul className="pl-3">
                    <li>
                        E sobre as consequências da negativa.
                    </li>
                </ul>
                <br />                 
                • DIREITO DE REVOGAÇÃO DO CONSENTIMENTO: 
                <ul className="pl-3">
                    <li>
                        Revogação do consentimento, nos termos do § 5º do art. 8º da LGPD. O consentimento pode ser revogado a qualquer momento mediante manifestação expressa do titular, por procedimento gratuito e facilitado, ratificados os tratamentos realizados sob amparo do consentimento anteriormente manifestado enquanto não houver requerimento de eliminação, nos termos do inciso VI do caput do art. 18 da LGPD.
                    </li>
                </ul>
                <br />
                • DIREITO A REVISÃO E DE EXPLICAÇÃO SOBRE DECISÕES AUTOMATIZADAS: 
                <ul className="pl-3">
                    <li>
                        Art. 20 da LGPD: O titular dos dados tem direito a solicitar a revisão de decisões tomadas unicamente com base em tratamento automatizado de dados pessoais que afetem seus interesses, incluídas as decisões destinadas a definir o seu perfil pessoal, profissional, de consumo e de crédito ou os aspectos de sua personalidade.
                        <ul className="pl-3">
                            <li>
                                § 1º O Controlador deverá fornecer, sempre que solicitadas, informações claras e adequadas a respeito dos critérios e dos procedimentos utilizados para a decisão automatizada, observados os segredos comercial e industrial.
                            </li>
                            <li>
                                § 2º Em caso de não oferecimento de informações de que trata o § 1º deste artigo baseado na observância de segredo comercial e industrial, a autoridade nacional poderá realizar auditoria para verificação de aspectos discriminatórios em tratamento automatizado de dados pessoais.
                            </li>
                        </ul>
                    </li>
                </ul>

                <br />
                • DIREITO A NÃO RETRIBUIÇÃO:
                <ul className="pl-3">
                    <li>
                        Art. 21 da LGPD. Os dados pessoais referentes ao exercício regular de direitos pelo titular não podem ser utilizados em seu prejuízo.
                    </li>
                </ul>
                <br />                       
                • DIREITO A AÇÃO INDIVIDUAL E COLETIVA: 
                <ul className="pl-3">
                    <li>
                        Art. 22 da LGPD. A defesa dos interesses e dos direitos dos titulares de dados poderá ser exercida em juízo, individual ou coletivamente, na forma do disposto na legislação pertinente, acerca dos instrumentos de tutela individual e coletiva. 
                        <br />
                        Para o exercício desses direitos, o IDD disponibiliza o contato de e-mail 
                        <a className="ml-1" href="mailto:suporte@iddplus.com.br" target="_blank" rel="noreferrer">suporte@iddplus.com.br</a>. <br />
                    </li>
                </ul>
            </p>
            <p>
                <br />
                <h3>5. INFORMAÇÕES COMUNICADAS OU TRANSFERIDAS</h3>
                Como regra geral, o IDD comunica dados pessoais a terceiros, ou lhes fornece acesso, somente nos casos necessários para desenvolver uma prestação adequada do serviço solicitado, para cumprir as obrigações jurídicas, fiscais, legais e corporativas, ou para o desenvolvimento de determinados processos ou atividades das subcontratadas e devidamente adequadas ao previsto pela LGPD e ou pela Autoridade Nacional de Proteção de Dados – ANPD.<br />
                Mais especificamente, são realizadas comunicações e trocas de informações com os bancos, para realizar a gestão das cobranças e faturamento dos serviços prestados, a gestão dos pagamentos aos prestadores de serviços, ou cumprimento dos requisitos legais, fiscais e de direitos públicos. Da mesma forma, em conformidade com esses deveres públicos, as comunicações podem ser feitas a outras agências da administração e ao órgão regulador. Também pode ocorrer a subcontratação de courier ou agência de correios para entrega e envio de faturas ou documentos.<br />
                Além disso, o IDD dispõe de determinados serviços subcontratados para a prestação e desenvolvimento dos serviços, como hospedagem externa (housing) ou hospedagem na web (hosting). Neste caso, os contratos são assinados de modo a garantir o cumprimento dos requisitos da LGPD.<br />
            </p>




            <p>
                <br />
                <h3>6. TEMPO DE MANUTENÇÃO DA INFORMAÇÃO</h3>
                No IDD, como regra geral, as informações pessoais são mantidas pelo tempo necessário para dar cumprimento às finalidades quando da obtenção dos dados. Uma vez concluída a prestação do serviço, as informações são disponibilizadas internamente e aos titulares, a Juízes e Tribunais, bem como, ao órgão regulador. Para determinar os períodos de retenção de informações, o IDD contempla as Leis locais, as obrigações contratuais e as expectativas e requisitos dos titulares. Findo prazo de guarda e conservação, é feita a destruição ou a exclusão das informações, conforme previsto pela LGPD.<br />
            </p>


            <p>
                <br />
                <h3>7. PROPRIEDADE INTELECTUAL E INDUSTRIAL</h3>
                O conteúdo dos serviços e sites do IDD, incluindo estrutura, design, textos, código fonte, bem como os logotipos, marcas e outros sinais distintos, são propriedade do IDD e estão protegidos pelos direitos de propriedade intelectual e industrial correspondentes. Imagens, vídeos, sons, áudios e outros elementos gráficos contidos nos portais também são protegidos por lei. <br />
                De acordo com a Lei 9.610/98, Lei dos Direitos Autorais (LDA), é expressamente proibido copiar, reproduzir, publicar ou alterar qualquer conteúdo publicado pelo IDD sem a autorização prévia. O IDD autoriza a reprodução total ou parcial dos textos e conteúdos fornecidos pelo portal, desde que as seguintes condições sejam atendidas:
                <ul className="pl-3">
                    <li>
                        A integridade do conteúdo, documentos ou gráficos deve ser mantida.
                    </li>
                    <li>
                        O IDD é expressamente citado como a fonte e origem das informações.
                    </li>
                    <li>
                        O objetivo e o propósito do uso são compatíveis com a atividade do IDD.
                    </li>
                    <li>
                        Não seja feito uso comercial, sendo, nesse caso, a distribuição, comunicação pública ou transformação expressamente proibida.
                    </li>
                </ul>
            </p>
            <br />

            <p>
                <h3>8. CONDIÇÕES GERAIS DE USO E RESPONSABILIDADE</h3>
                Os endereços web, contatos de WhatsApp, Instagram, Facebook e mídias disponibilizadas pelo IDD destinam-se a comunicar as atividades que realiza, produtos e serviços que presta, bem como para possibilitar a contratação de serviços de terceiros. <br />
                A navegação no site implica a aceitação expressa e plena das condições aqui estabelecidas, sem prejuízo dos detalhes que podem se aplicar a alguns dos serviços específicos oferecidos por meio deste. <br />
                Com objetivo de manter as informações publicadas nos portais atualizadas, os conteúdos podem ser modificados, corrigidos, excluídos ou aditados a qualquer momento, razão pela qual será conveniente verificar sua validade e precisão em fontes oficiais. Da mesma forma, essa política de privacidade poderá se sujeitar a alterações, correções ou modificações, a fim de mantê-la adequada às necessidades da organização, bem como as legislações vigentes.<br />

                <p>
                    <br />
                    a. Responsabilidade pela operação do site<br />
                    O IDD não pode garantir a ausência de erros no acesso aos serviços web ou ao seu conteúdo, embora desenvolva mecanismos necessários para reduzir estes cenários, corrigi-los e atualizá-los o mais rapidamente possível. Da mesma forma, o IDD não pode ser responsabilizado por quaisquer erros, interferências ou interrupções do serviço que possam surgir de causas como vírus de computador, avarias de telefone ou rede, desconexões e outras falhas motivado por causas além do proprietário do site. O IDD reserva-se ao direito de suspender temporariamente o funcionamento dos serviços web, a qualquer momento, sempre que necessário para manutenção, reparação, atualização ou melhoria. A empresa também se reserva o direito de negar ou retirar o acesso ao portal e/ou aos serviços oferecidos sem aviso prévio, a seu pedido ou de terceiros, aos usuários que não cumpram essas Condições Gerais de Uso. Ainda, o IDD não é responsável pelos conteúdos que podem ser publicados pelos usuários ou por terceiros através dos fóruns, comentários ou redes sociais associadas ao site, embora sejam fornecidos os meios precisos para remover este tipo de conteúdo e para garantir o cumprimento da legislação aplicável, bem como o respeito pelos direitos das pessoas afetadas e ordem pública. Caso os usuários detectem qualquer conteúdo que os afetem a esse respeito, recomenda-se entrar em contato com o IDD o mais rápido possível, através dos meios fornecidos.<br />
                </p>

                <p>
                    b. Responsabilidade pelos links <br />
                    Os links contidos em nossos serviços web podem levar a serviços web externos, gerenciados por terceiros, a fim de fornecer ao usuário acesso a informações ou recursos relacionados. O IDD não gera, analisa, nem pode ser responsabilizado pelo conteúdo, operação ou informação com os quais não mantém qualquer relação. O IDD não pode ser responsabilizado por quaisquer danos e preconceitos que possam surgir a partir de páginas vinculadas. Da mesma forma, a inclusão dessas conexões externas não implicará qualquer tipo de associação, fusão ou participação com as models conectadas.<br />
                </p>

            </p>



            <p>
                <br />
                <h3>9. LEI E JURISDIÇÃO APLICÁVEIS</h3>
                Para sanar quaisquer questões do presente instrumento, como resolução de conflitos, litígios ou reclamações que possam surgir a partir dos serviços web, há que se dispor das leis supracitadas. As partes elegem o Foro Central da Comarca de CURITIBA- PR, com expressa renúncia de qualquer outro, por mais privilegiado que possa vir a ser.<br />
            </p>


            <p>
                <br />
                <h3>10. POLÍTICA DE COOKIES</h3>
                O site do IDD e seus domínios usam os cookies para o bom funcionamento e visualização dos sites pelo usuário. <br />
                Os cookies podem envolver os protocolos PHP, em especial PHPSESSID, até os fornecidos por terceiros como o Google Analytics. Cookies podem ser utilizados para analisar o tráfego do site e os padrões de acesso dos usuários, melhorando a experiência e o aproveitamento do site.<br />
                Ferramentas de busca e redes sociais podem fornecer ferramentas no estilo de plug-ins, que se conectam ao site para gerar informações através de cookies e exibir anúncios relacionados. 
                Destacamos que o usuário, pode desabilitar esses cookies diretamente nas configurações de seu navegador, mas isso pode afetar a sua experiência de navegação. <br />
                É importante salientar que o impacto causado pelo recebimento de anúncios referentes aos mesmos produtos ou serviços, não significa que o usuário foi identificado por visitar um website. Na maioria das vezes, essas informações são coletadas de forma anônima, com base no perfil de navegação, sem que seus dados pessoais identificáveis sejam coletados.<br />
                <br />
                <h4>a. Aceitação da política de Cookies</h4>
                <p>
                    O IDD exibe informações sobre sua Política de Cookies na parte inferior das páginas do portal a cada login para tomada de ciência. Dadas essas informações, é possível realizar as seguintes ações:
                    <ul className="pl-3">
                        <li>
                            Aceite dos Cookies: Este aviso não será exibido novamente ao acessar qualquer página do portal durante esta sessão.
                        </li>
                        <li>
                            Modificar as Configurações: Poderá obter mais informações sobre quais são os cookies, conhecer a política de cookies do IDD e modificar as configurações do navegador para restringir ou bloquear os cookies da empresa a qualquer momento. No caso de restringir ou bloquear os cookies, pode haver uma funcionalidade reduzida da web.
                        </li>
                        <li>
                            Seguir navegando ou se movendo pela barra de rolagem: caso em que consideramos aceite de seu uso.
                        </li>
                    </ul>
                </p>
                <br />
                <h4>b. Como alterar as configurações do cookie</h4>
                <p>
                    Pode-se restringir, bloquear ou excluir os cookies de qualquer site, utilizando seu navegador. Em
                    cada navegador a operação é diferente, a função &apos;Ajuda&apos; irá mostrar como fazer.

                    <ul className="pl-3">
                        <li>
                            InternetExplorer: windows.microsoft.com/es-xl/internet-explorer/delete-manage cookies#ie=&quot;ie-10&quot;
                        </li>
                        <li>
                            FireFox: support.mozilla.org/es/kb/Borrar%20cookies
                        </li>
                        <li>
                            Chrome: support.google.com/chrome/answer/95647?hl=&quot;es&quot;
                        </li>
                        <li>
                            Safari: www.apple.com/es/privacy/use-of-cookies/
                        </li>
                    </ul>
                </p>
            </p>

            <p>
                <br />
                <h3>11 -DIVULGAÇÃO E PUBLICIDADE</h3>
                <br />
                <h4>a. E-mails</h4>
                <p>
                    O endereço de e-mail e o nome de nossos clientes é importante para que possamos enviar newsletters, conteúdos publicitários, informativos e administrativos sobre alterações de conta, serviços, produtos e políticas do IDD. É por este motivo que armazenamos essas informações em nosso banco de dados interno. Ao clicar em “Ciente”, ocorre a concordância com o recebimento destes materiais. Caso não deseje mais recebê-los, pode-se cancelar a inscrição enviando um e-mail para suporte@iddplus.com.br com a palavra “Cancelar”.
                </p>
                <br />   

                <h4>b. SMS e WhatsApp</h4>
                <p>
                    Também utilizamos os canais SMS e WhatsApp, como uma forma de oferecer produtos e/ou serviços, para o qual nos dá seu consentimento expresso, livre e informado de forma individual. As finalidades dos consentimentos vão desde:

                    <ul className="pl-3">
                        <li>
                            Receber mensagens por WhatsApp com informações referentes ao curso contratado e novas oportunidades de cursos;
                        </li>
                        <li>
                            Participar de grupo de WhatsApp dos alunos/professores/colaboradores do IDD, relacionados com sua turma/curso, facilitando a comunicação e envio de materiais;
                        </li>
                        <li>
                            Consentir que o IDD trate seus dados pessoais, por WhatsApp, para os fins de melhor prestação dos serviços (contratados, a serem contratados ou participação de eventos gratuitos);
                        </li>
                        <li>
                            Envio de comunicações, por WhatsApp, após término de curso (comunicações sobre documentações, novas oportunidades de cursos, atualizações e afins);
                        </li>
                        <li>
                            Envio de newsletters por WhatsApp;
                        </li>
                    </ul>
                    O consentimento pode ser cancelado, a qualquer momento, por envio de e-mail ao endereço suporte@iddplus.com.br com a palavra “Cancelar” (retirada em, até, 72 horas).
                </p>
                <br />
                <h4>c. Confidencialidade</h4>
                <p>
                    Para melhor atender aos titulares dos dados pessoais, os dados coletados por quaisquer meios de cadastro ficam armazenados no banco interno do IDD e disponíveis para os nossos colaboradores e parceiros.<br />
                    As comunicações recebidas são confidenciais e tratadas com seriedade e compromisso. <br />
                    Para descadastrar informações pessoais das quais é titular envie um e-mail ao endereço suporte@iddplus.com.br com a palavra “Cancelar” (retirada em, até, 72 horas).
                </p>
                <br />
                <h4>d. Mensagens não solicitadas</h4>
                <p>
                    Caso acredite que esteja recebendo mensagens que não solicitou, por favor, encaminhe um relato para suporte@iddplus.com.br. A situação será analisada imediatamente e, se cabível, a sua inscrição nos canais indicados será cancelada.

                </p>
            </p>



        </div>






        



    </>
    )


}