export default class ProgramaHelper {
    static getDuracao = (programa) => {
        let qtdEpisodiosTemporadas = ''
        if(programa.quantidade_modulos > 1) { 
            qtdEpisodiosTemporadas = `${programa.quantidade_modulos} temporadas`
        } else if(programa.quantidade_submodulos > 1) { 
            qtdEpisodiosTemporadas = programa.quantidade_submodulos + ' episódio'
            if(programa.quantidade_submodulos != 1) { 
                qtdEpisodiosTemporadas += 's'
            }
        }
        return qtdEpisodiosTemporadas
    }

    static getEpisodioAtualUser = (programa, abreviado) => {
        let episodio = ""
        if(programa.quantidade_modulos > 1) {
            if(abreviado) { 
                episodio += `T${programa.submoduloAtual.modulo.ordem}`
            } else { 
                episodio += `Temporada ${programa.submoduloAtual.modulo.ordem} - `
            }
        }
        if(programa.quantidade_submodulos > 1) {
            episodio += ProgramaHelper.getEpisodioLabel(programa.submoduloAtual, abreviado)
        }
        
        return episodio
    }


    static getEpisodioLabel(submoduloAtual, abreviado) {

        let label = '';
        if(submoduloAtual.video) { 
            if(abreviado) { 
                label += `E`
            } else { 
                label += `Episódio `
            }
            label += `${submoduloAtual.ordem}`
            if(!abreviado) { 
                label += submoduloAtual.video.nome
            }
        } else { 
            label += `Quiz`
        }
        return label;
    }
}