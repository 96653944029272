import React         from 'react';
import api           from "../services/api";
import User          from '../utils/User.js';
import PagePreloader from '../components/PagePreloader.jsx'


export default function MeusCertificados() {

    // Não permitir deslogado ====================
    User.blockIfNonLogged()
    // ===========================================

    const [ moduloX_user,         setModuloX_user         ] = React.useState([]);
    const [ showPreloader, setShowPreloader ] = React.useState(true);


    React.useEffect(() => {

        /**
         * Buscar modulos concluídos
         **/ 
        api
        .get(`moduloX_user/concluidos/?key=${User.getAttr('token')}`)
        .then((response) => {

            // Erro no ajax
            if(response.data.status === 0) { 
                return
            }
            setShowPreloader(false)
            setModuloX_user(response.data.moduloX_user)

        })
        .catch((err) => {
            console.error("Erro ao buscar pela API: " + err);
        });

    }, [])







    return (
    <>

        <div className="titulosDetalhamentoTemporada" style={{marginTop: '100px'}}>
            <div className="titulosCategorias pb-3">
                  <span className="divisorTitulos"></span>
                  Meus certificados
              </div>
        </div>


        <div className="listagemCertificados">

            <PagePreloader show={showPreloader} />

            { !showPreloader && moduloX_user.length == 0 && 
                <div className="rounded bg-success text-white border p-5 m-5">
                    Você ainda não tem nenhum certificado. 
                    <br />
                    <br />
                    Continue seus estudos e veja seu currículo crescer!
                </div>
            }

            { moduloX_user.map((item, key) => {
                return (
                    <div key={key} className="DadosListagemCertificados mt-4">
                        <div className="AbasEpisodioBoxGeral">
                            <a href={item.url_certificado} >
                                <div className="AbasEpisodioAreaVideo" style={{textAlign: 'center', padding: '10px'}}>
                                    <img className="img-fluid AbasEpisodioVideo" src={api.getImageUrl(item.modulo.programa.url_imagem, 298, 168)} />
                                    <h3 className="abasEpisodioTitulo">
                                        {item.modulo.programa.nome}
                                    </h3>
                                    <div>
                                        {item.modulo.nome}
                                    </div>
                                </div>
                            </a>               
                        </div>    
                    </div>
                )
            })}

        </div>




    </>
    )


}