
export const getEnviroment = () => {
    if(document.location.href.indexOf('localhost:3000') !== -1) { 
        return 'development'
    } else if(document.location.href.indexOf('testes.idd.edu.br') !== -1) { 
        return 'test'
    } else { 
        return 'production'
    }
}

export const getUrlIdd = () => {
    const url = {
        'development': 'http://localhost/idd_online/idd.edu.br/public_html/',
        'test':        'https://testes.idd.edu.br/site/',
        'production':  'https://www.idd.edu.br/',
    }
    return url[getEnviroment()]
};

export const getUrlIddPlus = () => {
    const url = {
        'development': `http://localhost/idd_online/iddplus.com.br/public_html/`,
        'test':        `https://testes.idd.edu.br/iddplus/`,
        'production':  `https://iddplus.com.br/`,
    }
    return url[getEnviroment()]
};


export const getLinkedInCertificateUrl = (programaNome, concluido_em, certUrl, certId) => {
    const params = new URLSearchParams({
        startTask:       'CERTIFICATION_NAME',
        name:            programaNome,
        organizationId:  1016763,
        issueYear:       concluido_em.substr(0,4),
        issueMonth:      Number(concluido_em.substr(5,2)),
        certUrl:         certUrl,
        certId:          certId
    });
    return `https://www.linkedin.com/profile/add?${params.toString()}`
};



export const getRootPath = () => {
    if(getEnviroment() == 'test') {
        return '/iddplus/'
    } else { 
        return '/'
    }
}