import React, { useRef } from 'react';



export default function CabecalhoAbaTemporada(props) {

    const selectTemporada = useRef(null)

    React.useEffect(() => {
        if(props.temporadaInicialSelect) { 
            selectTemporada.current.value = props.temporadaInicialSelect
        }
    }, [props.temporadaInicialSelect])


    const onChangeSelect = () => {
        const temporadaAtual = selectTemporada.current.value
        props.setTemporadaAtual(temporadaAtual)
    }

    const temporada = [];
    for(let temp=1; temp<=props.qtdTemporadas; temp++) {
        temporada.push(temp)
    }

    return (


        <div className="titulosDetalhamentoTemporada">
            <div className="titulosCategorias pb-3">
                <span className="divisorTitulos">
                </span>
                {props.title}
            </div>
            {(!temporada || temporada.length == 1) && 
                <input ref={selectTemporada} value="1" type="hidden" />
            }
            {temporada && temporada.length > 1 && 
                <div className="pb-5">
                    {/* <select ref={selectTemporada} className="detalhamentoTemporadaSelect" onChange={onChangeSelect} >*/}
                    <select ref={selectTemporada} className="detalhamentotemporadaBotao" onChange={onChangeSelect} >
                        {temporada.map((x) => (
                            <option value={x} key={x}>
                                Temporada {x}
                            </option>
                        ))}
                    </select>
                </div>
            }
        </div>


    
    )
}

