import React    from 'react';
import api      from "../services/api";

import { FontAwesomeIcon }           from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';


import ListaHorizontal from '../components/ListaHorizontal';
import download_ebook from '../imagens/download-ebook.png'


export default function ListagemMateriais(props) {

    const [material, setMaterial] = React.useState(null);

    React.useEffect(() => {

        let url = 'material'
        if(props.slug) { 
            url += `/${props.slug}/`
        }
        const getParams = []
        if(props.temporada) { 
            getParams.push(`temporada=${props.temporada}`)
        } 
        if(props.palavraChave) { 
            getParams.push(`palavra_chave=${props.palavraChave}`)
        }
        if(props.palavraChave) { 
            getParams.push(`palavra_chave=${props.palavraChave}`)
        }
        if(props.comCategoria) { 
            getParams.push(`com_categoria=1`)
        }
        if(getParams.length) { 
            url += '?'+getParams.join('&')
        }
        api.get(url)
        .then(res => {
            if(!res.data)             return null
                if(res.data.status === 0) return null
                    if(!res.data.material)    return null

                        setMaterial(res.data.material)

                    if(typeof(props.onLoadCallback) == 'function') {
                        props.onLoadCallback(props.onLoadKey, res.data.material);
                    }
                })
    }, [props.slug, props.bibliografia]);


    /**
    * Função que retorna cada item do slider
    **/ 
    const getContent = (material, key) => {

        const video     = material.video
        const submodulo = video.submodulo
        const modulo    = submodulo.modulo

        let titulo, subtitulo;
        if(props.slug) { 
            titulo = `Episódio ${submodulo.ordem}`
        } else { 
            titulo = modulo.programa.nome
            subtitulo = `${modulo.ordem}ª temporada | ${submodulo.ordem}° episódio`
        }

        return (
            <div key={key} className="material">
            <div className="buscaBoxEbook">
            <span>
            <div className="buscaTituloEbook">
            {titulo}
            </div>
            <small>
            {subtitulo && 
            <strong>
            {subtitulo}<br />
            </strong>
        }
        {material.video.nome}
        </small>
        </span>
        </div>
        <a className="buscaLinkEbook" href={api.getRepositoryFileUrl(material.file_url) } target="_blank" rel="noreferrer">
        {material.file_name}
        <img src={download_ebook} />
        </a>
        </div>
        )
    } 


  /**
   * Quantidade de slides por tamanho de janela
   **/ 
  let breakpoints
  if(props.carousel) { 
    breakpoints={
                640: {
                  slidesPerView: 1,
                  slidesPerGroup: 1
                },
                970: {
                  slidesPerView: 2,
                  slidesPerGroup: 2
                },
                1415: {
                  slidesPerView: 3,
                  slidesPerGroup: 2
                },
                1860: {
                  slidesPerView: 4,
                  slidesPerGroup: 3
                },
              }
  }



  /**
   * Renderizar ==========================================================================================
   **/ 
  return (
    <>
      { material == null && 
        <div className="text-center">
          <br />
          <FontAwesomeIcon icon={faCircleNotch} className="m-3 fa-spin" size="5x" /> 
          <br />
          <br />
        </div>
      }

      { material && 
        <>
          <ListaHorizontal getContent={getContent} items={material} carousel={props.carousel} breakpoints={breakpoints} />
        </>
      }
    </>
  )

}


