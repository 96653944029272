import React    from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';


import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation }          from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

export default function ListaHorizontal(props) {

  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  const swiperRef         = React.useRef(null)


  let breakpoints
  let onAfterInit = () => {}

  if(props.carousel) { 
    if(props.breakpoints) { 
      breakpoints = props.breakpoints
    } else { 
      breakpoints={
        640: {
          slidesPerView: 2,
          slidesPerGroup: 2
        },
        770: {
          slidesPerView: 2,
          slidesPerGroup: 2
        },
        950: {
          slidesPerView: 3,
          slidesPerGroup: 2
        },
        1220: {
          slidesPerView: 4,
          slidesPerGroup: 3
        },
        1500: {
          slidesPerView: 5,
          slidesPerGroup: 4
        },
        1800: {
          slidesPerView: 6,
          slidesPerGroup: 4
        },
      }
    }

    if(props.centralizado) { 
      const centralizarNaJanela = () => {

        if(swiperRef.current.swiper.isLocked) {
          document.querySelector(".swiper-wrapper").style.justifyContent = 'center'
        } else { 
          document.querySelector(".swiper-wrapper").style.justifyContent = 'flex-start'
        }
      }
      onAfterInit = centralizarNaJanela;
      window.onresize = () => {
        setTimeout(centralizarNaJanela, 1000)
      }
    }
  }





  return (

    <>

      {
        /**
         * Sem carousel
         **/ 
        !props.carousel && 

        <div className="areaListagemDetalhamento">
          <div className="ContainerListagemVideosCatalogo">
            {props.items.map((item, i) => props.getContent(item, i))}
          </div>
        </div>
      }




      { 
        /**
         * Com carousel
         **/ 
        props.carousel && 

        <div className="areaListagemDetalhamento">

          <div className="d-flex w-100">
            <a className="swiper-arrow swiper-arrow-left d-flex align-items-center" ref={navigationPrevRef} >
              <FontAwesomeIcon icon={faCaretLeft} size="3x" />
            </a>
            <Swiper
              ref={swiperRef}
              spaceBetween={10}
              slidesPerView={1}
              slidesPerGroup={1}
              modules={[Navigation]}
              onAfterInit={onAfterInit}
              // loop: true,
              // centerInsufficientSlides={true}
              centeredSlidesBounds={true}
              onSlideChange={(swiper) => {
                if(swiper.isBeginning) {
                  swiper.params.navigation.prevEl.style.visibility = 'hidden'
                } else { 
                  swiper.params.navigation.prevEl.style.visibility = 'visible'
                }
                if(swiper.isEnd) {
                  swiper.params.navigation.nextEl.style.visibility = 'hidden'
                } else { 
                  swiper.params.navigation.nextEl.style.visibility = 'visible'
                }
              }}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              onSwiper={(swiper) => {

                // Delay execution for the refs to be defined
                setTimeout(() => {
                  // Override prevEl & nextEl now that refs are defined
                  swiper.params.navigation.prevEl = navigationPrevRef.current
                  swiper.params.navigation.nextEl = navigationNextRef.current

                  // Re-init navigation
                  swiper.navigation.destroy()
                  swiper.navigation.init()
                  swiper.navigation.update()

                  if(swiper.isBeginning) {
                    swiper.params.navigation.prevEl.style.visibility = 'hidden'
                  } else { 
                    swiper.params.navigation.prevEl.style.visibility = 'visible'
                  }
                  if(swiper.isEnd) {
                    swiper.params.navigation.nextEl.style.visibility = 'hidden'
                  } else { 
                    swiper.params.navigation.nextEl.style.visibility = 'visible'
                  }
                })
              }}
              onResize={(swiper) => {

                // Delay execution for the refs to be defined
                setTimeout(() => {
                  // Override prevEl & nextEl now that refs are defined
                  swiper.params.navigation.prevEl = navigationPrevRef.current
                  swiper.params.navigation.nextEl = navigationNextRef.current

                  // Re-init navigation
                  swiper.navigation.destroy()
                  swiper.navigation.init()
                  swiper.navigation.update()

                  if(swiper.isBeginning) {
                    swiper.params.navigation.prevEl.style.visibility = 'hidden'
                  } else { 
                    swiper.params.navigation.prevEl.style.visibility = 'visible'
                  }
                  if(swiper.isEnd) {
                    swiper.params.navigation.nextEl.style.visibility = 'hidden'
                  } else { 
                    swiper.params.navigation.nextEl.style.visibility = 'visible'
                  }
                })
              }}

              breakpoints={breakpoints}
                
            >
              {props.items.map(function(item, i) { 
                const content = props.getContent(item, i)
                return <SwiperSlide key={i} >{content}</SwiperSlide>
              })}
            </Swiper>
            <a className="swiper-arrow swiper-arrow-right d-flex align-items-center" ref={navigationNextRef} >
              <FontAwesomeIcon icon={faCaretRight} size="3x" />
            </a>
          </div>
        </div>
      }
    </>

    
  )
}