import React, { useState } from 'react';
import api from "../services/api";
import Accordion from 'react-bootstrap/Accordion';
import circulo from "../imagens/circulo.svg"


export default function Faq() {

    const [faq, setFaq] = useState([])

    React.useEffect(() => {

        api.get(`/faq/`)
            .then(res => {
                if(!res.data)             return null;
                if(res.data.status === 0) return null;
                if(!res.data.faq)    return null

                setFaq(res.data.faq)
        })

    }, [])

    // const toggleAcordeon = (event, key) => {

    //     event.preventDefault()

    //     for(let i=0; i<faq.length; i++) {

    //         const obj = document.getElementById(`conteudo${i}`)

    //         if(i != key || obj.offsetHeight != 0) { 
    //             obj.style.height = '0px'
    //         } else { 
    //             obj.style.height = 'auto'
    //             const outerHeight = obj.offsetHeight
    //             obj.style.height = '0px'
    //             setTimeout(() => {
    //                 obj.style.height = outerHeight+'px'
    //             }, 0)
    //         }
    //     }
    // }


    return (

    <>

        <div className="divisorArea">
            <div className="divisorEsquerda2">
            </div>
            <div className="divisorConteudo2">
                FAQ
            </div>
            <div className="divisorDireita2">
            </div>
        </div>





        <div className="listagemContainer">

            <div className="accordion faqMargens" id="accordionExample">

                <Accordion>

                    { faq.map((item, key) => {
                        const eventKey = String(key)
                        const resposta = item.resposta.replace(/\\n/g, '')
                        return (
                            <div key={key} className="mb-4">
                                <Accordion.Toggle variant="link" eventKey={eventKey} className="faqLink">
                                    <h2 className="faqH2">
                                        <div className="faqLista">
                                            <div className="faqPerguntaGeral">
                                                <div className="faqPergunta">
                                                    <span className="faqMarcador">
                                                        <img src={circulo} />
                                                    </span>
                                                    <span style={{'width':'96%'}}>
                                                        {item.enunciado}
                                                    </span>
                                                    <span className=" dropdown-toggle pt-2 text-center" style={{'width':'4%'}}></span>
                                                </div>
                                            </div>
                                        </div>
                                    </h2>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={eventKey}>
                                    <div className="faqResposta" dangerouslySetInnerHTML={{__html: resposta}}>
                                    </div>
                                </Accordion.Collapse>
                            </div>
                        )
                    })}


                </Accordion>


            

            </div>
        </div>







    </>
    )
}


