import api from "../../services/api";
import User from '../../utils/User.js';


export default class trechosAssistidos {


    // Configurações *************************************************************************************

    // Tempo restante, em segundos, a partir do qual considera-se que o vídeo foi assitido até o final
    static tempo_restante_considerado_100_por_cento = 7

    // ***************************************************************************************************


    static setDadosCertificado
    static player

    static updateDelay         = 3000;

    static timeoutAtualizacao
    static posicaoAtual
    static representacao
    static inseriuPosicaoAtual


    static submoduloAtual      = null
    static submoduloSalvo;

    static setSubmodulo = (submodulo) => {
        this.submoduloAtual = submodulo
        this.submoduloSalvo = false;
    }



    static reset = () => {
        this.pararAtualizacao()
        this.timeoutAtualizacao  = null
        this.posicaoAtual        = 0
        this.representacao       = null
        this.inseriuPosicaoAtual = false
    }

    static atualizar = (callback) => {

        this.player.getCurrentTime().then(function(seconds) {

            if(trechosAssistidos.posicaoAtual == seconds && trechosAssistidos.inseriuPosicaoAtual) { 
                callback()
                return;
            }
            trechosAssistidos.inseriuPosicaoAtual = true
            trechosAssistidos.posicaoAtual = seconds

            if(!trechosAssistidos.representacao) { 
                /**
                 * Primeira atualização: pegar tamanho e colocar primeiro trecho
                 **/ 
                trechosAssistidos.player.getDuration().then(function(duration) {
                    trechosAssistidos.representacao = `${duration}|${seconds}-${seconds}`;
                    trechosAssistidos.salvar(callback)
                });
            } else { 

                const tolerancia = 5
                let trechos = trechosAssistidos.representacao.split('|')
                let inicio, 
                fim, 
                inicioProximoTrecho, 
                fimProximoTrecho 
                let nadaAlterado = false;

                for(let i=1; i<trechos.length; i++) {
                    let temp = trechos[i].split('-');
                    inicio = parseFloat(temp[0])
                    fim    = parseFloat(temp[1])

                    // Este segundo já está contabilizado
                    // Então não faz nada
                    if(seconds == inicio || seconds == fim || (seconds > inicio && seconds < fim)) { 
                        nadaAlterado = true;
                        break;
                    }

                    if((i+1) == trechos.length) { 
                        inicioProximoTrecho = fimProximoTrecho = null;
                    } else { 
                        let temp = trechos[i+1].split('-');
                        inicioProximoTrecho = parseFloat(temp[0])
                        fimProximoTrecho    = parseFloat(temp[1])
                    }

                    if((inicio-tolerancia) <= seconds && (fim+tolerancia) >= seconds) { 


                        /**
                         * Emendar com um trecho já existente
                         **/ 
                        if(inicio > seconds) { 

                            // No início
                            inicio = seconds

                        } else if(inicioProximoTrecho === null) {

                            // Emendou no fim do último trecho
                            fim = seconds

                        } else { 

                            // No final de trecho que não é último

                            if((inicioProximoTrecho - tolerancia) > seconds) { 

                                // Não emenda com o próximo trecho
                                fim = seconds

                            } else { 

                                // Emendou com o próximo trecho
                                fim = fimProximoTrecho
                                trechos.splice(i+1, 1)

                            }
                        }
                        // Alterar o trecho
                        trechos[i] = `${inicio}-${fim}`
                        break;


                    } else { 

                        if(!inicioProximoTrecho) {

                            /**
                             * Adicionar novo trecho no final
                             **/ 
                            trechos.push(`${seconds}-${seconds}`)
                            break;

                        } else if(seconds < inicioProximoTrecho) { 

                            /**
                             * Adicionar novo trecho antes do próximo
                             **/ 
                            trechos.splice(i+1, 0, `${seconds}-${seconds}`)
                            break;
                        }
                    }
                }
                if(!nadaAlterado) { 
                    trechosAssistidos.representacao = trechos.join('|');
                }
                trechosAssistidos.salvar(callback)
            }

        });


    }






    static getDurationFromTrechos = (representacao) => {
        return Number(representacao.substr(0, representacao.indexOf('|')))
    }





    static salvar = (callback) => {

        if(this.representacao === null) { 
            return;
        }

        /**
         * Se faltam 10 segundos ou menos pro final, considerar que já viu tudo
         **/ 
        const duracao = this.getDurationFromTrechos(this.representacao)
        let posicaoConsiderada = this.posicaoAtual
        if(duracao - this.posicaoAtual <= this.tempo_restante_considerado_100_por_cento) { 
            posicaoConsiderada = duracao
        }

        /**
         * Parâmetros da API
         **/ 
        var params = new URLSearchParams();
        params.append('acao',               'atualizarTrechosAssistidos');
        params.append('submodulo',          this.submoduloAtual);
        params.append('video_actual_time',  posicaoConsiderada  );
        params.append('trechos_assistidos', this.representacao );

        if(!this.submoduloSalvo) { 
            params.append('salvar_programaX_user_submodulo', '1');
        }
        /**
         * Enviar
         **/ 
        api
        .post(`submoduloX_user/?key=${User.getAttr('token')}`, params)
        .then(res => {
            if(res.data.status === 0) { 
                console.log('Erro ao salvar trechos assistidos')
            } else { 
                trechosAssistidos.submoduloSalvo = true;
                if(res.data.dados_certificado) { 
                    trechosAssistidos.setDadosCertificado(res.data.dados_certificado)
                }
            }
            if(typeof(callback) == 'function') { 
                callback()
            }
        })
    }




    static pararAtualizacao = () => {
        if(!isNaN(this.timeoutAtualizacao)) { 
            clearTimeout(this.timeoutAtualizacao);
        }
    }


}
